import React from 'react';
import { Button, Card, List } from 'antd';
import intl from 'react-intl-universal';
import { replaceUrlToHttps } from 'utils/utils';
import { list_V5, getVersion } from 'utils/bannerWhiteList';

import styles from './BannerCard.less';
import adIcon from '../../assets/bargain/ad.png';
import discountIcon from '../../assets/bargain/discount.png';

const ListItem = List.Item;

function BannerCard({ data, type, handleBannerClick, userId }) {
  const {
    dsers_url,
    ae_url,
    dsers_img,
    ae_img,
    ae_product_title,
    dsers_product_title,
    ae_ship_price,
    ae_price,
    dsers_price,
    percent
  } = data;
  const img = ae_img?.split(';');
  const isBannerUser = getVersion(userId);
  const price = parseFloat(ae_ship_price) + parseFloat(ae_price);
  const isV5 = list_V5.includes(userId);

  if (isBannerUser !== 'notBannerUser') {
    return type === 2 ? (
      <ListItem>
        <Card
          bordered={false}
          cover={
            <img
              // alt={dsers_product_title}
              alt={ae_product_title}
              src={replaceUrlToHttps(img[0])}
              className={styles.img}
              onClick={() => {
                window.dataLayer.push({
                  event: 'compareProductMassEvent',
                  type: 'clickIssue'
                });
                // window.open(dsers_url, '_blank');
                window.open(ae_url, '_blank');
              }}
            />
          }
          className={styles.bannerCard}
          data-label="product-item"
        >
          <div className={styles.bannerCon}>
            <div className={styles.bannerInfo}>
              <a
                href={dsers_url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  window.dataLayer.push({
                    event: 'compareProductMassEvent',
                    type: 'clickIssue'
                  });
                }}
              >
                <span className={styles.bannerTitle} title={dsers_product_title}>
                  <img className={styles.ad} src={adIcon} />
                  {dsers_product_title}
                </span>
              </a>
            </div>
            <div className={styles.descriptionCon}>
              {/* <div className={styles.dsersPrice}>${dsers_price}</div> */}
              <div className={styles.dsersPrice}>${ae_price}</div>
              {/* <span className={styles.currentPrice}>Current Price: ${ae_price}</span> */}
              <span className={styles.currentPrice}>Current Price: ${price}</span>
              {/* <span className={styles.discountNum}>{percent}</span> */}
              <span className={styles.discountNum}>20%</span>
              <img className={styles.discount} src={discountIcon} />
              <Button
                onClick={() => handleBannerClick(data)}
                className={styles.cheaperBtn}
              >
                {/* <span>{intl.get('bargain.reward')}</span> */}
                <span>
                  {isV5 ? 'up to 20% off' : intl.get('bargain.found_cheaper')}
                </span>
              </Button>
            </div>
          </div>
        </Card>
      </ListItem>
    ) : (
      <Card
        bordered={false}
        cover={
          <img
            // alt={dsers_product_title}
            alt={dsers_product_title}
            src={replaceUrlToHttps(img[0])}
            className={styles.img}
            onClick={() => {
              window.dataLayer.push({
                event: 'compareProductMassEvent',
                type: 'clickIssue'
              });
              // window.open(dsers_url, '_blank');
              window.open(ae_url, '_blank');
            }}
          />
        }
        className={styles.bannerCard}
        data-label="product-item"
      >
        <div className={styles.bannerCon}>
          <div className={styles.bannerInfo}>
            <a
              // href={dsers_url}
              href={ae_url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                window.dataLayer.push({
                  event: 'compareProductMassEvent',
                  type: 'clickIssue'
                });
              }}
            >
              <span
                className={styles.bannerTitle}
                // title={dsers_product_title}
                title={ae_product_title}
              >
                <img className={styles.ad} src={adIcon} />
                {/* {dsers_product_title} */}
                {ae_product_title}
              </span>
            </a>
          </div>
          <div className={styles.descriptionCon}>
            {/* <div className={styles.dsersPrice}>${dsers_price}</div> */}
            <div className={styles.dsersPrice}>${ae_price}</div>
            {/* <span className={styles.currentPrice}>Current Price: ${ae_price}</span> */}
            <span className={styles.currentPrice}>Current Price: ${price}</span>
            {/* <span className={styles.discountNum}>{percent}</span> */}
            <span className={styles.discountNum}>20%</span>
            <img className={styles.discount} src={discountIcon} />
            <Button
              onClick={() => handleBannerClick(data)}
              className={styles.cheaperBtn}
            >
              {/* <span>{intl.get('bargain.reward')}</span> */}
              <span>
                {isV5 ? 'up to 20% off' : intl.get('bargain.found_cheaper')}
              </span>
            </Button>
          </div>
        </div>
      </Card>
    );
  }
  return null;
}

export default BannerCard;

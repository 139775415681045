import React, { PureComponent } from 'react';
import { connect } from 'dva';
import intl from 'react-intl-universal';
import styles from './index.less';
import {
  getShopifyStoreStatus,
  replaceUrlToHttps,
  getSupplierUrl,
  CommonDataCollect
} from '../../../../utils/utils';
import defaultImg from '../../../../assets/default.svg';
import numeral from 'numeral';
import {
  Form,
  Input,
  Modal,
  Button,
  Tooltip,
  InputNumber,
  Checkbox,
  Drawer,
  Table,
  Row,
  Col,
  Collapse,
  message,
  Icon,
  Spin,
  Popover
} from 'antd';
import { Get, Put } from 'utils/request';
import className from 'classnames';

import CheckSaveModal from '../../../../components/CheckSaveModal/CheckSaveModal';

const { Panel } = Collapse;
const { confirm } = Modal;

@Form.create()
export default class QuickPushModal extends PureComponent {
  checkedArr = [];
  variantMap = {};
  inputErrorTimer = null;
  storesSettings = [];
  organizationData = {};
  aeSku = '';
  sku_id = '';
  ae_pid = [];
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isLoading: false,
      detailArr: [],
      producteDetail: {
        detail: {
          options: []
        }
      },
      productTitleInput: '',
      freightLoading: false,
      selectedRows: {},
      isHidden: false,
      selectedShipToCity: [],
      selectedShipsFrom: undefined,
      selectedShipToCountry: undefined,
      defaultShipToCity: [],
      selectedShipsFrom: undefined,
      selectedShippingMethod: undefined,
      dropDownPrice: '',
      dropDownPriceDisabled: true,
      dropDownComparePrice: '',
      dropDownComparePriceDisabled: true,
      edited: false,
      secondaryCue: false,
      errorMessage: {}
    };
  }
  // 获取详情
  getImportDetails = async () => {
    this.setState({ isLoading: true });
    const { params } = this.props;

    if (params.id === '[]') {
      return;
    }

    this.setState({
      visible: true
    });

    const id = JSON.parse(params.id);

    window.dtag.set({
      event: 'click',
      module: 'oneClickPush_browse',
      action: 'browse'
    });

    // const id = ['br7ikbv2drrg00e63lvg_600147477443587060_TMall'];

    for (let index = 0; index < id.length; index++) {
      try {
        const res = await Get('/prod/select', {
          data: { unique_id: id[index] }
        });
        if (res.code === 2000 && res.data) {
          this.ae_pid.push(res?.data?.ae_prod?.product_id_str);
          this.getAeCost(res.data);
          const handle_producteDetail = res.data.prod;
          let id = 0;
          handle_producteDetail.detail.variants.forEach(it => {
            it.only_flag = it.sku;
            id += 1;
            it.inventory_quantity = it.inventory_quantity || 0;
            it.rowId = id;
            it.price = Number(it.price) || Number(it.purchase_cost) || 0;
            it.sku = it.sku || 'Default';
            it.originSku = it.sku || 'Default';
            it.img = this.getVariantImage(
              it.sku,
              handle_producteDetail.detail?.images || []
            );
            this.variantMap[id] = it;

            handle_producteDetail.ext.basic[
              res.data.ae_prod.product_id_str
            ].supply.forEach(sp => {
              if (sp.sku_edit === it.sku || sp.sku === it.sku) {
                sp.rowId = sp.rowId || id;
              }
            });
          });
          this.rowId = id + 1;
          const arr = this.state.detailArr;
          arr.push(handle_producteDetail);

          this.setState({
            producteDetail: handle_producteDetail,
            productTitleInput: handle_producteDetail?.detail?.title,
            detailArr: [...arr]
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
    this.setState({ isLoading: false });
  };
  // 获取aeCost
  getAeCost = data => {
    const { ae_prod, prod } = data;
    const allCost = ae_prod?.item_offer_site_sale_price;
    const product_sku = ae_prod?.aeop_ae_product_s_k_us.aeop_ae_product_sku;

    let flag = false;

    if (product_sku.length === 1 && product_sku[0].id === '<none>') {
      // mapping的主供应商只有一个sku
      flag = true;
    }

    prod.detail.variants.forEach(item => {
      let { sku } = item;

      prod.ext.basic[ae_prod?.product_id_str].supply.some(sp => {
        if (sku === sp.sku_edit || sku === sp.sku_desc || sku === sp.sku) {
          sku = sp.sku;
          return true;
        }
        return false;
      });
      product_sku.forEach(ae => {
        let arr = sku.split(';');
        if (sku === ae.id || ae.sku_sign === arr.sort().join(';') || flag) {
          if (ae.s_k_u_available_stock !== undefined) {
            item.aeStock = ae.s_k_u_available_stock;
          } else {
            item.aeStock = 0;
          }
          if (ae_prod?.product_status_type !== 'onSelling') {
            item.aeStock = 0;
          }
          if (ae.id === '<none>') {
            item.aeCost = allCost || ae.offer_sale_price || ae.sku_price;
          } else {
            item.aeCost = ae.offer_sale_price || allCost || ae.sku_price;
          }
        }
      });
    });
  };
  rowId = '';
  getVariantImage = (sku, images) => {
    let src = defaultImg;
    images.some(item => {
      const { alt = '' } = item;
      if (alt.split('|').includes(sku)) {
        src = item.src;
        return true;
      } else {
        //找不到的情况
        alt.split('|')?.map(altItem => {
          if (altItem.includes(sku)) {
            src = item.src;
          }
        });
      }
      return false;
    });

    return src;
  };
  componentWillUnmount() {
    clearTimeout(this.inputErrorTimer);
  }
  componentDidMount() {
    this.getImportDetails();
  }
  onClose = () => {
    const { edited } = this.state;
    window.dtag.set({
      event: 'click',
      module: 'oneClickPush_cancel',
      action: 'clickCancel',
      custom_data: {
        is_edit: `${edited}`
      }
    });

    if (edited) {
      this.setState({
        secondaryCue: true
      });
    } else {
      this.setState({
        visible: false,

        productTitleInput: '',
        freightLoading: false,
        selectedRows: {},
        isHidden: false,
        selectedShipToCity: [],
        selectedShipsFrom: undefined,
        selectedShipToCountry: undefined,
        defaultShipToCity: [],
        selectedShipsFrom: undefined,
        selectedShippingMethod: undefined,
        dropDownPrice: '',
        dropDownPriceDisabled: true,
        dropDownComparePrice: '',
        dropDownComparePriceDisabled: true
      });
    }
  };

  productTitleInputhandler = (e, index) => {
    const { detailArr } = this.state;
    detailArr[index].detail.title = e.target.value;

    this.setState({
      edited: true,
      detailArr: JSON.parse(JSON.stringify(detailArr))
    });
  };

  arrt = [];

  // 设置variants的sku
  setVariantSKU = (rowId, value, index) => {
    const { detailArr } = this.state;

    const { supplier_id, detail, ext } = detailArr[index];

    const { variants } = detail;
    const { basic } = ext;
    const { supply } = basic[supplier_id];

    supply.forEach(item => {
      if (item.rowId === rowId) {
        item.sku_edit = value;
      }
    });

    variants.forEach(item => {
      if (item.rowId === rowId) {
        item.sku = value;
      }
    });

    this.setState({
      edited: true,
      detailArr: JSON.parse(JSON.stringify(detailArr))
    });
  };

  // 设置Price value
  setVariantPirce = (rowId, value, index) => {
    const { detailArr } = this.state;

    const { supplier_id, detail, ext } = detailArr[index];

    const { variants } = detail;

    variants.forEach(item => {
      if (item.rowId === rowId) {
        item.price = value;
      }
    });

    this.setState({
      edited: true,
      detailArr: JSON.parse(JSON.stringify(detailArr))
    });
  };

  // Compare price变化
  setVariantComparePirce = (rowId, value, index) => {
    const { detailArr } = this.state;

    const { supplier_id, detail, ext } = detailArr[index];

    const { variants } = detail;

    variants.forEach(item => {
      if (item.rowId === rowId) {
        item.compare_at_price = value;
      }
    });

    this.setState({
      edited: true,
      detailArr: JSON.parse(JSON.stringify(detailArr))
    });
  };

  // 库存变化
  changeStock = (rowId, value, index) => {
    const { detailArr } = this.state;

    const { supplier_id, detail, ext } = detailArr[index];

    const { variants } = detail;

    variants.forEach(item => {
      if (item.rowId === rowId) {
        item.inventory_quantity = value;
      }
    });

    this.setState({
      edited: true,
      detailArr: JSON.parse(JSON.stringify(detailArr))
    });
  };

  // 确认删除variants的弹窗
  removeVariants = (recoed, index) => {
    let { detailArr } = this.state;

    if (detailArr[index]?.detail?.variants.length === 1) {
      message.error(intl.get('product_edit.sku_count_invalid'));
      return;
    }
    confirm({
      title: intl.get('product_edit.delete_variant_tip'),
      okText: intl.get('public.yes'),
      cancelText: intl.get('public.no'),
      onOk: () => {
        this.removeVariantsSub(recoed, index);
        this.setState({
          isHidden: true
        });
      }
    });
  };

  // 批量确认删除variants的弹窗
  removeVariantsAll = index => {
    const { detailArr, selectedRows } = this.state;

    const { detail } = detailArr[index];

    if (detail?.variants.length - selectedRows[index].length < 1) {
      message.error(intl.get('product_edit.sku_count_invalid'));
      return;
    }

    confirm({
      title: intl.get('product_edit.delete_selected_variant_tip'),
      okText: intl.get('public.yes'),
      cancelText: intl.get('public.cancel'),
      onOk: () => {
        this.removeVariantsSub(null, index, true);
      }
    });
  };

  // 确认删除variants
  removeVariantsSub = (recoed, index, all = false) => {
    let { detailArr, selectedRows } = this.state;
    const { detail, ext } = detailArr[index];
    const variants = [];

    if (all) {
      detail.variants.forEach(item => {
        if (!selectedRows[index].includes(item.rowId)) {
          variants.push(item);
        }
      });

      console.log(variants);

      const residueId = variants.map(item => {
        return item.rowId;
      });

      const residueSKU = variants.map(item => {
        return item.sku;
      });

      //处理basic没有删除完全的bug
      let basicArr = ext.basic[this.ae_pid[index]].supply;
      ext.basic[this.ae_pid[index]].supply = basicArr.filter(item =>
        residueId.includes(item.rowId)
      );

      //强制删除一次supply
      ext.basic[this.ae_pid[index]].supply = basicArr.filter(item =>
        residueSKU.includes(item.sku_edit)
      );
    } else {
      detail.variants.forEach(item => {
        if (recoed.rowId !== item.rowId) {
          variants.push(item);
        }
      });

      //处理basic没有删除完全的bug
      let basicArr = ext.basic[this.ae_pid[index]].supply;
      ext.basic[this.ae_pid[index]].supply = basicArr.filter(
        item => recoed.rowId !== item.rowId
      );

      //强制删除一次supply
      ext.basic[this.ae_pid[index]].supply = basicArr.filter(
        item => recoed.sku !== item.sku_edit
      );
    }

    console.log(ext);

    detail.variants = variants;
    let arr = [];
    detail.variants.forEach(ii => {
      arr.push(ii.img);
    });
    detail.images.map((el, i) => {
      if (!arr.includes(el.src)) {
        delete el.alt;
      }
    });
    this.setState({ edited: true, detailArr: [...detailArr] });
  };

  // 设置variants的options的值
  setVariants = (rowId, value, ind, index) => {
    const { detailArr } = this.state;

    const { detail } = detailArr[index];

    const { variants, options } = detail;

    const optionValue = [{}, {}, {}];

    variants.forEach(item => {
      if (item.rowId === rowId) item[`option${ind}`] = value;

      options.forEach((option, ele) => {
        optionValue[ele][item[`option${ele + 1}`]] = item[`option${ele + 1}`];
      });
    });

    options.forEach((item, i) => {
      item.values = Object.values(optionValue[i]);
    });

    this.setState({ edited: true, detailArr: [...detailArr] });
  };

  // 改变option分类的name
  changeOptionTitle = (e, index) => {
    const v = e.target.value;
    Promise.resolve(null).then(() => {
      const { detailArr } = this.state;

      const { detail } = detailArr[index];
      const { options = [] } = detail;
      options[index].name = v;
      this.setState({
        edited: true,
        detailArr: JSON.parse(JSON.stringify(detailArr))
      });
    });
  };

  setDropDownPrice = val => {
    console.log(val);
    this.setState({
      dropDownPrice: val,
      dropDownPriceDisabled: false
    });
  };

  setDropDownComparePrice = val => {
    this.setState({
      dropDownComparePrice: val,
      dropDownComparePriceDisabled: false
    });
  };

  // 批量设置Price value
  applyDropDownPrice = index => {
    const { selectedRows, dropDownPrice, detailArr } = this.state;

    const { detail } = detailArr[index];

    const { variants } = detail;

    variants.forEach(item => {
      item.price = dropDownPrice;
    });

    this.setState({
      dropDownPrice: '',
      dropDownPriceDisabled: true,
      edited: true,
      detailArr: JSON.parse(JSON.stringify(detailArr))
    });
  };

  // 批量设置Price value
  applyDropDownComparePrice = index => {
    const { selectedRows, dropDownComparePrice, detailArr } = this.state;

    const { detail } = detailArr[index];

    const { variants } = detail;

    variants.forEach(item => {
      item.compare_at_price = dropDownComparePrice;
    });

    this.setState({
      dropDownComparePrice: '',
      dropDownComparePriceDisabled: true,
      edited: true,
      detailArr: JSON.parse(JSON.stringify(detailArr))
    });
  };

  secondaryFrameonClose = () => {
    this.setState({
      secondaryCue: false
    });
  };

  allClose = () => {
    this.setState(
      {
        secondaryCue: false,
        edited: false
      },
      () => {
        this.onClose();
      }
    );
  };

  getColumns = index => {
    const skuRow = [
      {
        title: '',
        dataIndex: 'img',
        width: 150,
        render: (v, record, i) => {
          return (
            <div>
              <img
                src={replaceUrlToHttps(v)}
                style={{ width: 64, height: 64 }}
                // onClick={() => {
                //   this.showChangeModal(record);
                // }}
              />
              {/* <span className="bugw">
                <Checkbox onChange={(e)=>{
                  this.selectChecked(record.rowId)
                }} checked={ this.checkedArr.includes(record.rowId)} />
              </span> */}
            </div>
          );
        }
      },
      {
        title: intl.get('product_edit.sku'),
        dataIndex: 'sku',
        render: (sku, record) => {
          return (
            <Tooltip title={sku}>
              <Input
                value={sku}
                name={record.id}
                onChange={e => {
                  this.setState({
                    isHidden: true
                  });
                  this.setVariantSKU(record.rowId, e.target.value, index);
                }}
              />
            </Tooltip>
          );
        }
      },
      {
        title: intl.get('product_edit.cost'),
        dataIndex: 'aeCost',
        render: v => {
          return <span>{`$${numeral(v).format('0,0.00')}`}</span>;
        }
      },
      {
        title: (
          <Popover
            placement="bottom"
            trigger="click"
            content={
              <div className={styles.batchOperation}>
                <div className={styles.headline}>
                  {intl.get('product_edit.price')}
                </div>
                <div className={styles.select}>
                  <div className={styles.input}>
                    <InputNumber
                      formatter={value =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%' }}
                      min={0}
                      precision={2}
                      onChange={this.setDropDownPrice}
                    />
                  </div>
                  <div className={styles.button}>
                    <Button
                      type="primary"
                      onClick={e => this.applyDropDownPrice(index)}
                      disabled={this.state.dropDownPriceDisabled}
                    >
                      CONFIRM
                    </Button>
                  </div>
                </div>
              </div>
            }
            destroyTooltipOnHide
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            <div>
              {intl.get('product_edit.price')}
              <i class="material-icons">arrow_drop_down</i>
            </div>
          </Popover>
        ),
        dataIndex: 'price',
        render: (price, record) => {
          return (
            <div className={styles.priceWrap}>
              <span className={styles.currencyTip}>
                <Tooltip
                  title={intl.getHTML('product_edit.product_edit_price_tip')}
                >
                  <Icon type="question-circle" />
                </Tooltip>
              </span>
              <InputNumber
                width={80}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                min={0}
                precision={2}
                value={price}
                onChange={value => {
                  this.setState({
                    isHidden: true
                  });
                  this.setVariantPirce(record.rowId, value, index);
                }}
              />
            </div>
          );
        }
      },
      {
        title: (
          <Popover
            placement="bottom"
            trigger="click"
            content={
              <div className={styles.batchOperation}>
                <div className={styles.headline}>
                  {intl.getHTML('product_edit.compare_price')}
                </div>
                <div className={styles.select}>
                  <div className={styles.input}>
                    <InputNumber
                      formatter={value =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%' }}
                      min={0}
                      precision={2}
                      onChange={this.setDropDownComparePrice}
                    />
                  </div>
                  <div className={styles.button}>
                    <Button
                      type="primary"
                      onClick={() => this.applyDropDownComparePrice(index)}
                      disabled={this.state.dropDownComparePriceDisabled}
                    >
                      CONFIRM
                    </Button>
                  </div>
                </div>
              </div>
            }
            destroyTooltipOnHide
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            <div>
              {intl.getHTML('product_edit.compare_price')}
              <i class="material-icons">arrow_drop_down</i>
            </div>
          </Popover>
        ),
        dataIndex: 'compare_at_price',
        render: (price, record) => {
          return (
            <div className={styles.priceWrap}>
              <span className={styles.currencyTip}>
                <Tooltip
                  title={intl.getHTML('product_edit.product_edit_price_tip')}
                >
                  <Icon type="question-circle" />
                </Tooltip>
              </span>
              <InputNumber
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                min={0}
                precision={2}
                value={price}
                onChange={value => {
                  this.setState({
                    isHidden: true
                  });
                  this.setVariantComparePirce(record.rowId, value, index);
                }}
              />
            </div>
          );
        }
      },
      {
        title: (
          <div>
            {intl.getHTML('product_edit.shopify_stock')}
            <Tooltip
              title={intl.getHTML('product_edit.shopify_stock_tip', {
                platform:
                  this.state.detailArr[index].supplier_type === 4
                    ? 'Tmall'
                    : 'AliExpress'
              })}
            >
              <Icon
                style={{ marginLeft: 5 }}
                type="question-circle"
                className={styles.stockTip}
              />
            </Tooltip>
          </div>
        ),
        dataIndex: 'inventory_quantity',
        render: (v, record) => {
          return (
            <InputNumber
              value={v || 0}
              precision={0}
              min={0}
              max={100000000}
              className={className({
                [styles.redBorder]: Number.isNaN(Number(v))
              })}
              onChange={e => {
                this.setState({
                  isHidden: true
                });
                this.changeStock(record.rowId, e, index);
              }}
            />
          );
        }
      },
      {
        title: (
          <i
            style={{ cursor: 'pointer' }}
            className={className('material-icons', {
              [styles.disabled]: !this?.state?.selectedRows[index]?.length
            })}
            onClick={() => {
              this?.state?.selectedRows[index]?.length &&
                this.removeVariantsAll(index);
            }}
          >
            delete
          </i>
        ),
        dataIndex: 'del',
        width: 20,
        render: (text, record) => {
          return (
            <i
              class="material-icons"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.removeVariants(record, index);
              }}
            >
              delete
            </i>
          );
        }
      }
    ];

    const { detailArr } = this.state;
    const { detail = {} } = detailArr[index];
    const { options = [] } = detail;
    const names = [];
    options.forEach((item, ind) => {
      const dataindex = `option${ind + 1}`;

      if (ind >= 2) {
        names.push(item.name);
      } else {
        skuRow.splice(2 + ind, 0, {
          title: (
            <Tooltip title={item.name.length > 5 ? item.name : null}>
              <Input
                width={65}
                value={item.name}
                name={item.id}
                onChange={e => {
                  this.setState({
                    isHidden: true
                  });
                  this.changeOptionTitle(e, ind, index);
                }}
              />
            </Tooltip>
          ),
          dataIndex: dataindex,
          width: 65,
          render: (v, record) => {
            return (
              <Tooltip title={v.length > 5 ? v : null}>
                <Input
                  width={65}
                  value={v}
                  name={record.id}
                  onChange={e => {
                    this.setState({
                      isHidden: true
                    });
                    this.setVariants(
                      record.rowId,
                      e.target.value,
                      ind + 1,
                      index
                    );
                  }}
                />
              </Tooltip>
            );
          }
        });
      }
    });

    if (options.length > 2) {
      skuRow.splice(4, 0, {
        // title: names[0],
        title: (
          <Input
            value={names[0]}
            name={options[2].id}
            onChange={e => {
              this.setState({
                isHidden: true
              });
              this.changeOptionTitle(e, 2, index);
            }}
          />
        ),
        dataIndex: 'option3',
        width: 65,
        render: (option3, record) => {
          return (
            <Tooltip title={option3.length > 5 ? option3 : null}>
              <Input
                value={option3}
                name={record.id}
                onChange={e => {
                  this.setState({
                    isHidden: true
                  });
                  this.setVariants(record.rowId, e.target.value, 2 + 1, index);
                }}
              />
            </Tooltip>
          );
        }
      });
    }
    return skuRow;
  };

  disabledCopyOption = () => {
    this.actionOptions.forEach(item => {
      if (item.key.includes('d-')) {
        item.disabled = true;
      }
    });
  };

  validateOptionValue = options => {
    let hasValid = true;
    for (const item of options) {
      if (item.values.some(i => i.trim() === '')) {
        hasValid = false;
        break;
      }
    }
    return hasValid;
  };

  validateRepeatOptionName = options => {
    let hasValid = true;
    let tmp = {};
    for (const item of options) {
      if (tmp[item.name]) {
        hasValid = false;
        break;
      } else {
        tmp[item.name] = 1;
      }
    }
    tmp = null;
    return hasValid;
  };

  // 校验字段
  checkField = () => {
    const { detailArr, errorMessage } = this.state;

    let flag = false;

    this.setState({
      errorMessage: {}
    });

    for (let index = 0; index < detailArr.length; index++) {
      const element = detailArr[index];

      const { detail } = element;

      const { variants = [], options = [] } = detail;

      // 型号的值有没有重复的 例如2个 red / s
      let repeat = false;
      const arr = [];
      variants.forEach(item => {
        let v = '';
        options.forEach((option, i) => {
          v += `${item[`option${i + 1}`]}--`;
        });

        if (arr.includes(v)) {
          repeat = true;
        } else {
          arr.push(v);
        }
      });
      if (repeat) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            [index]: intl.get('product_edit.option_repeated_tip')
          }
        });

        flag = true;
      }

      // ----------END------------

      if (
        detail.variants &&
        detail.variants.some(i => i.sku?.trim() === '' || i.sku === undefined)
      ) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            [index]: intl.get('product_edit.option_sku_invalid')
          }
        });

        flag = true;
      }

      if (
        detail.options &&
        detail.options.some(i => i.name?.trim() === '' || i.name === undefined)
      ) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            [index]: intl.get('product_edit.option_name_invalid')
          }
        });

        flag = true;
      }

      // 不能包含 ' / '
      if (detail.options && detail.options.some(i => /\s\/\s/.test(i.name))) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            [index]: intl.get('product_edit.option_name_slash')
          }
        });

        flag = true;
      }

      if (detail.options && !this.validateOptionValue(detail.options)) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            [index]: intl.get('product_edit.option_value_invalid')
          }
        });

        flag = true;
      }

      if (detail.options && !this.validateRepeatOptionName(detail.options)) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            [index]: intl.get('product_edit.option_name_repeat')
          }
        });

        flag = true;
      }

      const repeatSkuArr = [];
      let hasRepeatSku = false;

      variants.forEach(item => {
        if (repeatSkuArr.includes(item.sku)) {
          hasRepeatSku = true;
        }
        repeatSkuArr.push(item.sku);
      });

      console.log(hasRepeatSku);

      if (hasRepeatSku) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            [index]: intl.get('product_edit.sku_repeated_tip')
          }
        });

        flag = true;
      }
    }

    console.log(flag, '324234');

    return flag;
  };

  pushGoods = async (push = true) => {
    const { detailArr, edited } = this.state;

    let dotData = {};

    if (push) {
      window.dtag.set({
        event: 'click',
        module: 'oneClickPush_pushProduct',
        action: 'clickPushProduct',
        custom_data: {
          is_edit: `${edited}`
        }
      });
    }

    this.setState({ isLoading: true, secondaryCue: false, edited: false });

    const checkField = this.checkField();

    if (checkField) {
      this.setState({
        isLoading: false
      });
      return;
    }

    const SAVE_SUCC = [];

    for (let index = 0; index < detailArr.length; index++) {
      const element = detailArr[index];

      element.detail.variants.forEach(item => {
        item.price = String(item.price);
        item.compare_at_price = String(item.compare_at_price);
      });

      dotData = {
        prod: {
          user_id: element.user_id,
          ae_product_id: element.ae_product_id
        }
      };

      try {
        let data = {
          detail: element.detail,
          ext: element.ext,
          unique_id: element.product_unique_id,
          setting: {
            [element.id]: {
              collections: [],
              product_type: '',
              tags: '',
              vendor: ''
            }
          }
        };

        const result = await Put('/prod/select', {
          data: data
        });

        if (result.code === 2010) {
          SAVE_SUCC.push(element);
        }
      } catch (error) {
        console.log(error);
      }
    }

    this.setState({ isLoading: false });

    if (push) {
      // 推送商品
      const PUSH_DATA = [];

      SAVE_SUCC.forEach(item => {
        PUSH_DATA.push({
          id: item.id,
          image: item.detail.image.src,
          supplier_type: item.supplier_type,
          unique_id: item.product_unique_id
        });
      });

      const { pushSpecialProducts } = this.props;

      pushSpecialProducts(PUSH_DATA, true, dotData);
      this.onClose();
    }
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const {
      detailArr,
      isLoading,
      producteDetail,
      productTitleInput,
      freightLoading,
      selectedRows,
      errorMessage
    } = this.state;

    return (
      <Modal
        title="Batch Editing"
        placement="right"
        closable={true}
        onCancel={this.onClose}
        visible={this.state.visible}
        width={868}
        className={styles.quickPushModal}
        footer={
          <Button
            type="primary"
            disabled={isLoading || !detailArr.length}
            onClick={this.pushGoods}
          >
            PUSH TO SHOPIFY
          </Button>
        }
      >
        <div className={styles.tip}>
          You can use this pop-up window to bulk edit product information and
          quickly push products to your store.
        </div>
        <Spin spinning={isLoading}>
          <Form>
            <Collapse expandIconPosition={'right'}>
              {detailArr?.map((item, index) => (
                <Panel
                  key={index}
                  header={[
                    <>
                      <div className={styles.panelStyle}>
                        <Form.Item label="Product">
                          <img
                            className={styles.mainImg}
                            src={item?.detail?.image?.src}
                            alt=""
                          />
                        </Form.Item>
                        <Form.Item label="Title">
                          {getFieldDecorator(`title_${index}`, {
                            initialValue: item?.detail?.title,
                            rules: [
                              {
                                required: true,
                                message: intl.get('product_edit.input_title')
                              }
                            ]
                          })(
                            <Input.TextArea
                              maxLength={256}
                              className={styles.importListNameTextArea}
                              onChange={e =>
                                this.productTitleInputhandler(e, index)
                              }
                              onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            />
                          )}
                          <div className={styles.siziNum}>
                            {item?.detail?.title.length || 0} / 256
                          </div>
                        </Form.Item>
                      </div>
                      {errorMessage[index] ? (
                        <div style={{ color: 'red' }}>
                          {errorMessage[index]}
                        </div>
                      ) : null}
                    </>
                  ]}
                >
                  {/* Variants列表 */}

                  <Spin spinning={freightLoading}>
                    <Table
                      columns={this.getColumns(index)}
                      rowKey="rowId"
                      pagination={false}
                      dataSource={item.detail.variants}
                      rowSelection={{
                        onChange: selectedRowKeys => {
                          if (selectedRowKeys.length == 0) {
                            // this.disabledCopyOption();
                          }

                          const { selectedRows } = this.state;

                          selectedRows[index] = selectedRowKeys;

                          this.setState({
                            selectedRows: JSON.parse(
                              JSON.stringify(selectedRows)
                            )
                          });
                        },
                        selectedRowKeys: selectedRows[index]
                      }}
                    />
                  </Spin>
                </Panel>
              ))}
            </Collapse>
          </Form>
        </Spin>
        <CheckSaveModal
          type="notSave"
          visible={this.state.secondaryCue}
          closeCkeckModalHanlder={this.allClose}
          saveAndNextHandler={() => this.pushGoods(false)}
          onlycloseCkeckModalHanlder={this.secondaryFrameonClose}
          restsProps={{ maskClosable: false }}
        />
      </Modal>
    );
  }
}

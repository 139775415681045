/*
 * Created Date: Monday March 22nd 2021
 * Author: William (cweiliang.me@gmail.com)
 */

import React, { useEffect, useState } from 'react';

import { pushCheck } from '../../services/productmy';

import { useDispatch } from 'react-redux';

/**
 * 检测是否推送完成
 */
function usePushSpCheck({ onSuccess, onError }) {
  const unmountRef = React.useRef(false);
  const timingRef = React.useRef(null);
  const dispatch = useDispatch()
  const [cachedData, setCachedData] = useState(null);
  const [awaited, setAwaited] = useState(false);
  const [trigger, setTrigger] = useState();
  const [result, setResult] = useState(null);

  useEffect(() => {
    console.log('****** running ********');
    function task() {
      try {
        const tmp = JSON.parse(localStorage.getItem('push_task'));

        if (!tmp) {
          if (timingRef.current) clearInterval(timingRef.current);
          return;
        }
        setCachedData(tmp);
        setAwaited(true);

        pushCheck({
          data: {
            request_id: tmp.request_id
          }
        })
          .then(res => {
            console.log('usePushSpCheck res', res);
            if (res && res.code === 2000) {
              setResult(res.data);
              // "status": int // 0 传送中 1完成 2 失败
              const hasProcessing = res.data.some(i => i.status === 0);
              // 处理完成
              if (!hasProcessing) {
                setCachedData(null);
                setAwaited(false);
                localStorage.removeItem('push_task');
                if (timingRef.current) {
                  clearInterval(timingRef.current);
                }
                onSuccess();
                dispatch({
                  type: 'topBtn/notificationUnreadLength',
                  payload: {
                    cancelMessage: true,
                  }
                });
              }
            } else {
              setAwaited(false);
              onError(res.data);
              if (timingRef.current) clearInterval(timingRef.current);
            }

            return null;
          })
          .catch(error => {
            console.log('push check fetch error:', error);
            onError(error);
            if (timingRef.current) clearInterval(timingRef.current);
          });
      } catch (error) {
        console.log('usePushSpCheck error: ', error);
        onError(error);
        if (timingRef.current) clearInterval(timingRef.current);
      }
    }

    task();
    timingRef.current = setInterval(() => {
      task();
    }, 2000);

    return () => {
      unmountRef.current = true;
      if (timingRef.current) clearInterval(timingRef.current);
    };
  }, [trigger]);

  // 手动触发检测
  function run() {
    if (timingRef.current) clearInterval(timingRef.current);
    setTrigger(Date.now());
  }

  return {
    cachedData,
    result,
    awaited,
    run
  };
}

export default usePushSpCheck;

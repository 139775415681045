import React, { PureComponent } from 'react';
import { connect } from 'dva';
import { Card, Button, Checkbox, Icon, Tooltip, Popover } from 'antd';
import qs from 'querystring';

import Ellipsis from 'components/Ellipsis';

import className from 'classnames';
import intl from 'react-intl-universal';
import './importList.css'

import { Post } from 'utils/request';
import RecommendTag from '../Order/component/RecommendTag';
import MoreActionGuide from 'components/FilterDrawer/MoreActionGuide';
import { replaceUrlToHttps } from 'utils/utils';
import { ProductCardTitles, ProductCardMoreAction } from 'features'

import countryStyles from 'common/country_icon.js'

import styles from './Product.less';
import './countryIcons.css'

import isDsersProductIco from '../../assets/product/Verified.png';
import aeProductIco from '../../assets/product/AE.png';
import defaultImg from '../../assets/default-image.jpg';
import dsersIcon from '../../assets/bargain/dsers_icon.svg';
import mainIcon from 'assets/newImportList/main_product.png'
import FindProductButton from 'components/FindProductButton';
import { RewardTag, TendaysDeliveryTag, TitleIcon } from 'components/BargainProductTags';

const { Meta } = Card;

const bannerTrickConfig = {
  'track-type': 'productBargain',
  'track-name': 'supplyBannerV3',
  'track-metadata-position': 'importList'
};

@connect(({ loading }) => ({
  loading: loading.models.productmy
}))
export default class ImportProduct extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      openFunctionVisible:false,
      showShip:true
    };
  }

  componentDidMount() {
    this.setState({
      tags: Array.from(new Set(this.props.product.tag))
    });
  }

  componentWillReceiveProps(nexprops) {
    this.setState({
      tags: Array.from(new Set(nexprops.product.tag))
    });
  }

  onChangeTag = e => {
    this.setState({
      tags: e
    });
  };

  clearCheckProduct = () => {
    this.setState({
      tags: []
    });
  };

  get getTargetBanner() {
    const { bannerData, product } = this.props;
    let result;

    if (bannerData && product) {
      const tmp = bannerData.find(i => i.ae_product_id === product.ae_pid);
      if (tmp) {
        result = tmp;
      }
    }

    return result;
  }

  handleBannerClick = async item => {
    const { product } = this.props;

    if (item) {
      this.props.dispatch({
        type: 'global/showOneClickMapping',
        payload: item
      });
    }

    try {
      const res = await Post('/prod/propool/spread/importlist', {
        data: {
          id: product.id
        }
      });
    } catch (error) {
      console.log('handleBannerClick error:', error);
    }
  };

  openFunctionHandler = ()=>{
    this.setState({
      openFunctionVisible:true
    })
  }

  closeFunctionHandler = ()=>{
    this.setState({
      openFunctionVisible:false,
    })
  }

  leaveCardHandler = ()=>{
    this.setState({
      showShip:true
    })
  }

  enterCardHandler = ()=>{
    this.setState({
      showShip:false
    })
  }

  moreFunctionHandler = (id)=>{
    const { product={}, confirmProductTag, showDetail, removeProduct } = this.props
    switch (id) {
      case 1:
        console.log(
          111111111111,product.supplier_type
        );
        window.dtag.set({
          event:'click',
          module: product.supplier_type === 4 ? 'tmall' : 'Aliexpress',
          action: product.supplier_type === 4 ? 'tmall_figure_search': 'Aliexpress_figure_search',
          custom_data:{
            'click_type': 'go_supplier_importlist'
          }
        })
        if(product.supplier_type === 4 ){
          

          const paramObjTmall ={
            url:`https://www.dsers.com/app/tmallproduct/${product.ae_pid}?appId=159831084`,
            ref: 'import_list',
            supplierType:"tmall",
            store_id: localStorage.getItem('storeId')
          }
          const paramStrTmall = qs.stringify(paramObjTmall)
          window.open(`/app/supplier_optimizer?${paramStrTmall}`)
          return
        }
        
        const paramObj = {
          url: product.image,
          ref: 'import_list',
          id:product.ae_pid,
          store_id: localStorage.getItem('storeId')
        };
        const paramStr = qs.stringify(paramObj);
        const link = `/app/supplier_optimizer?${paramStr}`;
        window.open(link)
        break;
      case 2:
        confirmProductTag(this.state.tags,[product.unique_id])
        break;
      case 3:
        window.dataLayer.push({
          type: 'editProductOpen'
        });
        showDetail(product, 'shipping')
        break;
      case 4:
        window.dataLayer.push({
          event: 'importListCustomEvent',
          type: 'deleteProduct'
        });
        removeProduct(product.unique_id, product.is_pub);
        break;
      default:
        break;
    }
  }

  description = (product,showShip) => {
    return (
      <>
        <p className={styles.processing}>
          {product.isDSers === true ? (
            <span>{intl.get('product.product.processing')}</span>
          ) : null}
        </p>
        <p className={styles.cost}>
          <span className={styles.value}>
            {`${intl.get('product.product.cost')} $${String(product.cost)?.includes('~') ? String(product.cost).replace('~', ' ~ ') : product.cost}`}
          </span>
          <span style={{display: showShip ? 'block' : 'none'}} className={`css_flag font css_${countryStyles[product.prod.ship_to] ? countryStyles[product.prod.ship_to][0] : countryStyles['US'][0]}`}><i>{intl.getHTML('product.price_for')} {countryStyles[product.prod.ship_to] ? countryStyles[product.prod.ship_to][1] : countryStyles['US'][1]}</i></span>
          {/* <span className={styles.separator}>/</span>
          <span className={styles.unit}>
            {intl.get('product.product.piece')}
          </span> */}
          <div className={styles.tip}>
            {product.supplier_type === 2 ? <Tooltip title={intl.get('product.product.piece_tip')}>
              <span>
                <i class="material-icons notranslate" style={{ fontSize: 22 }}>help_outline</i>
              </span>
            </Tooltip> : null}
          </div>
        </p>
        {/* <p className={styles.price}>
          {intl.get('product.product.price')}: ${product.price}
          {product.price2 ? `~${product.price2}` : null}
        </p> */}
        <div className={styles.ext}>
          <p className={styles.stock}>
            <span>
              {intl.get('product.product.stock')}
            </span>
            <span>
              {product.stock_count}
            </span>
          </p>
        </div>
      </>
    );
  };

  render() {
    const {
      product = {},
      push,
      removeProduct,
      showDetail,
      showSplitProduct,
      renderCheckData,
      confirmProductTag,
      hidenPushShofiy,
      awaitPush,
      firstId
    } = this.props;

    const moreActionOptions = [
      {id:1,name:'Supplier Optimizer',disabled:!product.image.includes('https:'),icon:'search'},
      {id:2,name:'Apply DSers tags',icon:'loyalty'},
      {id:3,name:'Shipping info',icon:'local_shipping'},
      {id:4,name:'Delete',icon:'delete'},
    ]

    const { tags } = this.state;

    let isFirst = false;
    if (product) {
      isFirst = firstId === product.id;
    }
    return (
      <div
        className={styles.card_ingo}
        onMouseLeave={this.leaveCardHandler}
        onMouseEnter={this.enterCardHandler}
        style={{position:'relative'}}
      >
        <Card
          bordered={false}
          hoverable
          actions={
            true
              ? newAction(
                  product,
                  removeProduct,
                  showDetail,
                  showSplitProduct,
                  renderCheckData,
                  this.clearCheckProduct,
                  this.onChangeTag,
                  confirmProductTag,
                  tags,
                  this.openFunctionHandler,
                )
              : actions(
                  product,
                  removeProduct,
                  showDetail,
                  showSplitProduct,
                  renderCheckData,
                  this.clearCheckProduct,
                  this.onChangeTag,
                  confirmProductTag,
                  tags,
                  this.openFunctionHandler
                )
          }
          cover={
            <img
              alt={product.title}
              src={replaceUrlToHttps(product.image)}
              className={styles.pic}
              onError={e => {
                e.target.onerror = null;
                e.target.src = defaultImg;
              }}
            />
          }
          className={`${styles.product} find-product-action-item`}
        >
          {/* {product.isTendays ? <TendaysDeliveryTag /> : null} */}
          {/* {product.isDSers ? <RewardTag /> : null} */}
          {true ? null : (
            <div className={styles.transon_optX}>
              <FindProductButton
                id={product.ae_pid}
                image={product.image}
                pageRef="import_list"
                offset={!!product.prod.stores.length}
                isFirst={isFirst}
              />
            </div>
          )}
          <Meta
            title={title(product)}
            description={this.description(product, this.state.showShip)}
          />
          <Checkbox value={product.unique_id} className={styles.checkbox} />
          {product.spread ? (
            <RecommendTag
              data={this.getTargetBanner}
              style={{
                top: 210,
                left: 0,
                fontSize: 13,
                paddingLeft: 4,
                paddingRight: 4
              }}
              title={intl.get('bargain.alternative_shipping')}
              onLinkClick={this.handleBannerClick}
              showPoint
              trickConfig={bannerTrickConfig}
            />
          ) : null}
          {product.prod.stores.length ? (
            <p className={styles.ShopifyTips}>
              {intl.get('import_list.pushed_to')}&nbsp;
              {!awaitPush ? (
                <a
                  className={styles.clickStoreCount}
                  onClick={e => hidenPushShofiy(product)}
                >
                  <span>
                    {product.prod.stores.length}{' '}
                    {intl.get('import_list.store_s')}
                  </span>
                </a>
              ) : (
                <span>
                  {product.prod.stores.length} {intl.get('import_list.store_s')}
                </span>
              )}
            </p>
          ) : null}
          <Tooltip title={intl.get('product.push_btn')}>
            <p
              className={styles.pushBtn}
              onClick={() => {
                window.dataLayer.push({
                  event: 'importListCustomEvent',
                  type: 'pushProduct',
                  payload: product
                });

                push(product);
              }}
            >
              <span>
                <img
                  src={require('../../assets/new_login_ui/Shopify-icon/Shopify-icon#fff.png')}
                ></img>
              </span>
            </p>
          </Tooltip>
        </Card>
        {this.props.index == 0 ?<MoreActionGuide type='importlist'></MoreActionGuide> : null}
        <ProductCardMoreAction 
          type = {'importlist'}
          openFunctionVisible={this.state.openFunctionVisible}
          moreActionOptions={moreActionOptions}
          product={product}
          moreFunctionHandler={this.moreFunctionHandler}
          closeFunctionHandler={this.closeFunctionHandler}
        />
      </div>
    );
  }
}

function title(product) {
  return <ProductCardTitles type='importlist' product={product} />
}

function newAction(
  product,
  removeProduct,
  showDetail,
  showSplitProduct,
  renderCheckData,
  clearCheckProduct,
  onChangeTag,
  confirmProductTag,
  tags,
  openFunctionHandler,
){
  return [
    <div className={styles.functionContainer}>
      <Tooltip className={styles.edit} title={intl.get('product.product.edit_details')}>
        <span>
          <i class="material-icons notranslate" style={{ fontSize: 24 }} onClick={() => {
            window.dataLayer.push({
              type: 'editProductOpen'
            });
            showDetail(product)
          }} >edit</i>
        </span>
      </Tooltip >
      <Tooltip className={styles.split} title={intl.get('product.product.split')}>
        <span>
          <i class="material-icons" style={{ fontSize: 24 }} onClick={e => {
            window.dataLayer.push({
              event: 'importListCustomEvent',
              type: 'split'
            });
            showSplitProduct(product);
          }}>call_split</i>
        </span>
      </Tooltip>
      <Tooltip className={styles.more} style={{ fontSize: 24 }} title={'More Action'}>
        <span style={{position:'relative'}}>
          <i class="material-icons" onClick={openFunctionHandler}>
            more_vert
          </i>
        </span>
      </Tooltip>
    </div>
  ]
}

function actions(
  product,
  removeProduct,
  showDetail,
  showSplitProduct,
  renderCheckData,
  clearCheckProduct,
  onChangeTag,
  confirmProductTag,
  tags
) {
  if (product.is_del) {
    return [
      <Tooltip title={intl.get('product.product.delete')}>
        <Icon
          type="delete"
          onClick={() => {
            removeProduct([product.id], product.is_pub);
          }}
        />
      </Tooltip>
    ];
  }

  const tools = [
    <Tooltip title={intl.get('product.product.edit_details')}>
      <span>
        <i class="material-icons notranslate" style={{ fontSize: 24 }} onClick={() => {
          window.dataLayer.push({
            type: 'editProductOpen'
          });
          showDetail(product)
        }} >edit</i>
      </span>
    </Tooltip >,
    <Tooltip title={intl.get('product.product.delete')}>
      <span>
        <i class="material-icons notranslate" style={{ fontSize: 24 }} onClick={() => {
          window.dataLayer.push({
            event: 'importListCustomEvent',
            type: 'deleteProduct'
          });
          removeProduct(product.unique_id, product.is_pub);
        }}>delete</i>
      </span>
    </Tooltip >,
    <Tooltip title={intl.get('import_list.add_tags')}>
      <span><i class="material-icons" style={{ fontSize: 24 }} onClick={() => {
        confirmProductTag(tags, [product.unique_id])
      }}>loyalty</i></span>
    </Tooltip>,
    <Tooltip title="Shipping info">
      <span>
        <i class="material-icons" style={{ fontSize: 24 }} 
          onClick={() => {
            window.dataLayer.push({
              type: 'editProductOpen'
            });
          showDetail(product, 'shipping')
        }}>local_shipping</i>
      </span>
    </Tooltip>,
    <Tooltip title={intl.get('product.product.split')}>
      <span><i class="material-icons" style={{ fontSize: 24 }} onClick={e => {
        window.dataLayer.push({
          event: 'importListCustomEvent',
          type: 'split'
        });
        showSplitProduct(product);
      }}>call_split</i></span>
    </Tooltip>
  ];
  return tools;
}

import React, { PureComponent } from 'react';
import { connect } from 'dva';
import {
  List,
  Button,
  Row,
  Col,
  Checkbox,
  Modal,
  Icon,
  Tooltip,
  message,
  Input,
  Progress,
  Table,
  Form,
  Menu,
  Dropdown,
  Popconfirm,
  Spin,
  Drawer,
  Collapse,
  Radio
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import copy from 'copy-to-clipboard';
import intl from 'react-intl-universal';
import { Get } from 'utils/request';
import { relative } from 'path';

import ErrorCashbackCode from 'components/ErrorCashbackCode';
import AEDataAuthorization from 'components/AEDataAuthorization';
import ProductPoolBanner from 'components/ProductPoolBanner';
import DSersSpBanner from 'components/DSersSpBanner';
import Ellipsis from 'components/Ellipsis';
import CardPagination from 'components/CardPagination';
import FindProductButton from 'components/FindProductButton';
import { getVersion } from 'utils/bannerWhiteList';
import { dispatchEventForGuide } from '../BeginnerGuide/util';
import OldUiOutBanner from 'components/OldUiOut/OldUiOutBanner';
import CheckSaveModal from 'components/CheckSaveModal/CheckSaveModal.jsx';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import ImportSearchInput from './component/ImportSearchInput';
import InputGuide from 'components/FilterDrawer/InputGuide';
import { SearchInput } from 'features';

import ImportMyProdcutEdit from './ImportMyProductEdit.js';
import ImportProduct from './ImportListProduct.js';
import SplitProduct from './Common/SplitProduct.js';
import { productSelectAddTags, pushProduct } from '../../services/productmy';
import PushNotice from './component/PushNotice';
import UpdatePriceNotice from './component/UpdatepriceNotice';

import withTracker from '../../common/tracker.js';

import {
  getLanguage,
  replaceUrlToHttps,
  sendNoviceGuidanceInfo
} from '../../utils/utils';
import { getImportProductShowData, getShopifyStoreDomain } from './util.js';
// import checkDocumentVisible from '../../utils/checkDocumentVisible';

import styles from './AliExpressProdcut.less';
import styless from './Product.less';
import stylesss from './importList.less';
import AdBanner from 'components/AdBanner';
import { aeUrlMatchReg, tmallUrlMatchReg } from '../../utils/constants';

import './importList.css';

import iconShop from '../../assets/myproduct/icon_shop.png';
import appIcon2 from '../../assets/app_icon2.jpg';
import logo from '../../assets/svg/logo_blue.svg';
import appIcon from '../../assets/app_icon.png';
import BannerCard from './BannerCard';
import GuideModal from 'routes/Order/GuideModal';
import addTags from '../../assets/newImportList/add_tags.png';
import cancelTags from '../../assets/newImportList/icon- delete.png';
import searchIcon from '../../assets/newImportList/icon-search.png';
import closeFilterIcon from '../../assets/newImportList/close-24px.png';
import clearsearch from 'assets/newImportList/clearsearch.svg';
import import_guide from 'assets/newImportList/import_guide.png';

import { GuildeAction, LinkStoreAction } from 'actions';

import { CommonDataCollect, getSupplierId } from '../../utils/utils';
import { currencySymbolMap } from '../../common/sysconfig';
import UpdatePrice from './Common/UpdatePrice';

import QuickPushModal from './component/QuickPushModal/index.jsx';

const { Panel } = Collapse;
const { Search } = Input;
const EditableContext = React.createContext();

// const documentVisibleCapability = checkDocumentVisible();
let currentComponent = null; // 存储组件实例引用

window.addEventListener(
  'message',
  e => {
    if (e.origin.includes('dsers.com') || e.origin.includes('localhost')) {
      if (e.data.type === 'EXISTS_PRODUCT_ADD') {
        if (currentComponent) {
          currentComponent.updateProductItemFromExtension(e.data.value);
        }
      }
    }
  },
  false
);

@connect(({ loading, topBtn, login, global, productmy, user }) => ({
  loading: loading.models.productmy,
  topBtn,
  lang: global.lang,
  userInfo: login.userInfo,
  optionLoading: loading.effects['productmy/deputySupplierOption'],
  historyLoading: loading.effects['productmy/deputySupplierHistory'],
  saveLoading: loading.effects['productmy/optionSaveSku'],
  getCountryLoading: loading.effects['productmy/supplierCountry'],
  getShippingLoading: loading.effects['productmy/supplierShipping'],
  saveSupplierLoading: loading.effects['productmy/saveSupplierShipping'],
  updateProductLoading: loading.effects['productmy/update'],
  getIsLoadingProducts: productmy.isLoadingProducts,
  noviceGuidanceInfo: user?.noviceGuidanceInfo
}))
@Form.create()
@withTracker
export default class ImportList extends PureComponent {
  listLock = false; // 仅执行一次，当拿到列表数据

  cachedPushData = null; // 缓存 push 任务的数据，用于查看详情时回填

  cachedPushResult = null; // push 结果

  constructor(props) {
    super(props);
    this.state = {
      isChanged: [],
      isChanged01: [],
      showDetail: false,
      checkedList: [],
      checkList: [],
      checkedVisibleList: [],
      showVisible: false,
      visibleProgress: 1,
      type: '',
      shop: '',
      productValue: '',
      showSetVisible: false, // 设置商品到shopify弹窗是否可见
      showSplitProduct: false,
      checked: false,
      disabled: false,
      checkListTag: [],
      isVisibleDropDown: false,
      checkListStore: [],
      isVisibleFilterDropDown: false,
      checkListTagFilter: [],
      dataColumn: [],
      editingKey: '',
      key: 0,
      tagVisible: false,
      listP: [],
      storesList: this.props.userInfo.stores || [],
      cancelChecked: false,
      checkAll: false,
      pricingLine: false,
      // 选中的 store
      storeCheck: [],
      list: [],
      bannerList: [],
      currentPush: [],
      pushOnline: false,
      continueSelling: false,
      selectedPushProducts: [],
      pushReqId: undefined,
      awaitPush: false, // 是否在 push 中
      pushResult: null, // push 结果
      pushState: 0, // 0: 初始值 1: push 中 2: push 结束
      pushShopifyLoading: false,
      guideModal: false,
      has_import: false,
      byPushStores: [
        {
          id: 1,
          name: intl.get('product.to_push_shopify'),
          checked: false
        },
        {
          id: 2,
          name: intl.get('product.no_push_shopify'),
          checked: false
        }
      ],
      tagVal: '',
      tagChecked: false,
      addTagChecked: false,
      tags_id: [],
      removeVisible: false,
      removeList: [],
      selectAllAddTags: false,
      isVisibleModal: false,
      isLoadingProducts: [],
      pricingRuleTip: true,
      shopSellPrice: [],
      priceLoading: false,
      changeFilterBySku: false,
      checkListStoreShow: [],
      checkListTagFilterShow: [],
      changeFilterBySkuShow: false,
      byPushStoresShow: [
        {
          id: 1,
          name: intl.get('product.to_push_shopify'),
          checked: false
        },
        {
          id: 2,
          name: intl.get('product.no_push_shopify'),
          checked: false
        }
      ],
      updateModalVisible: false,
      ifUseUpdate: false,
      updateReqId: undefined,
      updateLoading: false,
      updateLoadingProducts: [],
      tooltipVisible: false,
      supplier_type: undefined, // 供应商平台
      supplyTypeShow: '',
      selectValue: 'AliExpress',
      search_supplier_type: undefined,
      saveEditLoading: false
    };

    currentComponent = this;
  }

  componentWillMount() {
    this.cat = localStorage.getItem('storeId') || '';

    if (this.getQueryString('type')) {
      // window.open('https://home.aliexpress.com/dropshipper/ds_center.htm')
      window.location =
        'https://home.aliexpress.com/dropshipper/join_drop_shipper.htm';
    }
    if (localStorage.getItem('shopifyProductID')) {
      this.searchKey = localStorage.getItem('shopifyProductID');
      this.setState({
        productValue: localStorage.getItem('shopifyProductID')
      });
    }
  }

  componentDidMount() {
    console.log(this.props, '12222222222');
    console.log(this.props, 'location');
    // if (documentVisibleCapability.hidden) {
    //   document.addEventListener(
    //     documentVisibleCapability.visibilityChange,
    //     this.handleVisibilityChange,
    //     false
    //   );
    // }
    //1635835206
    //1646621010
    const create_time = JSON.parse(localStorage.getItem('store'))?.created_at;
    if (create_time > 1646621010) {
      localStorage.setItem('newuser_saw_pricing_mean', true);
    }

    const { has_import } = this.props.userInfo;
    this.setState({
      has_import
    });
    this.getStoreList();
    this.getBannerList();
    this.getTagList();

    let newDataColunm = this.state.dataColumn.map(item => {
      return {
        ...item,
        check: false
      };
    });
    this.setState({
      dataColumn: newDataColunm
    });
  }

  componentWillUnmount() {
    // document.removeEventListener(
    //   documentVisibleCapability.visibilityChange,
    //   this.handleVisibilityChange,
    //   false
    // );
  }

  componentDidUpdate(prevProps, prevState) {
    const { list, isLoadingProducts } = this.state;
    if (
      prevState.list !== list &&
      list &&
      list.prods &&
      list.prods.length > 0 &&
      !this.listLock
    ) {
      // 通知 guide
      this.listLock = true;
      dispatchEventForGuide({
        type: 'importList',
        payload: { category: 'rendered' }
      });
    }

    if (isLoadingProducts != prevState.isLoadingProducts) {
      this.props.dispatch({
        type: 'productmy/setIsLoadingProducts',
        payload: {
          data: isLoadingProducts
        }
      });
    }
  }

  getIfUseUpdate = async () => {
    const res = await Get('/prod/import_list/price/refresh', {});
    if (res && res.data && res.data.visible) {
      this.setState({
        ifUseUpdate: true
      });
    }
  };

  // 获取商店列表
  getStoreList = () => {
    const stores = this.props.userInfo.stores || [];

    this.realStoreData = stores.filter(
      item =>
        item.shop_name != 'Importlist' && item.shop_name != 'Imported Orders'
    );
  };

  getBannerList = async () => {
    try {
      const storeIds = this.realStoreData.map(item => {
        return item.id;
      });

      const params = {
        data: {
          store_ids: storeIds
        }
      };

      const res = await Get('/prod/propool/ad/list', params);
      if (res && res.code === 2000) {
        const bannerList = res.data;
        res.data.forEach(item => {
          item.isBanner = true;
        });
        this.setState({
          bannerList
        });
      }
    } catch (error) {
      console.log('fetch error:', error);
    }
    this.getProdocutList();
  };

  getProdocutList(type) {
    const { byPushStores } = this.state;

    if (type === 'filter') {
      this.setState({
        isVisibleFilterDropDown: false
      });
    }

    let isPushed = 0;

    if (byPushStores[0]['checked']) {
      isPushed = 1;
    }

    if (byPushStores[1]['checked']) {
      isPushed = 2;
    }

    const params = {
      page: this.currentPage,
      size: this.state.pageSize,
      search: this.searchKey,
      tags: this.state.checkListTagFilter.length
        ? this.state.checkListTagFilter
        : [],
      stores: this.state.checkListStore.length ? this.state.checkListStore : [],
      is_pushed: isPushed,
      sku_filter: this.state.changeFilterBySku ? 1 : 0,
      supplier_type: this.state.supplier_type
        ? this.state.supplier_type
        : this.state.search_supplier_type
    };
    if (!window.IS_UPDATE_PRODUCT) {
      params.untimeliness = true;
    } else {
      delete params.untimeliness;
    }

    if (window.DS_BEGINNER_GUIDE_ACTIVE) {
      Object.assign(params, { is_tutorial: 1 });
    }

    this.props.dispatch({
      type: 'productmy/listSelect',
      payload: {
        data: params,
        callback: data => {
          this.checkedVisibleList = {};
          this.setState({
            checkedList: [],
            bannerList: this.searchKey?.length ? [] : this.state.bannerList
          });
          if (data.code === 2000 || data.code === 2010) {
            // this.searchKey = '';
            this.isConfirm = 1;
            this.setState({
              list: data.data
            });
          }
        }
      }
    });
    localStorage.removeItem('shopifyProductID');
  }

  // handleVisibilityChange = () => {
  //   if (!document[documentVisibleCapability.hidden]) {
  //     console.log('handleVisibilityChange');
  //   }
  // };

  updateProductItemFromExtension = data => {
    if (data) {
      this.currentPage = 1;
      this.searchKey = data;
      this.getProdocutList();
    }
  };

  // 根据速卖通url抓取产品详情
  addProduct = e => {
    if (!aeUrlMatchReg.test(this.state.productValue)) {
      if (!tmallUrlMatchReg.test(this.state.productValue)) {
        this.currentPage = 1;
        this.getBannerList();
        return;
      }
    }
    this.props.dispatch({
      type: 'productmy/productSelectUrl',
      payload: {
        data: {
          url: this.state.productValue,
          product_id: getSupplierId(
            this.state.selectValue === 'Tmall' ? 4 : 2,
            this.state.productValue
          ),
          scene: 'dsers_page',
          ref: 'link',
          supplier_type: this.state.selectValue === 'Tmall' ? 4 : 2
        },
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            CommonDataCollect({
              action: 'IL-Import',
              event_type: 'import_product'
            });
            if (this.state.selectValue === 'Tmall') {
              CommonDataCollect({
                action: 'import_list_import_tmall_product',
                event_type: 'import_list_tmall'
              });
            }
            this.state.productValue = '';
            this.searchKey = '';
            this.setState(
              {
                search_supplier_type: undefined
              },
              () => {
                this.getProdocutList();
              }
            );
            // 上报已经导过商品
            if (this.props.noviceGuidanceInfo.imported == 0) {
              sendNoviceGuidanceInfo({ imported: 1 }, () => {
                this.props.dispatch({ type: 'user/getNoviceGuidance' });
              });
            }
            this.refreshProductNumber();
            if (d.msg == 'ok') {
              // this.setState({
              //   productValue: ''
              // });
              return;
            }
            if (Object.values(d.data).length > 0) {
              message.error(intl.get('product.already_imported'));
            }
          } else if (d.code === 4090) {
            // 直接搜索该商品
            this.searchKey = this.state.productValue;
            this.currentPage = 1;
            this.setState(
              {
                // search_supplier_type:undefined
              },
              () => {
                this.getProdocutList();
              }
            );
            return;
          }

          if (d.code === 5000) {
            // let errMsg = intl.get('import_list.fetch_ae_prod_error');
            // switch (d.msg) {
            //   case 'product id not found':
            //     errMsg = intl.get('import_list.ae_prod_not_found');
            //     break;
            //   case 'aliexpress server err':
            //     errMsg = intl.get('import_list.service_unavaliable');
            //     break;
            //   case 'remote server error':
            //     errMsg = intl.get('import_list.ae_issue');
            //     break;
            //   default:
            //     break;
            // }
            message.error(d.msg);
            this.setState({
              searchLoading: false
            });
            return;
          }

          if (
            d.code === 4290 &&
            d.msg ==
              'The quantity of products you imported to DSers has reached the "Product limit" of your current subscription plan. Please upgrade your subscription to import more products.'
          ) {
            // message.error(intl.get('import_list.fetch_ae_prod_error'));
            // window.EVENT.emit(
            //   GuildeAction.TurnPlan_Pop,
            //   `y-${Date.now()}`,
            //   true
            // );
            // window.open('/app/pricing')
            window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'ProductsLimit');
            return;
          }

          if (d.code === 4501) {
            message.error(intl.get('import_list.fetch_ae_prod_error1'));
            return;
          }

          if (d.code === 4601) {
            message.error(intl.get('import_list.fetch_tm_prod_error1'));
            return;
          }

          if (
            localStorage.getItem('aliExpressAuthStatus') != 'true' ||
            localStorage.getItem('aliBeOverdueTime') <= 0
          ) {
            message.error(intl.get('import_list.add_product_tip'));
          } else {
            message.error(intl.get('import_list.fetch_ae_prod_error1'));
          }
        }
      }
    });
  };

  initListMap(list) {
    this.listMap = {};
    list.forEach(item => {
      this.listMap[item.unique_id] = item;
    });
  }

  copyLink = item => {
    const prod = this.listMap[item.id];
    if (!prod.is_pub) {
      message.error(intl.get('product.copy_first_pushed'));
      return;
    }

    const stores = this.props.userInfo.stores || [];
    const storeId = localStorage.getItem('storeId');
    let domain = '';
    let storeName = '';

    stores.forEach(item => {
      if (item.id == storeId) {
        domain = item.domain;
        storeName = item.shop_name;
      }
    });

    domain = domain.includes('myshopify.com')
      ? domain
      : `${storeName}.myshopify.com`;

    let flag = false;
    let shopifyProductDetailUrl = '';
    let storeUrl = '';

    storeUrl = `https://${domain}/products/${prod.prod.detail.handle}`; // shopify商店商品详情
    shopifyProductDetailUrl = `https://${domain}/admin/products/${prod.prod.detail.id}`; // shopify管理后台商品编辑页
    flag = copy(storeUrl);

    if (flag) {
      message.success(intl.get('product.succ_copy'));
      window.open(shopifyProductDetailUrl, '_blank');
    } else {
      message.error(intl.get('product.copy_failed'));
    }
  };

  updateMyProduct = async (id, values, isPublish, moreOrganizationData) => {
    const { prod } = this.currentProdcut;
    const { user_id, ae_product_id } = prod;
    CommonDataCollect({
      user_id: user_id,
      // 店铺ID
      store_id: '',
      // 店铺名称
      shop_name: '',
      // 销售平台 shopify  wix  woocommerce
      sale_platform: 'shopify',
      // 销售平台对应商品ID
      sale_platform_product_id: '',
      // 销售平台对应订单ID
      sale_platform_order_id: '',
      // 供应商平台  aliexpress
      supply_platform: 'aliexpress',
      // 供应商平台商品ID
      supply_platform_product_id: ae_product_id,
      // 供应商平台订单ID
      supply_platform_order_id: '',
      // 操作来源 如dsers
      trans_from: '',
      // 操作目的 如shopify
      trans_to: 'import_list_push_to_shopify_edit_one',
      // 操作如 import_product
      action: 'import_list_push_to_shopify',
      // 表名第二段 模块名称 如: product
      event_type: 'subscription_analysis'
    });

    if (
      values.detail.specifications == '<p><br></p>' ||
      values.detail.specifications == '<h1><br></h1>'
    )
      values.detail.specifications = '';

    if (isPublish === false) {
      this.setState({
        saveEditLoading: true
      });
      if (this.currentProdcut?.supplier_type === 4) {
        CommonDataCollect({
          action: 'import_list_save_edit_product',
          event_type: 'import_list_tmall'
        });
        await Get(`/tmall/banner/${values.supplier_id}`).then(res => {
          if (res?.data?.code) {
            CommonDataCollect({
              event_type: 'tmall_banner_group',
              action: 'tmall_banner_product_edit',
              test_group: res.data.code,
              custom_data: [
                {
                  name: 'tmall_url',
                  value: `https://www.dsers.com/app/tmallproduct/${values.supplier_id}`
                },
                {
                  name: 'product_test_group',
                  value: res.data.code
                },
                {
                  name: 'tmall_product_id',
                  value: values.supplier_id
                }
              ]
            });
          }
        });
      }
      window.IS_UPDATE_PRODUCT = true;
      setTimeout(() => {
        window.IS_UPDATE_PRODUCT = false;
      }, 15 * 1000);
      this.props.dispatch({
        type: 'productmy/productEditImport',
        payload: {
          data: {
            unique_id: values.product_unique_id,
            detail: values.detail,
            ext: values.ext,
            setting: values.setting
          },
          message: {
            success: intl.get('product.update_succ')
          },
          callback: data => {
            if (data.code === 2010 || data.code === 2000) {
              this.hideDetail();
              this.saveMoreOrganizationData(moreOrganizationData);
            }
            this.setState({
              saveEditLoading: false
            });
          }
        }
      });
    } else if (isPublish == 'save_push') {
      if (this.currentProdcut?.supplier_type === 4) {
        CommonDataCollect({
          action: 'import_list_save_edit_product',
          event_type: 'import_list_tmall'
        });
      }
      this.setState({
        saveEditLoading: true
      });
      this.props.dispatch({
        type: 'productmy/productEditImport',
        payload: {
          data: {
            unique_id: values.product_unique_id,
            detail: values.detail,
            ext: values.ext,
            setting: values.setting
          },
          message: {
            success: intl.get('product.update_succ')
          },
          callback: data => {
            if (data.code === 2010 || data.code === 2000) {
              this.getProdocutList();
              this.saveMoreOrganizationData(moreOrganizationData);
            }
            this.setState({
              saveEditLoading: false
            });
          }
        }
      });
    } else if (isPublish) {
      // this.showSetVisibleFn(values);
      let flag_arr = []; // 是否编辑过price 或 compare at price
      const source = this.listMap[values.product_unique_id];
      source?.prod?.detail?.variants?.forEach(item => {
        values?.detail?.variants.forEach(it => {
          if (it.only_flag === item.sku) {
            if (
              it.price != item.price ||
              it.compare_at_price != item.compare_at_price
            ) {
              flag_arr.push(it);
            }
          }
        });
      });
      values?.detail?.variants.forEach(it => {
        delete it.only_flag;
      });
      this.resetPushState();
      this.setState(
        {
          showSetVisible: true,
          pricingLine: !!source?.price_rule && !flag_arr.length,
          selectedPushProducts: [
            {
              unique_id: values?.product_unique_id,
              image: values?.detail?.image?.src,
              id: values.id,
              supplier_type: values.supplier_type
            }
          ]
        },
        this.autoSelectedOnlyOneStore(
          [
            {
              unique_id: values?.product_unique_id,
              image: values?.detail?.image?.src,
              id: values.id,
              supplier_type: values.supplier_type
            }
          ],
          !!source?.price_rule && !flag_arr.length
        )
      );
      this.getProdocutList();
    }
    this.Child.setState({
      isHidden: false
    });
  };

  // 保存用户再编辑页手动输入的 tags vendor type
  saveMoreOrganizationData = moreOrganizationData => {
    if (moreOrganizationData?.length === 0) {
      this.getProdocutList();
      return;
    }

    this.props.dispatch({
      type: 'productmy/moreOrganizationData',
      payload: {
        data: {
          setting: moreOrganizationData
        },
        callback: data => {
          if (data.code === 2010 || data.code === 2000) {
            this.getProdocutList();
          }
        }
      }
    });
  };

  refreshProductNumber = () => {
    this.props.dispatch({
      type: 'topBtn/notificationUnreadLength',
      payload: {
        cancelMessage: true
      }
    });
  };

  RemoveProduct = ids => {
    if (this.state.showDetail) {
      this.setState({
        showDetail: false
      });
    }

    this.props.dispatch({
      type: 'productmy/deleteProduct',
      payload: {
        data: {
          unique_id: ids
        },
        callback: data => {
          if (data.code === 2010) {
            const { checkedVisibleList } = this.state;
            const list = checkedVisibleList.filter(item => item.id != ids);
            delete this.checkedVisibleList[ids];

            this.setState({
              checkedList: [],
              checkedVisibleList: list,
              showDetail: false,
              removeVisible: false,
              removeList: []
            });
            this.getProdocutList();
            this.refreshProductNumber();
          }
        }
      }
    });
  };

  removeProduct = ids => {
    // Modal.confirm({
    //   title: intl.get('import_list.delete_product_tip'),
    //   keyboard: false,
    //   okText: intl.getHTML('public.cancel'),
    //   okType: 'primary',
    //   cancelText: intl.getHTML('public.yes'),
    //   width: 560,
    //   onOk: () => {
    //     window.dataLayer.push({
    //       event: 'importListCustomEvent',
    //       type: 'deleteProductCancel'
    //     });
    //   },
    //   onCancel: () => {
    //     window.dataLayer.push({
    //       event: 'importListCustomEvent',
    //       type: 'deleteProductYes',
    //       payload: ids
    //     });

    //     this.RemoveProduct(ids);
    //   }
    // });

    this.setState({
      removeVisible: true,
      removeList: ids
    });
  };

  currentPage = 1;
  pageSize = 20;
  searchKey = '';
  currentProdcut = {};
  listMap = {};
  checkedStockState = 1;
  checkedVisibleList = {};
  visibleAll = false;
  visibleFailed = false;
  visibleIds = [];
  pageKey = 'product';
  cat = '';
  productStatus = 0;
  verified = 0;
  productPlatfromType = 0; // 筛选的产品类型 ae => 1 other => 2 all => 0
  realStoreData = [];
  currentLanguage = getLanguage();
  hasPushed = false; // push商品有没有真的点击push to shopify
  isClickPushedToStores = false; // 是不是点击的商品被推到几的商店的按钮
  pushProductsHasFailed = false; // 推商品到shopify有没有失败的 false 没有  true 有

  isConfirm = 0;
  checkListTagFilterCopy = [];
  checkListStoreCopy = [];
  isChanged01Copy = [];
  changeFilterBySkuCopy = false;
  byPushStoresCopy = [];
  supplyTypeCopy = '';

  // 编辑跳转
  showDetail = (item, key) => {
    this.currentProdcut = JSON.parse(JSON.stringify(item));
    this.pageKey = key;
    if (item?.supplier_type === 4) {
      CommonDataCollect({
        action: 'import_list_card_click_edit_tmall_product',
        event_type: 'import_list_tmall'
      });
    }
    if (!key) {
      const { prod } = item;
      const { user_id, ae_product_id } = prod;
      CommonDataCollect({
        user_id: user_id,
        // 店铺ID
        store_id: '',
        // 店铺名称
        shop_name: '',
        // 销售平台 shopify  wix  woocommerce
        sale_platform: 'shopify',
        // 销售平台对应商品ID
        sale_platform_product_id: '',
        // 销售平台对应订单ID
        sale_platform_order_id: '',
        // 供应商平台  aliexpress
        supply_platform: 'aliexpress',
        // 供应商平台商品ID
        supply_platform_product_id: ae_product_id,
        // 供应商平台订单ID
        supply_platform_order_id: '',
        // 操作来源 如dsers
        trans_from: '',
        // 操作目的 如shopify
        trans_to: '',
        // 操作如 import_product
        action: 'edit_product',
        // 表名第二段 模块名称 如: product
        event_type: 'subscription_analysis'
      });
    }
    this.setState({
      showDetail: true
    });
  };

  EditProduct = item => {
    this.props.dispatch({
      type: 'productmy/productEditImport',
      payload: {
        data: {
          unique_id: item.unique_id,
          detail: item.detail
        },
        callback: data => {
          if (data.code == 2010 || data.code == 2000) {
            // console.log(data,'data------------')
          }
        }
      }
    });
  };

  hideDetail = () => {
    this.setState({
      showDetail: false
    });
    this.getProdocutList();
  };

  changePager = (page, pageSize) => {
    this.currentPage = page;
    this.setState(
      {
        pageSize: pageSize
      },
      () => {
        this.getProdocutList();
      }
    );
  };

  // 商品列表选中更改
  changeCheck = (value, list) => {
    list.forEach(item => {
      if (value.includes(item.unique_id)) {
        this.checkedVisibleList[item.unique_id] = item;
      } else {
        delete this.checkedVisibleList[item.unique_id];
      }
    });
    this.setState({
      checkedList: value,
      checkList: list
    });
  };

  closeModal = () => {
    this.setState({
      guideModal: false
    });
  };

  // 搜索框输入值变化
  changeSearchKey = e => {
    window.dataLayer.push({
      event: 'importListCustomEvent',
      type: 'importButton',
      payload: e?.target?.value
    });

    this.searchKey = e?.target?.value;

    this.setState(
      {
        productValue: e?.target?.value
      },
      () => {
        if (!e) {
          this.setState(
            {
              search_supplier_type: undefined
            },
            () => {
              this.getProdocutList();
            }
          );
        }
      }
    );
  };

  SelectClick = ''; // 下拉默认第一个
  SelectClickId = ''; // 下拉默认第一个ID
  saveShipmentWho = 1; // 保存副供应商物流 1 => option1  2 => option2
  clickLogistics = [];
  valueId = '';

  getVisibleColumns = () => {
    // 获取visible进度tr内容
    const visibleColumns = [
      {
        title: intl.get('product_edit.picture'),
        dataIndex: 'image',
        width: 100,
        render: (image, record) => {
          return <img src={replaceUrlToHttps(image)} style={{ height: 40 }} />;
        }
      },
      {
        title: intl.get('product_edit.title'),
        dataIndex: 'title'
      },
      {
        title: intl.get('order.fulfill_confirm.table_title.feedback'),
        dataIndex: 'feedback',
        width: 100,
        render: (_, record) => {
          return (
            <Icon
              type={record.visibleStatus}
              style={{
                width: 16,
                display: 'block',
                margin: '0 auto',
                fontSize: 16,
                color: `${
                  record.visibleStatus == 'close-circle-o'
                    ? '#f5222d'
                    : '#52c41a'
                }`
              }}
            />
          );
        }
      },
      {
        title: intl.get('product.possible_cause'),
        width: 140,
        dataIndex: 'visibleResult'
      }
    ];

    return visibleColumns;
  };

  // 隐藏visible弹窗
  hideVisibleModal = () => {
    const obj = this.setSelectedRows();
    this.setState({
      showVisible: false,
      visibleProgress: 1,
      checkedList: obj.ids,
      checkedVisibleList: obj.list
    });
    this.visibleAll = false;
    this.visibleFailed = false;
  };

  // 更换商店
  changeStore = value => {
    this.setState({
      storename: value
    });

    this.currentPage = 1;
    this.getProdocutList();
  };

  // visible完成时checked里去掉成功的
  setSelectedRows = () => {
    let ids = [...this.state.checkedList];
    let list = Object.values(this.checkedVisibleList);
    list = list.filter(item => {
      return !this.visibleIds.includes(item.id);
    });
    ids = ids.filter(val => {
      return !this.visibleIds.includes(val);
    });
    this.visibleIds.forEach(val => {
      delete this.checkedVisibleList[val];
    });
    return { ids, list };
  };

  getQueryString = name => {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
    const hash = `?${location.hash.split('?')[1]}`;
    const r = hash.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  };

  // Manage product问号提示关闭回调
  manageProductTipBack = v => {
    if (!v) {
      localStorage.setItem('hasCloseManageProductTip', 1);
    }
  };

  changeCateGory = v => {
    this.currentPage = 1;
    this.productStatus = v;
    this.checkedVisibleList = {};
    this.setState({
      checkedList: []
    });
    this.getProdocutList();
  };

  // 设置商品到shopify弹窗是否可见 隐藏
  hideSetVisible = () => {
    this.publishId = null;
    this.publishStatus = null;
    this.pushProductsHasFailed = false;
    localStorage.setItem('saw_pricing_mean', true); //用户已经看过pricing rule 的解释
    // this.currentPush = []
    this.setState({
      showSetVisible: false,
      currentPush: [],
      pricingLine: false
    });

    if (this.hasPushed) {
      this.hasPushed = false;
      this.getProdocutList();
    }
  };

  handleOkPush = () => {
    let flag = true;

    this.state.currentPush.forEach(item => {
      if (item.data && item.data.length == 0) {
        flag = false;
      }
    });

    if (flag) {
      this.hasPushed = true;
      this.setState({
        pushShopifyLoading: true
      });

      window.dataLayer.push({
        event: 'importListCustomEvent',
        type: 'pushToShopify',
        payload: this.state.currentPush
      });

      this.submitPush();
    }
  };

  hasSetListLoading = false;
  fulfilledOverSucc = false;

  submitPush = () => {
    // if( !this.hasSetListLoading ){
    this.setListLoading(this.state.currentPush);
    // this.hasSetListLoading = true;
    // }

    let ind = 0;
    const subCollect = t => {
      const col = this.state.currentPush[t];
      const { data } = col;
      const len = data.length;

      this.props.dispatch({
        type: 'productmy/pushShopifyProduct',
        payload: {
          data: {
            unique_id: col.product_unique_id || col.unique_id,
            store: data[ind].storeId,
            published: this.alsoOnline,
            price_rule: this.state.pricingLine ? 1 : 2,
            inventory_policy: this.continueSelling
          },
          callback: d => {
            if (d.msg === 'product quota err') {
              localStorage.setItem('passivity', 'true');
              //  跳转订阅 && 弹窗拦截
              // window.EVENT.emit(
              //   GuildeAction.TurnPlan_Pop,
              //   `q-${Date.now()}`,
              //   true
              // );
              // self.location.href = '/app/pricing';
              window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'ProductsLimit');
            }

            if (d.code === 2000 || d.code === 2010) {
              dispatchEventForGuide({
                type: 'importList',
                payload: { category: 'PUSH_TO_SP_SUCCESS' }
              });
              // hello
              data[ind].feedback = 'check-circle';
            } else {
              dispatchEventForGuide({
                type: 'importList',
                payload: { category: 'PUSH_TO_SP_FAILED' }
              });
              data[ind].feedback = 'close-circle';
              data[ind].visibleResult = d.msg;
              this.pushProductsHasFailed = true;
            }

            this.forceUpdate();

            if (len > ind + 1) {
              ind += 1;
              subCollect(t);
              return;
            }

            if (this.state.currentPush.length > t + 1) {
              ind = 0;
              subCollect(t + 1);
              return;
            }

            this.setState({
              pushShopifyLoading: false
            });
            if (!this.pushProductsHasFailed) {
              this.hideSetVisible();
            }
          }
        }
      });
    };

    subCollect(0);
  };

  // 设置结果状态
  setFeedback = (i, code) => {
    const dataAll = this.currentPush;
    let result = true;

    if (code !== 2000 && code !== 2010) {
      result = false;
    }

    dataAll[i].data.forEach(child => {
      if (child.markAsFulfilledChecked) {
        child.feedback = result ? 'check-circle' : 'close-circle';
        this.forceUpdate();
      }
    });
  };

  pushProcessMarkup = data => {
    if (!data) {
      return null;
    }
    let href = 'javascript:;';
    if (data.store_myshopify_url) {
      const storeUrl = data.store_myshopify_url.replace(/^https:\/\//, '');
      href = `https://loox.io/reviews-importer/dsers?shop=${storeUrl}&product_id=${data.shopify_product_id}&ext_product_id=${data.ali_express_product_id}`;
    }

    if (data === 1) {
      return (
        <>
          <i style={{ color: 'red' }} class="material-icons">
            cancel
          </i>
          <span style={{ color: 'red' }} title="Something went wrong">
            Something went wrong
          </span>
        </>
      );
    }

    if (data.status === 0) {
      return <Icon type="loading" style={{ color: '#fe8f24', fontSize: 16 }} />;
    }
    if (data.status === 1) {
      return (
        <>
          <i class="material-icons notranslate" style={{ color: '#8BC34A' }}>
            check_circle
          </i>
          {data.unique_id.substr(-5, 5) === 'TMall' ? null : (
            <a href={href} target="_blank" rel="noopener noreferrer">
              <Button
                className={styles.button}
                style={{
                  height: 28,
                  padding: '0 8px',
                  margin: '14px 5px',
                  backgroundColor: '#0056DF',
                  borderRadius: 4,
                  position: 'relative',
                  border: '1px solid #0056DF'
                }}
              >
                <img
                  className={styles.bannerImg}
                  src={appIcon}
                  style={{
                    width: 20,
                    height: 20,
                    position: 'absolute',
                    top: 3,
                    left: 4
                  }}
                />
                <span
                  style={{
                    height: 26,
                    lineHeight: '26px',
                    marginLeft: 24,
                    fontSize: 12,
                    fontWeight: 400,
                    color: '#FFFFFF'
                  }}
                >
                  {intl.get('import_list.import_review')}
                </span>
              </Button>
            </a>
          )}
        </>
      );
    }
    if (data.status === 2) {
      const reg = /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
      const url = data?.err_msg?.match(reg) && data?.err_msg?.match(reg)[0];
      const specailErr = data?.err_msg?.includes(
        'Daily variant creation limit reached'
      );
      const msg =
        data.code < 6
          ? intl.get(`import_list.push_error_${data.code}`)
          : url && specailErr
          ? intl.getHTML('import_list.variants_limit', { url: url })
          : data.err_msg;
      return (
        <>
          <i style={{ color: '#red' }} class="material-icons">
            cancel
          </i>
          <span style={{ color: 'red' }} title={msg}>
            {msg}
            {data.code === 4 ? (
              <Tooltip title={intl.get('import_list.push_error_4_tip')}>
                <span>
                  <i
                    class="material-icons notranslate"
                    style={{ fontSize: 22 }}
                  >
                    help_outline
                  </i>
                </span>
              </Tooltip>
            ) : null}
          </span>
        </>
      );
    }
    return null;
  };

  // 设置loading状态
  setListLoading = dataList => {
    dataList.map(item => {
      item.data.map(child => {
        if (child.markAsFulfilledChecked || child.feedback == '') {
          child.feedback = 'loading';
          this.forceUpdate();
        }
      });
    });
  };

  handlePushOne = data => {
    this.resetPushState();
    console.log('handlePushOne', data);

    const { prod } = data;
    const { user_id, ae_product_id } = prod;

    CommonDataCollect({
      user_id: user_id,
      // 店铺ID
      store_id: '',
      // 店铺名称
      shop_name: '',
      // 销售平台 shopify  wix  woocommerce
      sale_platform: 'shopify',
      // 销售平台对应商品ID
      sale_platform_product_id: '',
      // 销售平台对应订单ID
      sale_platform_order_id: '',
      // 供应商平台  aliexpress
      supply_platform: 'aliexpress',
      // 供应商平台商品ID
      supply_platform_product_id: ae_product_id,
      // 供应商平台订单ID
      supply_platform_order_id: '',
      // 操作来源 如dsers
      trans_from: 'import_list_push_to_shopify_one',
      // 操作目的 如shopify
      trans_to: '',
      // 操作如 import_product
      action: 'import_list_push_to_shopify',
      // 表名第二段 模块名称 如: product
      event_type: 'subscription_analysis'
    });

    this.setState({
      showSetVisible: true,
      pushState: 0,
      pricingLine: !!data.price_rule,
      selectedPushProducts: [
        {
          unique_id: data.unique_id,
          image: data.image,
          id: data.id,
          supplier_type: data.supplier_type
        }
      ]
    });
    this.autoSelectedOnlyOneStore(
      [
        {
          unique_id: data.unique_id,
          image: data.image,
          id: data.id,
          supplier_type: data.supplier_type
        }
      ],
      !!data.price_rule
    );

    // 新手引导时，可能存在两个以上店铺，需要自动选中一个
    if (window.DS_BEGINNER_GUIDE_ACTIVE) {
      if (this.realStoreData && this.realStoreData.length > 1) {
        this.setState({
          storeCheck: [this.realStoreData[0].id]
        });
        //存到本地
        localStorage.setItem(
          'storeCheck',
          JSON.stringify([this.realStoreData[0].id])
        );
      }
    }
  };

  handleBatchPushQuickPush = (
    data,
    quickPush = false,
    speedinessDotData = ''
  ) => {
    let price_rule = true;

    const GET_ID = data.map(item => {
      return item.unique_id;
    });

    this.props.dispatch({
      type: 'productmy/listSelect',
      payload: {
        data: {},
        callback: result => {
          result.data.prods.forEach(item => {
            if (GET_ID.includes(item.unique_id) && !item.price_rule) {
              price_rule = false;
            }
          });

          this.handleBatchPush(data, quickPush, speedinessDotData, price_rule);
        }
      }
    });
  };

  handleBatchPush = (
    data,
    quickPush = false,
    speedinessDotData = '',
    price_rule = true
  ) => {

    this.resetPushState();

    let target = [];

    let price = price_rule;
    let dotData = speedinessDotData;

    if (!quickPush) {
      const { checkedList } = this.state;
      target = checkedList.reduce((acc, cur) => {
        const source = this.listMap[cur];
        if (source) {
          if (!source.price_rule) {
            price = false;
          }
          dotData = source;
          return acc.concat({
            unique_id: source.unique_id,
            image: source.image,
            id: source.id,
            supplier_type: source.supplier_type
          });
        }

        return acc;
      }, []);
    }

    console.log('handleBatchPush', target);
    this.setState({
      pushState: 0,
      showSetVisible: true,
      pricingLine: price,
      selectedPushProducts: quickPush ? data : target
    });

    this.autoSelectedOnlyOneStore(target, price);

    const { prod } = dotData;
    const { user_id, ae_product_id } = prod;

    CommonDataCollect({
      user_id: user_id,
      // 店铺ID
      store_id: '',
      // 店铺名称
      shop_name: '',
      // 销售平台 shopify  wix  woocommerce
      sale_platform: 'shopify',
      // 销售平台对应商品ID
      sale_platform_product_id: '',
      // 销售平台对应订单ID
      sale_platform_order_id: '',
      // 供应商平台  aliexpress
      supply_platform: 'aliexpress',
      // 供应商平台商品ID
      supply_platform_product_id: ae_product_id,
      // 供应商平台订单ID
      supply_platform_order_id: '',
      // 操作来源 如dsers
      trans_from: 'import_list_push_to_shopify_all',
      // 操作目的 如shopify
      trans_to: '',
      // 操作如 import_product
      action: 'import_list_push_to_shopify',
      // 表名第二段 模块名称 如: product
      event_type: 'subscription_analysis'
    });

    window.dataLayer.push({
      event: 'importListCustomEvent',
      type: 'batchPushButton'
    });
  };

  handlePushSubmit = e => {
    // console.log(this.currentProdcut,this.state.selectedPushProducts,'handlePushSubmit');
    if (this.currentProdcut?.supplier_type === 4) {
      CommonDataCollect({
        action: 'confirm_push_to_shopify',
        event_type: 'import_list_tmall'
      });
    }
    const {
      selectedPushProducts,
      storeCheck,
      pushOnline,
      continueSelling
    } = this.state;
    const { userInfo, noviceGuidanceInfo } = this.props;
    const reqId = uuidv4();

    const pushFail = () => {
      this.setState({
        pushShopifyLoading: false,
        awaitPush: false
      });
    };

    const result = storeCheck.reduce((acc, cur) => {
      const items = selectedPushProducts.map(item => {
        const target = {
          unique_id: item.unique_id,
          store: cur,
          request_id: reqId,
          published: pushOnline,
          price_rule: this.state.pricingLine ? 1 : 2,
          inventory_policy: continueSelling
        };

        if (window.DS_BEGINNER_GUIDE_ACTIVE) {
          Object.assign(target, { is_tutorial: 1 });
        }
        return target;
      });
      return acc.concat(items);
    }, []);

    this.setState({
      pushShopifyLoading: true,
      awaitPush: true,
      isLoadingProducts: e
    });

    try {
      pushProduct({ data: result })
        .then(res => {
          if ([2000, 2010].includes(res.code)) {
            if (res.msg === 'product quota err') {
              localStorage.setItem('passivity', 'true');
              //  跳转订阅 && 弹窗拦截
              // window.EVENT.emit(
              //   GuildeAction.TurnPlan_Pop,
              //   `x-${Date.now()}`,
              //   true
              // );
              //  window.location.href = '/app/pricing';
              window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'ProductsLimit');
            }
            const cachedValue = {
              request_id: reqId,
              published: pushOnline,
              price: this.state.pricingLine,
              stores: storeCheck,
              items: selectedPushProducts,
              u_id: userInfo.user_id,
              time: Date.now(),
              shopSellPrice: this.state.shopSellPrice
            };

            // localStorage.removeItem('pushCheckStoreId');

            this.setState({ showSetVisible: false });

            // 上报已经push过商品
            if (noviceGuidanceInfo.pushed == 0) {
              sendNoviceGuidanceInfo({ pushed: 1 }, () => {
                this.props.dispatch({ type: 'user/getNoviceGuidance' });
              });
            }

            // 新手引导后端走同步推送
            if (window.DS_BEGINNER_GUIDE_ACTIVE) {
              this.setState({
                pushShopifyLoading: false,
                awaitPush: false
              });

              if (res.data && res.data.every(i => i.status === 1)) {
                dispatchEventForGuide({
                  type: 'importList',
                  payload: { category: 'PUSH_TO_SP_SUCCESS' }
                });
              } else {
                dispatchEventForGuide({
                  type: 'importList',
                  payload: { category: 'PUSH_TO_SP_FAILED' }
                });
              }

              return null;
            }

            try {
              // 重开浏览器也要检测
              localStorage.setItem('push_task', JSON.stringify(cachedValue));
              this.setState({ pushReqId: reqId });
            } catch (error) {
              console.log('handlePushSubmit error', error);
            }
          } else {
            pushFail();
            if (window.DS_BEGINNER_GUIDE_ACTIVE) {
              dispatchEventForGuide({
                type: 'importList',
                payload: { category: 'PUSH_TO_SP_FAILED' }
              });
            }
          }

          return null;
        })
        .catch(error => {
          console.log(error);
          pushFail();
          if (window.DS_BEGINNER_GUIDE_ACTIVE) {
            dispatchEventForGuide({
              type: 'importList',
              payload: { category: 'PUSH_TO_SP_FAILED' }
            });
          }
        });
    } catch (error) {
      console.log(error);
      pushFail();
    } finally {
      this.setState({
        pushShopifyLoading: false
      });
    }
  };

  handleCheckPushDetail = () => {
    console.log('handleCheckPushDetail');
    if (this.cachedPushData) {
      this.setState({
        showSetVisible: true,
        pricingLine: this.cachedPushData.price,
        pushOnline: this.cachedPushData.published,
        storeCheck: this.cachedPushData.stores,
        selectedPushProducts: this.cachedPushData.items
      });
    }

    if (this.cachedPushResult) {
      this.setState({
        pushResult: this.cachedPushResult
      });
    }
  };

  handleStartPushProcess = data => {
    console.log('handleStartPushProcess');
    this.cachedPushData = data;

    if (data && this.state.selectedPushProducts.length === 0) {
      this.setState({
        selectedPushProducts: data.items
      });
    }
  };

  handleFinishPushProcess = () => {
    this.setState({ awaitPush: false, pushState: 2, isLoadingProducts: [] });
    this.getProdocutList();
    dispatchEventForGuide({
      type: 'importList',
      payload: { category: 'PUSH_TO_SP_SUCCESS' }
    });
  };

  handlePendingPushProcess = data => {
    console.log('handlePendingPushProcess');
    this.cachedPushResult = data;
    this.setState({ pushResult: data, awaitPush: true, pushState: 1 });
  };

  handleErrorPushProcess = () => {
    this.cachedPushResult = 1;
    this.setState({ awaitPush: false, pushResult: 1, isLoadingProducts: [] });
    this.getProdocutList();
    dispatchEventForGuide({
      type: 'importList',
      payload: { category: 'PUSH_TO_SP_FAILED' }
    });
  };

  handleUpdatePriceStart = uniqueId => {
    localStorage.setItem('updateprice_task', uniqueId);
    this.setState({
      updateReqId: uniqueId,
      updateLoading: true,
      updateModalVisible: false
    });
  };

  handleUpdatePricePendingProcess = data => {
    this.setState({
      updateLoading: true,
      updateLoadingProducts: data.running_product_id_list
    });
  };

  handleUpdatePriceFinsh = () => {
    this.setState({
      updateLoading: false,
      updateLoadingProducts: []
    });
    this.getProdocutList();
  };

  handleUpdatePriceError = () => {
    this.setState({
      updateLoading: false,
      updateLoadingProducts: []
    });
    this.getProdocutList();
  };

  resetPushState = () => {
    this.setState({
      storeCheck: [],
      pushOnline: false,
      continueSelling: false,
      selectedPushProducts: [],
      pushResult: null,
      pushReqId: undefined,
      pricingLine: false
    });
    this.cachedPushData = null;
    this.cachedPushResult = null;
  };

  // 设置商品到shopify弹窗是否可见 可见
  showSetVisibleFn = (product, key) => {
    if (key == 1) {
      this.setState({
        storeCheck: []
      });

      const { checkedList } = this.state;
      let dataPushList = [];
      const priceRule = [];

      dataPushList = product.filter(item =>
        checkedList.includes(item.unique_id)
      );

      dataPushList.forEach(item => {
        item.data = [];
        priceRule.push(item.price_rule);
      });

      this.setState({
        currentPush: dataPushList,
        pricingLine: !priceRule.includes(false)
      });
    } else {
      product.data = [];
      this.setState({
        pricingLine: product.price_rule == true,
        storeCheck: [],
        currentPush: [{ ...product }]
      });
    }

    this.alsoOnline = false;
    this.setState(
      {
        showSetVisible: true
      },
      this.autoSelectedOnlyOneStore
    );

    this.forceUpdate();
  };

  // 只有一个店铺时默认选中
  autoSelectedOnlyOneStore = (product, pricerule) => {
    if (this.realStoreData.length === 1 && !this.isClickPushedToStores) {
      this.changStoreCheck([this.realStoreData[0].id]);
      this.isClickPushedToStores = false;
      if (!product) {
        return;
      }
      if (this.realStoreData[0].id) {
        this.getShopSellPrice(product, [this.realStoreData[0].id], pricerule);
      }
    }
  };

  // 这是比较函数
  compare = p => {
    return function(m, n) {
      const a = m[p];
      const b = n[p];
      return a - b; // 升序
    };
  };

  Unique(arr, name) {
    const hash = {};
    return arr.reduce((item, next) => {
      hash[next[name]] ? '' : (hash[next[name]] = true && item.push(next));
      return item;
    }, []);
  }

  publishStatus = null;
  publishId = null;
  alsoOnline = false; // 发布到shopify还是上线

  // 更改发布到shopify还是上线
  changeAlsoOnline = e => {
    const { checked } = e.target;

    window.dataLayer.push({
      event: 'importListCustomEvent',
      type: 'alsoPublishOnLineCheck',
      payload: checked
    });
    this.setState({ pushOnline: checked });

    this.alsoOnline = checked;
  };

  changeSelling = e => {
    const { checked } = e.target;
    this.setState({ continueSelling: checked });
    this.continueSelling = checked;
  };

  // pricing
  changePricing = e => {
    const { checked } = e.target;
    window.dataLayer.push({
      event: 'importListCustomEvent',
      type: 'pricingRuleCheck',
      payload: checked
    });

    this.setState({
      pricingLine: checked
    });
    if (this.state.storeCheck.length) {
      this.getShopSellPrice(
        this.state.selectedPushProducts,
        this.state.storeCheck,
        checked
      );
    }
  };

  setDataChecked = data => {
    data.forEach(item => {
      item.data.forEach(child => {
        if (child.storeId != id) {
          child.markAsFulfilledChecked = false;
        } else {
          child.markAsFulfilledChecked = true;
        }
      });
    });
    return data;
  };

  // 勾选商店
  changStoreCheck = val => {
    // 取最后一个id 新手引导才生效
    const { status } = this.props.userInfo;
    // 已经缓存过的用户清除标记
    localStorage.removeItem('pushCheckStoreId');
    // if (
    //   String(status).substr(1, 1) === '0' &&
    //   localStorage.getItem('noMigra') != 'false'
    // ) {
    //   let dsid = null;
    //   if (val.length > 1) {
    //     dsid = val[0];
    //     localStorage.setItem('pushCheckStoreId', dsid);
    //   } else {
    //     localStorage.setItem('pushCheckStoreId', val[0]);
    //   }
    // }

    localStorage.setItem('storeCheck', JSON.stringify(val));

    window.dataLayer.push({
      event: 'importListCustomEvent',
      type: 'selectStores',
      payload: val
    });
    this.setState({
      storeCheck: val
    });
    if (
      val.length <= this.state.storeCheck.length ||
      !this.state.selectedPushProducts.length
    ) {
      return;
    }
    if (val) {
      this.getShopSellPrice(
        this.state.selectedPushProducts,
        val,
        this.state.pricingLine
      );
    }
  };

  // 全选
  changeCheckAll = e => {
    this.changeCheck(
      e.target.checked ? Object.keys(this.listMap) : [],
      Object.values(this.listMap)
    );
  };

  // 获取供应商id
  getAliId = item => {
    const supplier_default = item.supplier_default || [];
    return supplier_default[0] ? supplier_default[0] : item.platfrom_id;
  };

  splitProduct = undefined; // 要拆分的商品

  // 展示商品拆分
  showSplitProduct = pro => {
    const { prod } = pro;
    const { user_id, ae_product_id } = prod;

    console.log(pro, 'prod');
    const sku = pro?.prod?.detail?.variants;
    if (!sku || sku?.length < 2) {
      message.error(intl.get('product_split.select_split_mothod'));
      return;
    }
    this.splitProduct = pro;

    CommonDataCollect({
      user_id: user_id,
      // 店铺ID
      store_id: '',
      // 店铺名称
      shop_name: '',
      // 销售平台 shopify  wix  woocommerce
      sale_platform: 'shopify',
      // 销售平台对应商品ID
      sale_platform_product_id: '',
      // 销售平台对应订单ID
      sale_platform_order_id: '',
      // 供应商平台  aliexpress
      supply_platform: 'aliexpress',
      // 供应商平台商品ID
      supply_platform_product_id: ae_product_id,
      // 供应商平台订单ID
      supply_platform_order_id: '',
      // 操作来源 如dsers
      trans_from: '',
      // 操作目的 如shopify
      trans_to: '',
      // 操作如 import_product
      action: 'split_into_multiple_products',
      // 表名第二段 模块名称 如: product
      event_type: 'subscription_analysis'
    });

    this.setState({
      showSplitProduct: true
    });
  };

  // 隐藏商品拆分
  hideSplitProduct = flag => {
    this.setState({
      showSplitProduct: false
    });
    flag && this.getProdocutList();
  };

  addBlackProduct = null;

  // onChangeChecked = (e, data) => {
  //   console.log(e);
  //   const { checkListTag } = this.state;
  //   data.check = e.target.checked;
  //   this.forceUpdate();
  //   if (e.target.checked) {
  //     checkListTag.push(data);
  //   } else {
  //     const idx = checkListTag.findIndex(item => {
  //       return item.id === data.id;
  //     });
  //     if (idx >= 0) {
  //       checkListTag.splice(idx, 1);
  //     }
  //   }
  //   this.setState({
  //     checkListTag
  //   });
  // };

  onChangeCheckedFilter = (e, data) => {
    const { checkListTagFilter } = this.state;
    data.check = e.target.checked;
    // this.forceUpdate();
    let arr = [...checkListTagFilter];
    if (e.target.checked) {
      arr.push(data.id);
    } else {
      const idx = arr.findIndex(item => {
        return item === data.id;
      });
      if (idx >= 0) {
        arr.splice(idx, 1);
      }
    }
    this.setState({
      checkListTagFilter: arr
    });
  };

  newarr01 = [];

  onChangeFilter = id => {
    if (!this.newarr01.includes(id)) {
      this.newarr01.push(id);
      this.setState({
        isChanged01: [...this.newarr01]
      });
    } else {
      this.newarr01 = this.newarr01.filter(item => item !== id);
      this.setState({
        isChanged01: [...this.newarr01]
      });
    }
  };

  onChangeStore = (e, id) => {
    const { checkListStore } = this.state;
    const {
      target: { checked }
    } = e;
    let arr = [...checkListStore];

    if (checked) {
      if (id) {
        arr.push(id);
      }
    } else {
      arr = arr.filter(i => i !== id);
    }

    this.setState({
      checkListStore: arr,
      byPushStores: [
        {
          id: 1,
          name: intl.get('product.to_push_shopify'),
          checked: false
        },
        {
          id: 2,
          name: intl.get('product.no_push_shopify'),
          checked: false
        }
      ]
    });
  };

  clearCheck = () => {
    const dataTag = this.state.checkListTag;
    dataTag.forEach(item => {
      item.check = false;
      this.forceUpdate();
    });
  };

  confirmCheck = async () => {
    const { checkListTag, checkedList } = this.state;
    const newArr = [];
    checkListTag.forEach(item => {
      newArr.push(item.id);
    });

    window.dataLayer.push({
      event: 'importListCustomEvent',
      type: 'BatchAddTag',
      payload: {
        id: newArr,
        tags: checkedList
      }
    });

    try {
      const res = await productSelectAddTags({
        data: {
          unique_id: checkedList,
          tags: newArr
        }
      });
      if ([2000, 2010].includes(res.code)) {
        this.getTagList();
        this.setState(
          {
            isVisibleDropDown: false,
            checkListTag: []
          },
          () => {
            this.getProdocutList();
          }
        );
      }
    } catch (error) {
      console.log(error);
    }

    // this.batchAddTags(newArr, checkedList);
  };

  batchAddTags = (tagId, productId) => {
    this.props.dispatch({
      type: 'productmy/productSelectTag',
      payload: {
        data: {
          unique_id: productId,
          tags: tagId
        },
        callback: data => {
          if (data.code === 2010 || data.code === 2000) {
            this.getTagList();
            this.setState(
              {
                isVisibleDropDown: false,
                checkListTag: [],
                addTagVisible: false,
                tagVisible: false
              },
              () => {
                this.getProdocutList();
              }
            );
          }
        }
      }
    });
  };

  batchAddSeveralTags = (tagId, productId) => {
    this.props.dispatch({
      type: 'productmy/productSelectSeveralTag',
      payload: {
        data: {
          unique_id: productId,
          tags: tagId
        },
        callback: data => {
          if (data.code === 2010 || data.code === 2000) {
            this.getTagList();
            this.setState(
              {
                isVisibleDropDown: false,
                checkListTag: [],
                addTagVisible: false,
                tagVisible: false
              },
              () => {
                this.getProdocutList();
              }
            );
          }
        }
      }
    });
  };

  handleVisibleChange = flag => {
    this.setState({
      isVisibleDropDown: flag
    });
  };

  handleVisibleChangeFilter = flag => {
    const {
      checkListTagFilter,
      checkListStore,
      isChanged01,
      changeFilterBySku,
      byPushStores,
      supplier_type
    } = this.state;
    // 交互打点
    this.getTagList();
    CommonDataCollect({
      user_id: this.props.userInfo.user_id,
      sale_platform: 'shopify',
      supply_platform: 'aliexpress',
      trans_from: 'importlist click filter tags button',
      action: 'click_filter_button',
      event_type: 'interactive'
    });

    this.isConfirm = 0;
    (this.checkListTagFilterCopy = checkListTagFilter),
      (this.checkListStoreCopy = checkListStore),
      (this.isChanged01Copy = isChanged01),
      (this.changeFilterBySkuCopy = changeFilterBySku),
      (this.byPushStoresCopy = byPushStores);
    this.supplyTypeCopy = supplier_type;
    this.setState({
      isVisibleFilterDropDown: true
    });
  };

  clearCheckFilter = () => {
    this.currentPage = 1;
    this.newarr01 = [];
    this.setState(
      {
        checkListTagFilter: [],
        checkListStore: [],
        supplier_type: undefined,
        supplyTypeShow: '',
        isChanged01: [],
        // isVisibleFilterDropDown: false,
        changeFilterBySku: false,
        byPushStores: [
          {
            id: 1,
            name: intl.get('product.to_push_shopify'),
            checked: false
          },
          {
            id: 2,
            name: intl.get('product.no_push_shopify'),
            checked: false
          }
        ],
        checkListStoreShow: [],
        checkListTagFilterShow: [],
        changeFilterBySkuShow: false,
        byPushStoresShow: [
          {
            id: 1,
            name: intl.get('product.to_push_shopify'),
            checked: false
          },
          {
            id: 2,
            name: intl.get('product.no_push_shopify'),
            checked: false
          }
        ]
      },
      () => {
        this.getProdocutList();
      }
    );
  };

  confirmCheckFilter = () => {
    this.currentPage = 1;
    this.setState({
      checkListStoreShow: this.state.checkListStore,
      checkListTagFilterShow: this.state.checkListTagFilter,
      changeFilterBySkuShow: this.state.changeFilterBySku,
      byPushStoresShow: this.state.byPushStores,
      supplyTypeShow: this.state.supplier_type
    });
    this.getProdocutList('filter');
  };

  // tag table编辑

  newarr = [];

  onChangeChecked = record => {
    const { dataColumn, isChanged } = this.state;

    if (!this.newarr.includes(record.id)) {
      this.newarr.push(record.id);
      this.setState({
        isChanged: [...this.newarr]
      });
    } else {
      this.newarr = this.newarr.filter(item => item !== record.id);
      this.setState({
        isChanged: [...this.newarr]
      });
    }

    let mapList = dataColumn.map(item => {
      if (item.id === record.id) {
        // return {
        //   ...item,
        //   check: item.check ? false : true
        // };
        item.check = !item.check;
        return item;
      } else {
        return item;
      }
    });
    this.setState({
      dataColumn: mapList
    });
    let isSelectAll = dataColumn.filter(item => {
      return item.check == true;
    });

    if (!isSelectAll.length) {
      this.setState({
        selectAllAddTags: false
      });
    } else if (isSelectAll.length == this.state.dataColumn.length) {
      this.setState({
        selectAllAddTags: true
      });
    } else {
      this.setState({
        selectAllAddTags: false
      });
    }
    console.log(
      this.state.dataColumn.filter(item => {
        return item.check == true;
      })
    );
  };
  columns = [
    {
      title: 'tag',
      dataIndex: 'tag',
      editable: true,
      render: (_, record) => {
        return !this.state.tagChecked ? (
          <div style={{ display: 'flex' }}>
            {/* {
              <span style={{ marginRight: 32 }}>
                <Checkbox
                  checked={record.check}
                  disabled={
                    !this.state.tagChecked
                      ? this.state.checkedList.length == 0 ||
                        this.props.awaitPush
                      : false
                  }
                  onChange={() => {
                    this.onChangeChecked(record);
                  }}
                />
              </span>
            } */}
            <span className="overhidden">{_}</span>
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            {
              <span style={{ marginRight: 32 }}>
                <Checkbox
                  checked={record.check}
                  disabled={
                    !this.state.tagChecked
                      ? this.state.checkedList.length == 0 ||
                        this.props.awaitPush
                      : false
                  }
                  onChange={() => {
                    this.onChangeChecked(record);
                  }}
                />
              </span>
            }
            <span className="overhidden">{_}</span>
          </div>
        );
      }
    },
    {
      title: 'count',
      dataIndex: 'count',
      render: (text, record) => {
        return record.count && !this.state.tagChecked ? (
          <span className="two">{`${record.count} ${intl.get(
            'import_list.product_s'
          )}`}</span>
        ) : null;
      }
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      width: 200,
      render: (text, record) => {
        return !this.state.tagChecked ? (
          record.editable ? (
            <div className="save_box">
              <span className="save">
                <EditableContext.Consumer>
                  {form => (
                    <a
                      onClick={debounce(
                        () => this.saveTags(form, record.key, record),
                        800
                      )}
                    >
                      <i class="material-icons notranslate">save</i>
                    </a>
                  )}
                </EditableContext.Consumer>

                <a style={{ marginLeft: 8 }} onClick={() => this.getTagList()}>
                  <i class="material-icons notranslate">
                    <img src={cancelTags} alt="" />
                  </i>
                </a>
              </span>
            </div>
          ) : (
            <div>
              <a onClick={record.ifuse ? () => this.edit(record.key) : null}>
                <i
                  class="material-icons notranslate"
                  style={record.ifuse ? null : { color: '#00000040' }}
                >
                  edit
                </i>
              </a>
              <Popconfirm
                icon={null}
                overlayClassName="deleteTag"
                title={intl.getHTML('import_list.tag_manage_delete_tip')}
                okText={intl.get('public.ok')}
                cancelText={intl.get('public.cancel')}
                onConfirm={() => this.remove(record)}
              >
                <a style={{ marginLeft: 8 }}>
                  <i class="material-icons notranslate">delete</i>
                </a>
              </Popconfirm>
            </div>
          )
        ) : null;
      }
    }
  ];

  columns_add = [
    {
      title: 'tag',
      dataIndex: 'tag',
      editable: true,
      render: (_, record) => {
        return (
          <div style={{ display: 'flex' }}>
            {
              <span style={{ marginRight: 32 }}>
                <Checkbox
                  checked={record.check}
                  disabled={
                    !this.state.tagChecked
                      ? this.state.checkedList.length == 0 ||
                        this.props.awaitPush
                      : false
                  }
                  onChange={() => {
                    this.onChangeChecked(record);
                  }}
                />
              </span>
            }
            <span className="overhidden">{_}</span>
          </div>
        );
      }
    },
    {
      title: 'count',
      dataIndex: 'count',
      render: (text, record) => {
        return record.count && !this.state.tagChecked ? (
          <span className="two">{`${record.count} ${intl.get(
            'import_list.product_s'
          )}`}</span>
        ) : null;
      }
    }
  ];

  remove = row => {
    if (row.id) {
      this.props.dispatch({
        type: 'productmy/tagDeleteItem',
        payload: {
          data: {
            id: row?.id
          },
          callback: data => {
            if (data.code === 2010 || data.code === 2000) {
              this.getTagList();
            }
          }
        }
      });
    } else {
      const newData = this.state.dataColumn.filter(item => {
        return item.key !== row?.key;
      });

      this.setState({
        dataColumn: newData
      });
    }
  };

  saveTags = (form, key, record) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }

      this.saveTagName(Object.assign(record, row));
    });
  };

  getTagList = () => {
    this.props.dispatch({
      type: 'productmy/getProductTagList',
      payload: {
        callback: data => {
          if (data.code === 2010 || data.code === 2000) {
            if (data.data.length > 0) {
              this.setState({
                dataColumn: data.data
                  .filter(item => item.tag !== '')
                  .map((item, index) => {
                    item.editable = false;
                    item.key = index + 1;
                    item.check = false;
                    item.ifuse = true;
                    return item;
                  })
              });
            } else {
              this.setState({
                dataColumn: []
              });
            }
          }
        }
      }
    });
  };

  saveTagName = row => {
    const repeatTag = this.state.dataColumn.filter(i => i.tag === row.tag);
    if (repeatTag.length && row.id != repeatTag[0]?.id) {
      message.error(intl.getHTML('import_list.repeated_tag'));
      return;
    }
    if (row.id) {
      this.props.dispatch({
        type: 'productmy/tagEditItem',
        payload: {
          data: {
            id: row.id,
            tag: row.tag
          },
          callback: data => {
            if (data.code === 2010 || data.code === 2000) {
              this.getTagList();
            }
          }
        }
      });
    } else {
      this.props.dispatch({
        type: 'productmy/tagCreate',
        payload: {
          data: {
            tags: [row.tag]
          },
          callback: data => {
            if (data.code === 2010 || data.code === 2000) {
              this.refDom.value = '';
              this.setState({
                tagVal: ''
              });
              this.getTagList();
            }
          }
        }
      });
    }
  };

  edit(key) {
    this.state.dataColumn.forEach(item => {
      if (item.key !== key) {
        item.ifuse = false;
      } else {
        item.editable = !item.editable;
      }
    });

    this.forceUpdate();
  }

  handleClickMenu = () => {
    this.getTagList();
    this.setState({
      storesList: this.props.userInfo.stores || []
    });
  };

  handleAdd = () => {
    const { tagVal } = this.state;
    //直接保存数据
    if (!tagVal) {
      this.refDom.focus();
      return;
    }

    //暂时缺文案
    if (tagVal.length > 50) {
      message.error('error');
      return;
    }

    this.saveTagName({
      tag: tagVal
    });

    return;

    const { key, dataColumn } = this.state;
    if (dataColumn.length === 0) {
      const newData = {
        key,
        tag: '',
        count: '',
        editable: true
      };
      this.setState({
        dataColumn: [...dataColumn, newData],
        key: key + 1
      });
    } else {
      const data = dataColumn.every(item => {
        return !!item.tag;
      });

      if (data) {
        const newData = {
          key: Number(parseInt(dataColumn[dataColumn.length - 1].key) + 1),
          tag: '',
          count: '',
          editable: true
        };
        this.setState({
          dataColumn: [...dataColumn, newData],
          key: key + 1
        });
      }
    }
  };

  // 显示TagModal
  showTagModal = () => {
    // 交互打点
    CommonDataCollect({
      user_id: this.props.userInfo.user_id,
      sale_platform: 'shopify',
      supply_platform: 'aliexpress',
      trans_from: 'importlist click tag management button',
      action: 'click_tag_management_button',
      event_type: 'interactive'
    });

    window.dataLayer.push({
      event: 'importListCustomEvent',
      type: 'openTagManagement'
    });

    this.getTagList();
    this.setState({
      tagVisible: true
    });
  };
  // 显示addTagModal
  showAddTagModal = () => {
    // 交互打点
    CommonDataCollect({
      user_id: this.props.userInfo.user_id,
      sale_platform: 'shopify',
      supply_platform: 'aliexpress',
      trans_from: 'importlist click add tags button',
      action: 'click_add_tags_button',
      event_type: 'interactive'
    });

    window.dataLayer.push({
      event: 'importListCustomEvent',
      type: 'openTagManagement'
    });

    this.getTagList();
    this.setState({
      addTagVisible: true
    });
  };

  // 隐藏tag Modal
  hideVisibleTagModal = () => {
    this.setState({
      tagVisible: false
    });
  };

  // 隐藏addTag Modal
  hideVisibleAddTagModal = () => {
    this.setState({
      addTagVisible: false
    });
  };

  handleSelectAllAddTags = () => {
    this.setState(
      {
        selectAllAddTags: !this.state.selectAllAddTags
      },
      () => {
        const { dataColumn } = this.state;
        let mapList = dataColumn.map(item => {
          return {
            ...item,
            check: this.state.selectAllAddTags
          };
        });
        this.setState({
          dataColumn: mapList
        });
      }
    );
    if (!this.state.selectAllAddTags) {
      this.setState({
        isChanged: this.state.dataColumn
      });
    } else {
      this.setState({
        isChanged: []
      });
    }
  };

  confirmProductTag = (tag, id) => {
    console.log('tag', tag);

    const { dataColumn } = this.state;

    // 交互打点
    CommonDataCollect({
      user_id: this.props.userInfo.user_id,
      sale_platform: 'shopify',
      supply_platform: 'aliexpress',
      trans_from: 'importlist click add card tags button',
      action: 'click_add_card_tags_button',
      event_type: 'interactive'
    });

    //如果没有tag的时候
    if (!tag.length) {
      this.setState({
        tagChecked: true,
        tagVisible: true,
        dataColumn: dataColumn.map(item => {
          return {
            ...item,
            check: false
          };
        }),
        tags_id: id
      });
      return;
    }

    let arr = [];
    dataColumn.map((item, index) => {
      tag.map(item2 => {
        if (item.id == item2) {
          arr.push({
            ...item,
            check: true
          });
        }
      });
      arr.push(item);
    });

    this.setState({
      tagChecked: true,
      tagVisible: true,
      dataColumn: uniqBy(arr, 'id'),
      tags_id: id
    });
  };
  //单个商品添加tag
  saveConfirmProductTag = () => {
    //计算tag
    const { dataColumn } = this.state;

    let tag = [];

    dataColumn.map(item => {
      if (item.check) {
        tag.push(item.id);
      }
    });
    this.batchAddTags(tag, this.state.tags_id);
    this.getProdocutList();
  };
  //批量添加tags
  saveConfirmProductAddTag = () => {
    //计算tag
    const { dataColumn } = this.state;

    let tag = [];

    dataColumn.map(item => {
      if (item.check) {
        tag.push(item.id);
      }
    });
    this.batchAddSeveralTags(tag, this.state.checkedList);
    this.getProdocutList();
  };

  hidenPushShofiy = prod => {
    const storeId = [];
    prod.storesCheck.forEach(item => {
      storeId.push(item.store_id);
    });

    this.isClickPushedToStores = true;
    // this.showSetVisibleFn(prod);
    this.handlePushOne(prod);
    this.setState({
      storeCheck: storeId
    });
  };

  handleBannerClick = value => {
    this.props.dispatch({
      type: 'global/showOneClickMapping',
      payload: value,
      callback: d => {
        if (d.code == 2000) {
          this.getBannerList();
        }
      }
    });

    this.props.dispatch({
      type: 'global/dataTrack',
      payload: {
        data: {
          user_id: this.props.userInfo.user_id,
          store_id: value.store_id.toString(),
          platform: 'shopify',
          page: 'import list',
          page_url: window.location.href,
          module: 'import list',
          action: 'switch-button-click',
          product_id_list: [''],
          order_id_list: [''],
          custom_info: [
            {
              name: 'switch_version',
              value: getVersion(this.props.userInfo.user_id)
            },
            {
              name: 'ae_product_id',
              value: value.ae_product_id.toString()
            },
            {
              name: 'dsers_product_id',
              value: value.dsers_product_id.toString()
            },
            {
              name: 'dsers_product_id',
              value: value.dsers_product_id.toString()
            },
            {
              name: 'shopify_product_id',
              value: value.shopify_product_id.toString()
            },
            {
              name: 'mapping_type',
              value: value.mapping_type.toString()
            }
          ]
        }
      }
    });
  };

  //切换push状态
  onChangePushFilter = item => {
    const { byPushStores } = this.state;
    const list = byPushStores.map(key => {
      if (key.id == item.id) {
        return {
          ...key,
          checked: item.checked ? false : true
        };
      } else {
        return {
          ...key,
          checked: false
        };
      }
    });

    this.setState({
      checkListStore: [],
      byPushStores: list
    });
  };

  onChangeFilterBySku = e => {
    this.setState({
      changeFilterBySku: !this.state.changeFilterBySku
    });
  };

  closeCkeckModalHanlder = () => {
    if (this.Child.state.btnType == 'push_to_shopify') {
      this.setState(
        {
          isVisibleModal: false
        },
        () => {
          this.Child.submitTrue(true);
        }
      );
    } else if (this.Child.state.btnType == 'close_drawer') {
      this.setState({
        isVisibleModal: false,
        showDetail: false
      });
    }
  };

  saveAndNextHandler = () => {
    if (this.Child.state.btnType == 'push_to_shopify') {
      this.Child.submitTrue('save_push');
      setTimeout(() => {
        this.Child.submitTrue(true);
      }, 300);
    } else if (this.Child.state.btnType == 'close_drawer') {
      this.Child.submitTrue(false);
    }
    this.setState({
      isVisibleModal: false
    });
  };

  onlycloseCkeckModalHanlder = () => {
    this.setState({
      isVisibleModal: false
    });
  };
  changemodalHidden = () => {
    this.setState({
      isVisibleModal: true
    });
  };

  closeDrawerHandler = () => {
    this.Child.setState(
      {
        btnType: 'close_drawer'
      },
      () => {
        if (
          this.Child.state.isHidden &&
          this.Child.state.btnType == 'close_drawer'
        ) {
          this.setState({
            isVisibleModal: true
          });
          return;
        }
        this.setState({
          showDetail: false
        });
      }
    );
  };

  // 获取推商品列表中是否有迁移过来的商品
  getIsHasOberloProduct = (selectedPushProducts, list) => {
    let is_oberlo_list = [];
    for (let i = 0; i < list?.prods?.length; i++) {
      let obj = list?.prods[i];
      let isExist = false;
      for (let j = 0; j < selectedPushProducts.length; j++) {
        let aj = selectedPushProducts[j];
        if (aj.id == obj.id && obj.refer == 'Migrated from Oberlo') {
          isExist = true;
          break;
        }
      }
      if (isExist) {
        is_oberlo_list.push(obj);
      }
    }
    return is_oberlo_list;
  };

  listp = [];
  renderList = [];

  getShopSellPrice = async (products, stores, pricingRule) => {
    this.setState({
      priceLoading: true
    });
    let productStr = products && products[0]?.id;
    products?.forEach((item, index) => {
      if (index !== 0) {
        productStr += `&select_product_id[]=${item.id}`;
      }
    });
    let storeStr = stores && stores[0];
    stores?.forEach((item, index) => {
      if (index !== 0) {
        storeStr += `&store_id[]=${item}`;
      }
    });
    const res = await Get('/prod/select/push/price', {
      data: {
        'select_product_id[]': productStr,
        'store_id[]': storeStr,
        price_rule: pricingRule
      }
    });
    if (res.code === 2000) {
      this.setState({
        shopSellPrice: res.data
      });
    }
    this.setState({
      priceLoading: false
    });
  };

  renderPrice = (productId, storeId, shopSellPrice) => {
    let price;
    shopSellPrice?.forEach(item => {
      if (item.select_product_id === productId) {
        item.price?.forEach(item_2 => {
          if (item_2.store_id === storeId) {
            if (item_2.max_price === item_2.min_price) {
              price = `${currencySymbolMap[item_2.currency]}${
                item_2.max_price
              }`;
            } else {
              price = `${currencySymbolMap[item_2.currency]}${
                item_2.min_price
              } ~ ${currencySymbolMap[item_2.currency]}${item_2.max_price}`;
            }
          }
        });
      }
    });
    return price;
  };

  handleCloseSku = () => {
    this.setState(
      {
        changeFilterBySku: false,
        changeFilterBySkuShow: false
      },
      () => {
        this.getProdocutList();
      }
    );
  };
  handleClosePush = id => {
    this.setState(
      {
        byPushStores: [
          {
            id: 1,
            name: intl.get('product.to_push_shopify'),
            checked: false
          },
          {
            id: 2,
            name: intl.get('product.no_push_shopify'),
            checked: false
          }
        ],
        byPushStoresShow: [
          {
            id: 1,
            name: intl.get('product.to_push_shopify'),
            checked: false
          },
          {
            id: 2,
            name: intl.get('product.no_push_shopify'),
            checked: false
          }
        ]
      },
      () => {
        this.getProdocutList();
      }
    );
  };
  handleCloseStore = id => {
    const arr = this.state.checkListStore.filter(i => {
      return i != id;
    });
    const arr1 = this.state.checkListStore.filter(i => {
      return i != id;
    });
    this.setState(
      {
        checkListStore: arr,
        checkListStoreShow: arr1
      },
      () => {
        this.getProdocutList();
      }
    );
  };

  handleCloseTag = id => {
    const arr = this.state.checkListTagFilter.filter(i => {
      return i != id;
    });
    const arr1 = this.state.checkListTagFilter.filter(i => {
      return i != id;
    });
    this.setState(
      {
        checkListTagFilter: arr,
        checkListTagFilterShow: arr1
      },
      () => {
        this.getProdocutList();
      }
    );
  };

  handleUpdatePrice = () => {
    this.setState({
      updateModalVisible: true
    });
  };

  closeUpdateModal = () => {
    this.setState({
      updateModalVisible: false
    });
  };

  clearSearchHandler = e => {
    e.stopPropagation();
    this.searchKey = '';
    this.setState({
      productValue: ''
    });
    this.getProdocutList();
  };

  changeMarketPlace = e => {
    this.setState({
      supplier_type: e.target.value
    });
  };

  handleCloseSupplyType = () => {
    this.setState(
      {
        supplier_type: undefined,
        supplyTypeShow: ''
      },
      () => {
        this.getProdocutList();
      }
    );
  };

  render() {
    const { loading, updateProductLoading, lang } = this.props;

    const {
      showDetail,
      checkedList = [],
      showVisible,
      visibleProgress,
      checkedVisibleList,
      productValue,
      showSetVisible,
      showSplitProduct,
      isVisibleDropDown,
      isVisibleFilterDropDown,
      checkList = [],
      dataColumn,
      list,
      bannerList,
      storeCheck,
      selectedPushProducts,
      pushReqId,
      pushShopifyLoading,
      pushOnline,
      continueSelling,
      awaitPush,
      pushResult,
      pushState,
      removeVisible,
      guideModal,
      isVisibleModal,
      pricingRuleTip,
      shopSellPrice,
      priceLoading,
      checkListStore,
      changeFilterBySku,
      checkListTagFilter,
      isChanged01,
      byPushStores,
      checkListStoreShow,
      checkListTagFilterShow,
      changeFilterBySkuShow,
      byPushStoresShow,
      supplier_type,
      updateModalVisible,
      updateReqId,
      updateLoading,
      updateLoadingProducts,
      ifUseUpdate
    } = this.state;
    const userInfos = JSON.parse(localStorage.getItem('store'));

    const productSupplyType = {
      2: 'AliExpress',
      4: 'Tmall'
    };

    const oberlo_product = this.getIsHasOberloProduct(
      selectedPushProducts,
      list
    );
    const total = list.count;
    const stores = this.props.userInfo.stores || [];
    const userId = this.props.userInfo.user_id || '';
    const isBannerUser = getVersion(userId);
    const hasTmallProduct = selectedPushProducts.filter(
      i => i.supplier_type === 4
    ).length;
    console.log(list, 'list--change前');
    this.listp = getImportProductShowData(list);
    console.log(this.listp, 'listp-=-=-=');
    if (this.currentPage === 1) {
      this.renderList = bannerList.concat(getImportProductShowData(list));
    }

    this.initListMap(this.listp);

    let importListDecHereLink =
      'https://help.dsers.com/import-list-for-shopify/';
    let howToTagProductsHereLink =
      'https://help.dsers.com/tag-products-in-import-list-for-shopify/';

    if (this.currentLanguage === 'fr-FR') {
      importListDecHereLink = 'https://help.dsers.com/import-list-for-shopify/';
      howToTagProductsHereLink =
        'https://help.dsers.com/tag-products-in-import-list-for-shopify/';
    }

    if (this.currentLanguage === 'pt-PT') {
      importListDecHereLink = 'https://help.dsers.com/import-list-for-shopify/';
      howToTagProductsHereLink =
        'https://help.dsers.com/tag-products-in-import-list-for-shopify/';
    }
    const is_searched_product =
      this.searchKey ||
      this.state.changeFilterBySku ||
      this.state.checkListTagFilter.length ||
      this.state.checkListStore.length ||
      this.state.supplier_type ||
      byPushStores.filter(i => i.checked)?.length;
    //!is_searched_product ? intl.getHTML('import_list.no_product_tip') :
    const orderNoData = is_searched_product ? (
      <div className={styles.noDataSync}>
        <p>
          <img src={iconShop} />
        </p>
        <dl>
          <dt>
            <p>{intl.getHTML('import_list.search_no_product_tip')}</p>
          </dt>
        </dl>
      </div>
    ) : (
      <div className={styles.noData} style={{ marginBottom: '300px' }}>
        <div className={styles.description}>
          <span className={styles.title}>
            {intl.getHTML('import_list.no_product_tip')}
          </span>
          <span className={styles.text}>
            {intl.getHTML('import_list.no_product_tip_1')}
          </span>
          <span className={styles.text}>
            {intl.getHTML('import_list.no_product_tip_2')}
          </span>
        </div>
        <img src={import_guide} alt="" />
      </div>
    );
    let firstId = '';
    if (this.currentPage === 1) {
      firstId = this.listp[0]?.id || '';
    }

    console.log(this.listp, 'listp-=-=-=');
    const cardList = (
      <Checkbox.Group
        value={checkedList}
        style={{ width: '100%' }}
        onChange={e => {
          this.changeCheck(e, this.listp);
        }}
      >
        <List
          // rowKey="id"
          loading={loading}
          dataSource={this.listp}
          // dataSource={
          //   isBannerUser !== 'notBannerUser'
          //     ? this.currentPage === 1
          //       ? this.renderList
          //       : this.listp
          //     : this.listp
          // }
          locale={{ emptyText: orderNoData }}
          className={stylesss.importListGrid}
          renderItem={(item, index) => (
            <List.Item
              key={item.id}
              className={stylesss.product_item_of_import}
              data-category="product-item-of-import"
            >
              <Spin
                spinning={
                  (awaitPush &&
                    this.props.getIsLoadingProducts?.some(
                      i => i.unique_id === item.unique_id
                    )) ||
                  (updateLoading &&
                    updateLoadingProducts?.includes(item.unique_id))
                }
              >
                {item.isBanner ? (
                  <BannerCard
                    data={item}
                    userId={userId}
                    type={1}
                    handleBannerClick={this.handleBannerClick}
                  />
                ) : (
                  <ImportProduct
                    firstId={firstId}
                    key={item.id}
                    product={item}
                    awaitPush={awaitPush}
                    push={this.handlePushOne}
                    removeProduct={this.removeProduct}
                    showDetail={this.showDetail}
                    showSplitProduct={this.showSplitProduct}
                    renderCheckData={this.state.dataColumn}
                    confirmProductTag={this.confirmProductTag}
                    cancelChecked={this.state.cancelChecked}
                    hidenPushShofiy={this.hidenPushShofiy}
                    bannerData={list.spreads}
                    index={index}
                  />
                )}
                {/* <ImportProduct
                  firstId={firstId}
                  key={item.id}
                  product={item}
                  awaitPush={awaitPush}
                  push={this.handlePushOne}
                  removeProduct={this.removeProduct}
                  showDetail={this.showDetail}
                  showSplitProduct={this.showSplitProduct}
                  renderCheckData={this.state.dataColumn}
                  confirmProductTag={this.confirmProductTag}
                  cancelChecked={this.state.cancelChecked}
                  hidenPushShofiy={this.hidenPushShofiy}
                  bannerData={list.spreads}
                /> */}
              </Spin>
            </List.Item>
          )}
        />
      </Checkbox.Group>
    );

    const menu = (
      <Menu style={{ width: '300px' }}>
        <Menu.Item key="add-tags">
          <h3>{intl.get('import_list.add_tags')}</h3>
        </Menu.Item>
        {dataColumn.length > 0 ? (
          dataColumn.map(item => {
            return (
              <Menu.Item key={`${item.tag}_${item.id}`}>
                <Ellipsis lines={1}>
                  <Checkbox
                    checked={item.check}
                    key={item.id}
                    onChange={e => {
                      this.onChangeChecked(e, item);
                    }}
                  >
                    <Tooltip placement="topLeft" title={item.tag}>
                      {item.tag}
                    </Tooltip>
                  </Checkbox>
                </Ellipsis>
              </Menu.Item>
            );
          })
        ) : (
          <Menu.Item key="no-data">
            {intl.get('product.manage_product.no_data')}
          </Menu.Item>
        )}
        <Menu.Item key="tools">
          <p
            style={{
              display: 'flex',
              textDecoration: 'underline',
              justifyContent: 'space-around'
            }}
          >
            <a style={{ color: 'blue' }} onClick={this.clearCheck}>
              {intl.get('mapping.clear')}
            </a>
            <a style={{ color: 'blue' }} onClick={this.confirmCheck}>
              {intl.get('public.confirm')}
            </a>
          </p>
        </Menu.Item>
      </Menu>
    );

    const menuFilter = (
      <Collapse
        className={stylesss.filterCollapse}
        ghost
        defaultActiveKey={['1', '2', '3', '4']}
      >
        <Panel header={intl.get('import_list.filter_by_stores')} key="1">
          {this.realStoreData.length > 0 ? (
            this.realStoreData.map(item => {
              return (
                <Menu>
                  <Menu.Item key={item.id}>
                    <Checkbox
                      checked={checkListStore.includes(item.id)}
                      onChange={e => this.onChangeStore(e, item.id)}
                    >
                      {item.shop_name}
                    </Checkbox>
                  </Menu.Item>
                </Menu>
              );
            })
          ) : (
            <Menu>
              <Menu.Item key="title1">
                {intl.get('product.manage_product.no_data')}
              </Menu.Item>
            </Menu>
          )}
        </Panel>
        <Panel header={intl.get('import_list.filter_by_tags')} key="2">
          {dataColumn.length > 0 ? (
            dataColumn.map(item => {
              return (
                <Menu>
                  <Menu.Item key={`${item.tag}_${item.id}`}>
                    <Ellipsis lines={1}>
                      <Checkbox
                        // defaultChecked={false}
                        // value={item.id}
                        checked={this.state.isChanged01.includes(item.id)}
                        onChange={e => {
                          this.onChangeCheckedFilter(e, item);
                          // this.onChangeStore(e);
                          this.onChangeFilter(item.id);
                        }}
                      >
                        <Tooltip placement="topLeft" title={item.tag}>
                          {item.tag}
                        </Tooltip>
                      </Checkbox>
                    </Ellipsis>
                  </Menu.Item>
                </Menu>
              );
            })
          ) : (
            <Menu>
              <Menu.Item key="title2">
                {intl.get('product.manage_product.no_data')}
              </Menu.Item>
            </Menu>
          )}
        </Panel>
        <Panel header={intl.get('product.filter_push_title')} key="3">
          {this.state.byPushStores.map(item => {
            return (
              <Menu>
                <Menu.Item key={item.id}>
                  <Radio
                    checked={item.checked}
                    onChange={e => {
                      this.onChangePushFilter(item);
                      // this.onChangeStore(e);
                      // this.onChangeFilter(item.id)
                    }}
                  >
                    {item.name}
                  </Radio>
                </Menu.Item>
              </Menu>
            );
          })}
          {/* </Radio.Group> */}
        </Panel>
        <Panel header={'Filter by product'} key="4">
          <Menu>
            <Menu.Item>
              <Checkbox
                defaultChecked={false}
                // value={item.id}
                checked={changeFilterBySku}
                onChange={e => {
                  this.onChangeFilterBySku(e);
                }}
              >
                {'SKU over 100'}
              </Checkbox>
            </Menu.Item>
          </Menu>
        </Panel>
      </Collapse>
    );

    const components = {
      body: {
        cell: EditableCell
      }
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'count' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: record.editable
        })
      };
    });

    const columns_add = this.columns_add.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'count' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: record.editable
        })
      };
    });
    const href = 'https://pagefly.io/?ref=dserspro&target=app-listing';

    console.log(this.currentProdcut, 'currentProdcut');
    return (
      <div className="importList_Tab">
        {/* 快速推品--首页点击弹窗推送商品时跳转过来展示 */}
        <QuickPushModal
          params={this.props.location.param}
          pushSpecialProducts={this.handleBatchPushQuickPush}
        ></QuickPushModal>
        {/* 商品详情抽屉 */}
        <Drawer
          style={{ borderRadius: 12 }}
          placement="right"
          className="souti"
          title={intl.get('import_list.edit_detail')}
          closable={true}
          onClose={this.closeDrawerHandler}
          visible={showDetail}
          width={1008}
          destroyOnClose={true}
        >
          <CheckSaveModal
            type="notSave"
            saveAndNextHandler={this.saveAndNextHandler}
            closeCkeckModalHanlder={this.closeCkeckModalHanlder}
            onlycloseCkeckModalHanlder={this.onlycloseCkeckModalHanlder}
            visible={isVisibleModal}
          ></CheckSaveModal>
          <ImportMyProdcutEdit
            changemodalHidden={this.changemodalHidden}
            pid={this.currentProdcut.id}
            currentProduct={this.currentProdcut}
            producteDetail={this.currentProdcut}
            onRef={c => (this.Child = c)}
            onHide={this.hideDetail}
            onSubmit={this.updateMyProduct}
            updateProductLoading={updateProductLoading}
            removeProduct={this.removeProduct}
            awaitPush={awaitPush}
            copyLink={this.copyLink}
            pageKey={this.pageKey}
            saveEditLoading={this.state.saveEditLoading}
          />
        </Drawer>

        {/* {!this.state.has_import && !localStorage.getItem('un_scv_user_guide') && (
          <div
            className={stylesss.orderGuideBtn}
            onClick={() => {
              this.setState({
                guideModal: true
              });
            }}
          >
            <i class="material-icons">wb_incandescent</i>
          </div>
        )} */}

        {this.state.guideModal && (
          <GuideModal
            status={guideModal}
            guideStorage="import"
            closeModal={this.closeModal}
          />
        )}

        <div className={styles.productBox} id="vv" key="min">
          <div style={{ marginTop: '24px' }}></div>
          <ProductPoolBanner showBtn dispatch={this.props.dispatch} />
          <div className={styles.productInp} style={{ marginBottom: 16 }}>
            <div className={styles.title_filter}>
              <h2>{intl.get('import_list.title')}</h2>
              <div className={styles.functions} style={{ width: '80%' }}>
                <div
                  className={styles.push_btn}
                  onClick={this.handleVisibleChangeFilter}
                >
                  <Button>
                    <i class="material-icons notranslate">filter_alt</i>{' '}
                    {intl.get('import_list.filter')}
                  </Button>
                </div>
                <div className="xt-border"></div>
                {/* 搜索按钮 */}
                <SearchInput
                  value={productValue}
                  getPlatform={v => {
                    this.setState({
                      selectValue: v
                    });
                    if (!this.state.supplyTypeShow && productValue) {
                      this.setState({
                        search_supplier_type: v === 'Tmall' ? 4 : 2
                      });
                    }
                  }}
                  openInputBtnText={intl.get('import_list.search_or_import')}
                  placeholder={
                    this.state.selectValue === 'AliExpress'
                      ? intl.get('import_list.search_ali_placeholder')
                      : intl.get('import_list.search_tm_placeholder')
                  }
                  selectValue={this.state.selectValue}
                  hiddenSelect={!window.TMALL_USER}
                  onChange={this.changeSearchKey}
                  onSubmit={this.addProduct}
                  autoFocus={true}
                  allowClear={true}
                ></SearchInput>
              </div>
            </div>
            <p className={styles.mapList}>
              {intl.getHTML('import_list.dec_change')}
              <Tooltip
                title={
                  <span>{intl.getHTML('import_list.dec_change_tit')}</span>
                }
              >
                <Icon
                  type="question-circle"
                  theme="outlined"
                  style={{
                    marginLeft: 2,
                    transform: 'scale(.9)'
                  }}
                />
              </Tooltip>
            </p>

            {
              <div className={styles.filterOptionContainer}>
                {this.state.supplyTypeShow ? (
                  <span className={styles.filterOption}>
                    <span>{productSupplyType[this.state.supplyTypeShow]}</span>
                    <img
                      src={closeFilterIcon}
                      onClick={() => this.handleCloseSupplyType()}
                      alt=""
                    />
                  </span>
                ) : null}
                {this.realStoreData.map(item => {
                  if (checkListStoreShow.includes(item.id)) {
                    return (
                      <span className={styles.filterOption}>
                        <span onClick={this.handleVisibleChangeFilter}>
                          {item.shop_name}
                        </span>
                        <img
                          src={closeFilterIcon}
                          onClick={() => this.handleCloseStore(item.id)}
                          alt=""
                        />
                      </span>
                    );
                  }
                })}
                {dataColumn.map(item => {
                  if (checkListTagFilterShow.includes(item.id)) {
                    return (
                      <span className={styles.filterOption}>
                        <span onClick={this.handleVisibleChangeFilter}>
                          {item.tag}
                        </span>
                        <img
                          src={closeFilterIcon}
                          onClick={() => this.handleCloseTag(item.id)}
                          alt=""
                        />
                      </span>
                    );
                  }
                })}
                {byPushStoresShow.map(item => {
                  if (item.checked) {
                    return (
                      <span className={styles.filterOption}>
                        <span onClick={this.handleVisibleChangeFilter}>
                          {item.name}
                        </span>
                        <img
                          src={closeFilterIcon}
                          onClick={() => this.handleClosePush(item.id)}
                          alt=""
                        />
                      </span>
                    );
                  }
                })}
                {changeFilterBySkuShow ? (
                  <span className={styles.filterOption}>
                    <span onClick={this.handleVisibleChangeFilter}>
                      SKU over 100
                    </span>
                    <img
                      src={closeFilterIcon}
                      onClick={this.handleCloseSku}
                      alt=""
                    />
                  </span>
                ) : null}
              </div>
            }
          </div>
          <PushNotice
            reqId={pushReqId}
            onCheckDetail={this.handleCheckPushDetail}
            onStart={this.handleStartPushProcess}
            onFinish={this.handleFinishPushProcess}
            onPending={this.handlePendingPushProcess}
            onError={this.handleErrorPushProcess}
            pushState={pushState}
            selectedPushProducts={selectedPushProducts}
            hasTmallProduct={hasTmallProduct}
          />
          <UpdatePriceNotice
            reqId={updateReqId}
            onFinish={this.handleUpdatePriceFinsh}
            onPending={this.handleUpdatePricePendingProcess}
            onError={this.handleUpdatePriceError}
          />
          <div className={stylesss.top2}>
            <Row key="top2">
              <Col key="content">
                <div className={stylesss.wrap}>
                  <div className={stylesss.l}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Checkbox
                        onChange={this.changeCheckAll}
                        indeterminate={
                          checkedList.length == 0
                            ? false
                            : checkedList.length != this.listp.length
                        }
                        checked={
                          checkedList.length == this.listp.length &&
                          checkedList.length != 0
                        }
                      />
                      <div className={stylesss.push_btn}>
                        <Button
                          type="primary"
                          onClick={this.handleBatchPush}
                          disabled={checkedList.length == 0 || updateLoading}
                          className={styless.btnClassLine}
                          loading={pushShopifyLoading || awaitPush}
                        >
                          <div className={stylesss.iconB}>
                            {checkedList.length > 0 && !updateLoading ? (
                              <img
                                src={require('../../assets/new_login_ui/Shopify-icon/Shopify-icon#fff.png')}
                              ></img>
                            ) : (
                              <img
                                src={require('../../assets/new_login_ui/Shopify-icon/Shopify-icon.svg')}
                              ></img>
                            )}
                          </div>
                          {intl.get('product.push_btn')}
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className={stylesss.r}>
                    {this.state.ifUseUpdate ? (
                      <div className={stylesss.push_btn}>
                        <Tooltip
                          visible={this.state.tooltipVisible}
                          title={intl.get('product.updatePrice')}
                          placement="topRight"
                        >
                          <Button
                            type="primary"
                            loading={updateLoading}
                            disabled={
                              checkedList.length == 0 ||
                              pushShopifyLoading ||
                              awaitPush
                            }
                            onClick={this.handleUpdatePrice}
                          >
                            {intl.get('product.update')}{' '}
                            <div
                              style={{ marginLeft: '3px' }}
                              onMouseEnter={() => {
                                this.setState({ tooltipVisible: true });
                              }}
                              onMouseLeave={() => {
                                this.setState({ tooltipVisible: false });
                              }}
                            >
                              <Icon
                                type="question-circle"
                                style={{
                                  verticalAlign: 'middle',
                                  color:
                                    checkedList.length == 0
                                      ? 'rgba(0,0,0,.24)'
                                      : 'white'
                                }}
                              />
                            </div>
                          </Button>
                        </Tooltip>
                      </div>
                    ) : null}
                    <div className="xt-border"></div>
                    <div className={stylesss.push_btn}>
                      <Button
                        // style={{ marginLeft: 20 }}
                        onClick={this.showTagModal}
                      >
                        <i class="material-icons notranslate">loyalty</i>{' '}
                        {intl.get('import_list.tag_management')}
                      </Button>
                    </div>
                    <div className="xt-border"></div>
                    <div className={stylesss.push_btn}>
                      <Button
                        // style={{ marginLeft: 20 }}
                        onClick={this.showAddTagModal}
                      >
                        <img src={addTags} alt="" />
                        {intl.get('import_list.add_tags')}
                      </Button>
                    </div>
                    <div className="xt-border"></div>
                    <div className={stylesss.push_btn}>
                      <Button
                        onClick={() => {
                          window.dataLayer.push({
                            event: 'importListCustomEvent',
                            type: 'batchDeleteButton'
                          });
                          this.removeProduct(checkedList, 1);
                        }}
                        disabled={checkedList.length == 0 || awaitPush}
                        className={styless.btnClassLine}
                        style={{ marginRight: 20 }}
                      >
                        <i class="material-icons notranslate">delete</i>{' '}
                        {intl.get('product.delete_btn')}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className={styless.products}>{cardList}</div>
          <div className={styles.pageFly}>
            <div className={styles.textBox}>
              <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className="open_pagefly"
              >
                <img src={logo} className={styles.logo} />
                <span className={styles.text}>
                  {intl.get('import_list.pagefly')}
                </span>
              </a>
            </div>
          </div>
          <div className={styles.coverCardList}>
            <CardPagination
              total={total || 0}
              size={this.state.pageSize}
              onChange={this.changePager}
              current={this.currentPage}
              visible={total}
            />
          </div>
        </div>

        {/* 商品拆分 */}
        <SplitProduct
          prod={this.splitProduct}
          visible={showSplitProduct}
          hideSplitProduct={this.hideSplitProduct}
          isImportListPage
        />
        {/* 删除Modal */}
        <Modal
          title={intl.get('import_list.delete_product')}
          visible={removeVisible}
          onCancel={() => {
            this.setState({
              removeVisible: false
            });
          }}
          maskClosable={true}
          width={560}
          footer={
            <Button
              type="primary"
              onClick={() => {
                console.log(this.state.ids);
                this.RemoveProduct(this.state.removeList);
              }}
            >
              {intl.get('public.confirm')}
            </Button>
          }
        >
          {intl.get('import_list.delete_product_tip')}
        </Modal>

        {showVisible ? (
          <Modal
            title={`${
              visibleProgress
                ? intl.get('product.visible_progress_title1')
                : intl.get('product.visible_progress_title2')
            }`}
            visible={showVisible}
            width={1100}
            maskClosable={false}
            footer={null}
            onCancel={this.hideVisibleModal}
          >
            <Table
              columns={this.getVisibleColumns()}
              dataSource={checkedVisibleList}
              pagination={false}
              rowKey="id"
            />
            {this.visibleAll && this.visibleFailed ? (
              <Progress
                percent={visibleProgress}
                status="exception"
                style={{ marginTop: 20 }}
              />
            ) : (
              <Progress percent={visibleProgress} style={{ marginTop: 20 }} />
            )}
          </Modal>
        ) : null}

        <ErrorCashbackCode />
        <AEDataAuthorization />
        <DSersSpBanner dispatch={this.props.dispatch} />

        {/* tag弹层 */}
        <Modal
          title={
            !this.state.tagChecked
              ? intl.get('import_list.add_manage_tags')
              : intl.get('import_list.add_manage_tags2')
          }
          visible={this.state.tagVisible}
          width={560}
          footer={
            this.state.tagChecked ? (
              <div style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  data="dx"
                  onClick={this.saveConfirmProductTag}
                >
                  {intl.get('import_list.confim')}
                </Button>
              </div>
            ) : null
          }
          onCancel={this.hideVisibleTagModal}
          className={styless.bodyStyle}
          wrapClassName="tag_table"
          destroyOnClose={true}
          afterClose={() => {
            this.setState({
              tagChecked: false,
              tags_id: [],
              isChanged: [],
              dataColumn: this.state.dataColumn.map(item => {
                return {
                  ...item,
                  check: false
                };
              })
            });
          }}
        >
          <p className={styless.titleColorBB}>
            {intl.getHTML(
              !this.state.tagChecked
                ? 'import_list.add_manage_tags_tip'
                : 'import_list.add_management_tags',
              {
                href: howToTagProductsHereLink
              }
            )}
          </p>
          <p>
            {!this.state.tagChecked ? (
              <div className="inputBoxY">
                <input
                  ref={node => {
                    this.refDom = node;
                  }}
                  maxLength={50}
                  placeholder={intl.get('import_list.addTag')}
                  onChange={e => {
                    this.setState({
                      tagVal: e.target.value.trim()
                    });
                  }}
                />
                <div className="add">
                  <Button
                    type="primary"
                    onClick={this.handleAdd}
                    style={{ fontSize: 22 }}
                  >
                    +
                  </Button>
                </div>
              </div>
            ) : null}
          </p>
          <EditableContext.Provider value={this.props.form}>
            {this.state.dataColumn.length ? (
              <div className={styless.table}>
                <Table
                  components={components}
                  dataSource={this.state.dataColumn}
                  columns={columns}
                  rowClassName="editable-row"
                  pagination={false}
                  showHeader={false}
                  bordered={false}
                  className={stylesss.tablewrap}
                />
              </div>
            ) : null}
          </EditableContext.Provider>
        </Modal>
        {/* add_tags弹层 */}
        <Modal
          title={intl.get('import_list.add_tags')}
          visible={this.state.addTagVisible}
          width={560}
          footer={
            <div style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                disabled={
                  this.state.dataColumn.filter(item => {
                    return item.check == true;
                  }).length
                    ? false
                    : true
                }
                onClick={this.saveConfirmProductAddTag}
                data="dx"
              >
                {intl.get('import_list.confim')}
              </Button>
            </div>
          }
          onCancel={this.hideVisibleAddTagModal}
          className={styless.bodyStyle}
          wrapClassName="tag_table"
          destroyOnClose={true}
          afterClose={() => {
            this.setState({
              tagChecked: false,
              tags_id: [],
              isChanged: [],
              dataColumn: this.state.dataColumn.map(item => {
                return {
                  ...item,
                  check: false
                };
              })
            });
          }}
        >
          <p className={styless.titleColorBB}>
            {intl.get('import_list.add_management_tags')}
          </p>
          <p className={styless.titleAll}>
            <span style={{ marginRight: 32 }}>
              <Checkbox
                checked={this.state.selectAllAddTags}
                disabled={
                  !this.state.addTagChecked
                    ? this.state.checkedList.length == 0 || this.props.awaitPush
                    : false
                }
                onChange={this.handleSelectAllAddTags}
              />
            </span>
            <span className="overhidden">Select All</span>
          </p>
          <EditableContext.Provider value={this.props.form}>
            {this.state.dataColumn.length ? (
              <div className={styless.table}>
                <Table
                  components={components}
                  dataSource={this.state.dataColumn}
                  columns={columns_add}
                  rowClassName="editable-row"
                  pagination={false}
                  showHeader={false}
                  bordered={false}
                  className={styless.tablewrap}
                />
              </div>
            ) : null}
          </EditableContext.Provider>
        </Modal>

        {/* filter 弹层 */}
        <div className={stylesss.filter_modal}>
          <FilterDrawer
            title={intl.getHTML('import_list.filter_product')}
            visible={this.state.isVisibleFilterDropDown}
            destroyOnClose={true}
            defaultActiveKey={['1', '2', '3', '4', '5']}
            options={[
              {
                key: '1',
                title: intl.get('import_list.filter_by_market_place'),
                hidden: window.TMALL_USER ? false : true,
                filterComponent: (
                  <>
                    <Radio.Group
                      onChange={this.changeMarketPlace}
                      value={this.state.supplier_type}
                    >
                      <div className={stylesss.filterCheckbox}>
                        <Radio className={styles.filterCheckbox} value={2}>
                          AliExpress
                        </Radio>
                      </div>
                      <div className={stylesss.filterCheckbox}>
                        <Radio className={styles.filterCheckbox} value={4}>
                          Tmall
                        </Radio>
                      </div>
                    </Radio.Group>
                  </>
                )
              },
              {
                key: '2',
                title: intl.get('import_list.filter_by_stores'),
                filterComponent: (
                  <>
                    {this.realStoreData.length > 0 ? (
                      this.realStoreData.map(item => {
                        return (
                          <div className={stylesss.filterCheckbox}>
                            <Checkbox
                              checked={checkListStore.includes(item.id)}
                              onChange={e => this.onChangeStore(e, item.id)}
                            >
                              {item.shop_name}
                            </Checkbox>
                          </div>
                        );
                      })
                    ) : (
                      <Menu>
                        <Menu.Item key="title1">
                          {intl.get('product.manage_product.no_data')}
                        </Menu.Item>
                      </Menu>
                    )}
                  </>
                )
              },
              {
                key: '3',
                title: intl.get('import_list.filter_by_tags'),
                filterComponent: (
                  <>
                    {dataColumn.length > 0 ? (
                      dataColumn.map(item => {
                        return (
                          <div className={stylesss.filterCheckbox}>
                            <Ellipsis lines={1}>
                              <Checkbox
                                defaultChecked={false}
                                checked={this.state.checkListTagFilter.includes(
                                  item.id
                                )}
                                onChange={e => {
                                  this.onChangeCheckedFilter(e, item);
                                }}
                              >
                                <Tooltip placement="topLeft" title={item.tag}>
                                  {item.tag}
                                </Tooltip>
                              </Checkbox>
                            </Ellipsis>
                          </div>
                        );
                      })
                    ) : (
                      <Menu>
                        <Menu.Item key="title2">
                          {intl.get('product.manage_product.no_data')}
                        </Menu.Item>
                      </Menu>
                    )}
                  </>
                )
              },
              {
                key: '4',
                title: intl.get('product.filter_push_title'),
                filterComponent: (
                  <>
                    {this.state.byPushStores.map(item => {
                      return (
                        <div className={stylesss.filterCheckbox}>
                          <Radio
                            checked={item.checked}
                            onChange={e => {
                              this.onChangePushFilter(item);
                            }}
                          >
                            {item.name}
                          </Radio>
                        </div>
                      );
                    })}
                  </>
                )
              },
              {
                key: '5',
                title: 'Filter by product',
                filterComponent: (
                  <>
                    <div className={stylesss.filterCheckbox}>
                      <Checkbox
                        defaultChecked={false}
                        // value={item.id}
                        checked={changeFilterBySku}
                        onChange={e => {
                          this.onChangeFilterBySku(e);
                        }}
                      >
                        {'SKU over 100'}
                      </Checkbox>
                    </div>
                  </>
                )
              }
            ]}
            onClose={() => {
              this.newarr01 = [];
              if (this.isConfirm != 1) {
                this.setState(
                  {
                    checkListTagFilter: this.checkListTagFilterCopy,
                    checkListStore: this.checkListStoreCopy,
                    isChanged01: this.isChanged01Copy,
                    changeFilterBySku: this.changeFilterBySkuCopy,
                    byPushStores: this.byPushStoresCopy,
                    supplier_type: this.supplyTypeCopy
                  },
                  () => {
                    this.checkListTagFilterCopy = [];
                  }
                );
              }
              this.setState({
                isVisibleFilterDropDown: false
              });
            }}
            wrapClassName="filterModal"
            onCancel={this.clearCheckFilter}
            onConfirm={this.confirmCheckFilter}
          ></FilterDrawer>
        </div>

        {/* push 弹层 */}
        {showSetVisible ? (
          <Modal
            title={intl.get('import_list.push_modal_title')}
            visible={showSetVisible}
            width={868}
            maskClosable={false}
            footer={null}
            onCancel={this.hideSetVisible}
            className={styless.bodyStyles}
            bodyStyle={{ paddingBottom: 0, paddingRight: 16 }}
            wrapClassName="pushModal"
            onClick={this.closePricingTipHnadler}
          >
            <Spin
              spinning={pushShopifyLoading}
              style={{ width: '100%', height: '100%', zIndex: 2000 }}
            >
              <div className="importListCheckbox">
                <p className={styless.titleColor}>
                  {intl.getHTML('import_list.push_modal_desc', {
                    href: howToTagProductsHereLink
                  })}
                </p>
                <span>{intl.get('import_list.pushTitle_1')}</span>
                <div className={styless.wrapBox}>
                  <div className="dp_list">
                    <Checkbox.Group
                      onChange={this.changStoreCheck}
                      value={this.state.storeCheck}
                    >
                      {stores.length > 0 ? (
                        stores.map(item => {
                          return (
                            <div
                              key={item.id}
                              className="importListCheckboxItem"
                            >
                              <p>
                                <Checkbox value={item.id}>
                                  {getShopifyStoreDomain(item)}
                                </Checkbox>
                              </p>
                            </div>
                          );
                        })
                      ) : (
                        <p>{intl.get('import_list.no_store')}</p>
                      )}
                    </Checkbox.Group>
                  </div>
                </div>
                <span>{intl.get('import_list.pushTitle_2')}</span>
                <div className="puhs_modal_footer">
                  <p className="pusu_span">
                    <Checkbox
                      checked={this.state.pricingLine}
                      onChange={this.changePricing}
                    >
                      {userInfos?.limit_user_id >= userInfos?.user_id ||
                      localStorage.getItem('newuser_saw_pricing_mean') ==
                        'true' ? (
                        <div className="yuw">
                          {this.state.pricingLine ? (
                            <>
                              {intl.getHTML('import_list.pricing_rule_tip')}
                              <Tooltip
                                title={intl.get(
                                  'import_list.select_pricing_rule_mean_tip'
                                )}
                                placement="top"
                                getPopupContainer={triggerNode =>
                                  triggerNode.parentNode
                                }
                                defaultVisible={
                                  localStorage.getItem('saw_pricing_mean') ==
                                  'true'
                                    ? false
                                    : true
                                }
                                trigger="[click,hover]"
                              >
                                <span className={styless.pricingTip}>
                                  {intl.getHTML(
                                    'import_list.pricing_rule_tip_1'
                                  )}{' '}
                                  <Icon type="question-circle" />
                                </span>
                              </Tooltip>
                              {intl.getHTML('import_list.pricing_rule_tip_2')}
                            </>
                          ) : (
                            <>
                              {!oberlo_product.length ? (
                                <>
                                  {intl.get(
                                    'import_list.pricing_rule_close_tip'
                                  )}
                                  <Tooltip
                                    title={intl.get(
                                      'import_list.unselect_pricing_rule_mean_tip'
                                    )}
                                    placement="top"
                                    getPopupContainer={triggerNode =>
                                      triggerNode.parentNode
                                    }
                                    defaultVisible={
                                      localStorage.getItem(
                                        'saw_pricing_mean'
                                      ) == 'true'
                                        ? false
                                        : true
                                    }
                                    trigger="[click,hover]"
                                  >
                                    <span className={styless.pricingTip}>
                                      {intl.get(
                                        'import_list.pricing_rule_close_tip_1'
                                      )}{' '}
                                      <Icon type="question-circle" />
                                    </span>
                                  </Tooltip>{' '}
                                  {intl.get(
                                    'import_list.pricing_rule_close_tip_2'
                                  )}
                                </>
                              ) : (
                                <>
                                  {intl.getHTML(
                                    'import_list.from_oberlo_product_tip'
                                  )}
                                  <Tooltip
                                    title={intl.get(
                                      'import_list.unselect_pricing_rule_mean_tip'
                                    )}
                                    placement="top"
                                    getPopupContainer={triggerNode =>
                                      triggerNode.parentNode
                                    }
                                    defaultVisible={
                                      localStorage.getItem(
                                        'saw_pricing_mean'
                                      ) == 'true'
                                        ? false
                                        : true
                                    }
                                    trigger="[click,hover]"
                                  >
                                    <span className={styless.pricingTip}>
                                      {intl.get(
                                        'import_list.from_oberlo_product_tip_1'
                                      )}{' '}
                                      <Icon type="question-circle" />
                                    </span>
                                  </Tooltip>
                                  {intl.getHTML(
                                    'import_list.from_oberlo_product_tip_2'
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="yuw">
                          {this.state.pricingLine ? (
                            <>
                              {intl.get('import_list.pricing_rule_tip_1')}
                              {intl.get('import_list.pricing_rule_tip_2')}
                            </>
                          ) : (
                            <>
                              {!oberlo_product.length ? (
                                <>
                                  {intl.get(
                                    'import_list.pricing_rule_close_tip'
                                  )}
                                  {intl.get(
                                    'import_list.pricing_rule_close_tip_1'
                                  )}
                                  {intl.get(
                                    'import_list.pricing_rule_close_tip_2'
                                  )}
                                </>
                              ) : (
                                <>
                                  {intl.getHTML(
                                    'import_list.from_oberlo_product_tip'
                                  )}
                                  {intl.getHTML(
                                    'import_list.from_oberlo_product_tip_1'
                                  )}
                                  {intl.getHTML(
                                    'import_list.from_oberlo_product_tip_2'
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </Checkbox>
                  </p>
                  <p className="pusu_span">
                    <Checkbox
                      onChange={this.changeSelling}
                      checked={continueSelling}
                    >
                      {intl.get('product.show_set_visible.continue_selling')}
                    </Checkbox>
                  </p>
                  <p className="pusu_span">
                    <Checkbox
                      onChange={this.changeAlsoOnline}
                      checked={pushOnline}
                    >
                      {intl.get('product.show_set_visible.tip')}
                    </Checkbox>
                  </p>
                </div>
                <span style={{ marginTop: '24px' }}>
                  {intl.get('import_list.pushTitle_3')}
                </span>
                <div className="dp-content">
                  {selectedPushProducts.map(item => {
                    return (
                      <div className={styless.pushwrp} key={item.id}>
                        <div className={styless.imgLeft}>
                          <div style={{ height: '113px' }}>
                            <img
                              src={replaceUrlToHttps(item.image)}
                              style={{ height: '90px' }}
                            />
                          </div>
                        </div>
                        <div className={styless.dataArea}>
                          {storeCheck.map(i => {
                            const target = stores.find(s => s.id === i);

                            return target && getShopifyStoreDomain(target) ? (
                              <p key={i}>
                                <span
                                  title={
                                    target && getShopifyStoreDomain(target)
                                  }
                                >
                                  {target && getShopifyStoreDomain(target)}{' '}
                                </span>{' '}
                                <span
                                  className={styless.shopPrice}
                                  title={this.renderPrice(
                                    item.id,
                                    i,
                                    shopSellPrice
                                  )}
                                >
                                  {priceLoading ? (
                                    <Spin></Spin>
                                  ) : (
                                    this.renderPrice(item.id, i, shopSellPrice)
                                  )}
                                </span>
                              </p>
                            ) : null;
                          })}
                        </div>
                        <div className={styless.iconTab}>
                          {storeCheck.map(s => {
                            const error =
                              pushResult === 1
                                ? pushResult
                                : pushResult &&
                                  pushResult.find(
                                    ii =>
                                      ii.unique_id === item.unique_id &&
                                      ii.store === s
                                  );
                            return (
                              <p key={s} className={styless.pushFeedback}>
                                {this.pushProcessMarkup(error)}
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className={styless.footerClass}
                style={{
                  paddingBottom: 2,
                  paddingBottom: hasTmallProduct ? '24px' : null
                }}
              >
                {/* <Button onClick={this.hideSetVisible}>
                      {intl.get('public.cancel')}
                    </Button> */}
                {stores.length > 0 ? (
                  <Button
                    type="primary"
                    onClick={() => {
                      this.handlePushSubmit(selectedPushProducts);
                    }}
                    id="select_push_shopify"
                    data="dx"
                    disabled={
                      storeCheck.length === 0 ||
                      selectedPushProducts.length === 0
                    }
                    loading={pushShopifyLoading || awaitPush}
                    data-label="push-to-sp-submit"
                    className={styless.but}
                  >
                    {intl.get('product.product.push_to_shopify')}
                  </Button>
                ) : (
                  <Button type="primary">
                    <a href="/app/bind/link-store" target="_blank">
                      {intl.get('import_list.link_to_shopify')}
                    </a>
                  </Button>
                )}
              </div>
              {hasTmallProduct ? null : (
                <div className={styless.wrapboxy}>
                  <a href="https://bit.ly/39ORqfs" target="_blank">
                    <img src={appIcon2} />
                    Instantly get reviews for this product with{' '}
                    <span>Loox</span> - try for free!
                  </a>
                </div>
              )}
            </Spin>
          </Modal>
        ) : null}

        {/* UpdatePrice 弹层 */}
        {updateModalVisible ? (
          <UpdatePrice
            type="importList"
            visible={updateModalVisible}
            handleClose={this.closeUpdateModal}
            selectProduct={checkedList}
            onStart={this.handleUpdatePriceStart}
            listData={this.listp}
          ></UpdatePrice>
        ) : null}
      </div>
    );
  }
}
class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return '';
    }
    return <Input maxLength={50} />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              // rules: [
              //   {
              //     required: true,
              //     message: `Please Input ${title}!`
              //   }
              // ],
              initialValue: record[dataIndex]
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return (
      <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
    );
  }
}

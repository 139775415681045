import React from 'react';
import { connect } from 'dva';
import {
  Form,
  Input,
  Button,
  Table,
  InputNumber,
  Icon,
  Modal,
  Select,
  message,
  Tabs,
  Row,
  Col,
  Dropdown,
  Menu,
  Alert,
  Spin,
  Drawer,
  Tooltip,
  Checkbox,
  Radio,
  Cascader
} from 'antd';

import Editor from 'components/Editor';

import numeral from 'numeral';
import intl from 'react-intl-universal';
import className from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';

import SelectImageManagement from './Common/SelectImage.js';
import ImagesList from './Common/ImageList.js';
import Organization from './component/Organization';
import ShippingDetail from './component/ShippingDetail';
import { ProductOptionsSelect } from 'features';

import { getCity, getAeProductFreight } from 'services/dropshippersetting.js';
import { Put } from 'utils/request';

import _ from 'lodash';

import {
  getShopifyStoreStatus,
  replaceUrlToHttps,
  getSupplierUrl,
  CommonDataCollect
} from '../../utils/utils';
import { Post } from '../../utils/request';
import { getAuthorityHeader } from '../../utils/authority';

import styles from './MyProductEdit.less';
import './MyProductEdit.css';

import defaultImg from '../../assets/default.svg';
import { MenuItem } from '@material-ui/core';
import importList from 'locale/en/importList.js';

const { TabPane } = Tabs;
const { Option, OptGroup } = Select;
const { confirm } = Modal;
const opitonColor = ['#52c41a', '#722ed1', '#f90'];

/* eslint react/no-array-index-key: 0 */

let clickValKeyArr = ['price', 'compare_at_price', 'inventory_quantity'];
@connect(({ productmy, loading }) => ({
  productmy,
  loading: loading.models.productmy,
  getProdLoading: loading.effects['productmy/shopifyProductDetail'],
  organizationLoading: loading.effects['productmy/storesSettings']
}))
@Form.create()
export default class ImportMyProdcutEdit extends React.PureComponent {
  checkedArr = [];

  constructor(props) {
    super(props);
    this.state = {
      producteDetail: {},
      showImages: [],
      bShowModal: false,
      selectedRows: [],
      showEditSku: false,
      showDeleteSku: false,
      warningTips: false,
      clickValKey: '',
      isimage: false,
      isShopInfo: true,
      isProduct: false,
      optionVal: '',
      tagKey: props.pageKey || 'product',
      isHidden: false,
      btnType: '',
      productTitleInput: '',
      collectionsNewArr: [],
      cascaderCountryOptions: [],
      aeProductDetails: {},
      shipToCity: [],
      defaultShipToCity: [],
      selectedShipsFrom: undefined,
      selectedShipToCountry: undefined,
      selectedShippingMethod: undefined,
      selectedShipToCity: [],
      shipsFrom: [],
      shippingList: [],
      filterCascaderVisible: false,
      freightLoading: false,
      selectOptionsProps: [],
      optionsSelectRadioValue: ''
    };
    this.option = [];
    if (props.onRef) {
      props.onRef(this);
    }
  }

  componentWillMount() {
    const handle_producteDetail = this.props.producteDetail.prod;
    handle_producteDetail.detail.variants?.forEach(it => {
      it.only_flag = it.sku;
    });
    this.setState({
      producteDetail: handle_producteDetail
    });
    this.getShippingToCountryList();
  }

  componentDidMount() {
    const { producteDetail } = this.props;

    this.aePid = producteDetail.ae_pid;
    this.getShopifyProductDetail(producteDetail);
    this.getOptions();
  }

  componentWillUnmount() {
    clearTimeout(this.inputErrorTimer);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.productTitleInput !== nextState.productTitleInput) {
      this.setState({
        isHidden: true
      });
    }
    return true;
  }

  // 获取 shopify 商品详情
  getShopifyProductDetail = producteDetail => {
    this.getProdLoading = true;
    this.props.dispatch({
      type: 'productmy/getDetailsPageList',
      payload: {
        data: {
          unique_id: producteDetail.unique_id
        },
        callback: d => {
          this.getProdLoading = false;
          if (d.code === 2000) {
            const handle_producteDetail = d.data.prod;
            handle_producteDetail.detail.variants.forEach(it => {
              it.only_flag = it.sku;
            });
            this.initData(d.data.prod);
            this.aePid = this.props.producteDetail.ae_pid;
            this.organizationSetting = d.data.setting || {};
            this.getAEDetail(d.data.prod, producteDetail.ae_pid);
            this.getStoreSettings();
            this.getDefaultShipTo(d.data);
            // this.getCollections();
          }
        }
      }
    });
  };

  // 获取 collections 数据
  getCollections = () => {
    this.props.dispatch({
      type: 'productmy/collections',
      payload: {
        callback: d => {
          if (d.code == 2000) {
            this.collectionsData = d.data || [];
          }
        }
      }
    });
  };

  getAEDetail = (producteDetail, id) => {
    if (id) {
      this.props.dispatch({
        type:
          this.props.currentProduct.supplier_type === 4
            ? 'productmy/tmProductDetail'
            : 'productmy/aeProductDetail',
        payload: {
          data: {
            url: getSupplierUrl(
              this.props.currentProduct.supplier_type,
              this.aePid
            ),
            product_id: this.aePid,
            country: this.props.currentProduct.prod.ship_to,
            supplier_type: this.props.currentProduct.supplier_type
          },
          callback: d => {
            if (d.code == 2000) {
              this.setState({
                warningTips: false,
                aeProductDetails: d.data
              });
              const {
                aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] }
              } = d.data;
              this.aeProductDetail = aeop_ae_product_sku;
              this.aeProduct = d.data;
              this.setAEStock(producteDetail);
            } else if (d.code === 4290) {
              this.setState({
                warningTips: true
              });
            }
          }
        }
      });
    }
  };

  // 获取针对每个店铺可选的值collections、type、tags、vendor
  getStoreSettings = () => {
    this.props.dispatch({
      type: 'productmy/storesSettings',
      payload: {
        callback: d => {
          if ([2000, 2010].includes(d.code)) {
            this.setStoresSetting(d.data);
            this.organizationData = d.data || {};
          }
        }
      }
    });
  };

  setStoresSetting = data => {
    const organizationValue = this.state.producteDetail.setting || {};
    const { stores = [] } = JSON.parse(localStorage.getItem('store') || '{}');

    stores.forEach(item => {
      item.organization = data[item.id];
      item.organizationValue = organizationValue[item.id];
    });

    this.storesSettings = stores.filter(store => {
      return getShopifyStoreStatus(store.id) === false;
    });
  };

  // 获取用户手动输入的Organization选项 用于发给后台
  getMoreOrganizationData = action => {
    const organizationData =
      (this.organizationData[action.storeId][action.type] &&
        this.organizationData[action.storeId][action.type]) ||
      [];
    const categoryMap = { vendor: 1, product_type: 2, tags: 3 };

    const arr = action.value.filter(item => !organizationData.includes(item));

    arr.forEach(item => {
      const flag = this.moreOrganizationData.some(
        li => li.name === item && li.type === categoryMap[action.type]
      );
      if (!flag) {
        this.moreOrganizationData.push({
          store_id: action.storeId,
          type: categoryMap[action.type],
          name: item
        });
      }
    });

    this.moreOrganizationData = this.moreOrganizationData.filter(item => {
      if (item.type !== categoryMap[action.type]) {
        return true;
      }

      if (!arr.includes(item.name)) {
        return false;
      }
      return true;
    });
  };

  // Organization 删除整个store的数据
  deleteOrganizationStoreData = storeId => {
    delete this.organizationSetting[storeId];
  };

  // 改变Collections/Type/Tags/Vendor
  changeOrganizationCategory = action => {
    window.dataLayer.push({
      event: 'importListCustomEvent',
      type: 'organizationSelect',
      position: action.type,
      payload: action.value
    });

    this.organizationSetting[action.storeId] =
      this.organizationSetting[action.storeId] || {};
    if (action.type !== 'collections') {
      this.organizationSetting[action.storeId][action.type] = action.value.join(
        ','
      );
      this.getMoreOrganizationData(action);
      return;
    }

    const arr = [];
    const cols =
      (this.organizationData[action.storeId] &&
        this.organizationData[action.storeId][action.type]) ||
      [];
    const newarr = [];
    cols.forEach(i => {
      newarr.push({ id: i.id, title: i.title });
    });
    action?.searchList?.forEach(item => {
      if (action.value.includes(`${item.id}`)) {
        arr.push({
          id: item.id,
          title: item.title
        });
      }
    });
    this.organizationSetting[action.storeId][action.type] = [...arr];
  };

  getProdLoading = true;
  aeProductDetail = []; // ae 商品的的sku列表
  aeProduct = {}; // 整个 ae 商品
  aePid = '';
  collectionsData = [];
  storesSettings = [];
  organizationSetting = {};
  organizationData = {};
  moreOrganizationData = [];
  inputErrorTimer = null;

  // 设置
  setAEStock = producteDetail => {
    const {
      detail: { variants },
      ext: {
        basic: {
          [this.aePid]: { supply = [] }
        }
      }
    } = producteDetail;
    let flag = false;

    if (
      this.aeProductDetail.length === 1 &&
      this.aeProductDetail[0].id === '<none>'
    ) {
      // mapping的主供应商只有一个sku
      flag = true;
    }

    const allCost = this.aeProduct.item_offer_site_sale_price;

    variants.forEach(item => {
      let { sku } = item;
      supply.some(sp => {
        if (sku === sp.sku_edit || sku === sp.sku_desc || sku === sp.sku) {
          sku = sp.sku;
          return true;
        }
        return false;
      });

      this.aeProductDetail.forEach(ae => {
        let arr = sku.split(';');
        let arr1 = ae.id.split(';');
        
        if (
          sku === ae.id ||
          ae.sku_sign === arr.sort().join(';') ||
          arr.sort().join(';') === arr1.sort().join(';') ||
          flag
        ) {
          if (ae.s_k_u_available_stock !== undefined) {
            item.aeStock = ae.s_k_u_available_stock;
          } else {
            // item.aeStock = ae.sku_stock ? 999 : 0;
            item.aeStock = 0;
          }
          // console.log(this.aeProduct)
          if (this.aeProduct?.product_status_type !== 'onSelling') {
            item.aeStock = 0;
          }
          if (ae.id === '<none>') {
            item.aeCost = allCost || ae.offer_sale_price || ae.sku_price;
          } else {
            item.aeCost = ae.offer_sale_price || allCost || ae.sku_price;
          }
        }
      });
    });
  };

  // 初始化数据
  initData = data => {
    // 迁移数据各种异常 临时处理 2022/05/13 11:23

    try {
      // 修正数据后推送到shopify有bug 不能mapping 先不上
      // 建议判断这种情况提示用户重新导入商品 （import list没有ext 。。。）
      // if(!data.ext) {
      //   data.ext = { basic: {[this.aePid]: {is_default: true, supply: []}} };
      // }
      if (data.detail) {
        if (!data.detail.image) {
          data.detail.image = {};
        }
        if (!data.detail.images) {
          data.detail.images = [];
        }
      }
    } catch (error) {}

    this.tags = data.detail.tags;
    this.initCollections(data);
    this.setVariantId(data);
    this.pushActionOptions(data);

    this.setState({
      producteDetail: data,
      showImages: this.getImages(data)
    });
  };

  // 初始化 collections
  initCollections = data => {
    const { collections = [] } = data;

    collections.forEach(item => {
      this.collections.push(`${item.collection_id}:${item.collection}`);
    });
  };

  // 设置图片内容
  getImages = data => {
    const {
      detail: { images = [], image }
    } = data;

    images.sort((a, b) => (a.src == image.src ? -1 : 0));

    images.forEach(item => {
      if (item.variant_ids == undefined) {
        item.variant_ids = [];
        return;
      }
      if (item.alt == undefined) {
        item.alt = '';
      }
    });

    return images;
  };

  validateOptionValue = options => {
    let hasValid = true;
    for (const item of options) {
      if (item.values.some(i => i.trim() === '')) {
        hasValid = false;
        break;
      }
    }
    return hasValid;
  };

  validateRepeatOptionName = options => {
    let hasValid = true;
    let tmp = {};
    for (const item of options) {
      if (tmp[item.name]) {
        hasValid = false;
        break;
      } else {
        tmp[item.name] = 1;
      }
    }
    tmp = null;
    return hasValid;
  };

  onSubmit = isPublish => {
    if (isPublish == true) {
      this.setState({
        btnType: 'push_to_shopify'
      });
    }
    if (this.state.isHidden && isPublish) {
      this.props.changemodalHidden();
      return;
    }
    this.submitTrue(isPublish);
  };

  submitTrue = isPublish => {
    const { form } = this.props;
    const { validateFields } = form;
    const producteDetail = cloneDeep(this.state.producteDetail);
    const showImages = cloneDeep(this.state.showImages);
    const {
      detail,
      detail: { variants = [] },
      ext
    } = producteDetail;
    // const collections = [];
    const basic = producteDetail?.ext?.basic;
    const { [this.aePid]: { supply = [] } = {} } = basic || {};

    if (this.hasRepeatOptionValue()) {
      message.error(intl.get('product_edit.option_repeated_tip'));
      return;
    }

    if (
      detail.variants &&
      detail.variants.some(i => i.sku?.trim() === '' || i.sku === undefined)
    ) {
      message.error(intl.get('product_edit.option_sku_invalid'));
      return;
    }

    if (
      detail.options &&
      detail.options.some(i => i.name?.trim() === '' || i.name === undefined)
    ) {
      message.error(intl.get('product_edit.option_name_invalid'));
      return;
    }

    // 不能包含 ' / '
    if (detail.options && detail.options.some(i => /\s\/\s/.test(i.name))) {
      message.error(intl.get('product_edit.option_name_slash'));
      return;
    }

    if (detail.options && !this.validateOptionValue(detail.options)) {
      message.error(intl.get('product_edit.option_value_invalid'));
      return;
    }

    if (detail.options && !this.validateRepeatOptionName(detail.options)) {
      message.error(intl.get('product_edit.option_name_repeat'));
      return;
    }

    validateFields((err, values) => {
      if (!err) {
        const repeatSkuArr = [];
        let hasRepeatSku = false;

        variants.forEach(item => {
          if (repeatSkuArr.includes(item.sku)) {
            hasRepeatSku = true;
          }
          repeatSkuArr.push(item.sku);
        });

        if (hasRepeatSku) {
          message.error(intl.get('product_edit.sku_repeated_tip'));
          return;
        }

        variants.forEach(item => {
          item.price += '';
          item.compare_at_price += '';
          delete item.aeStock;
          delete item.originSku;
          delete item.aeCost;
          delete item.img;
          delete item.rowId;
        });

        supply.forEach(sp => {
          delete sp.rowId;
        });

        producteDetail.setting = this.organizationSetting;

        detail.title = values.title;
        detail.body_html = this.descriptionHtml || '';
        detail.specifications = this.specifications || '';
        detail.images = showImages.filter(item => {
          delete item.variant_ids;
          return item;
        });

        //保存时强制更换options

        if (producteDetail.detail.hasOwnProperty('options')) {
          let arc = producteDetail.detail.options?.map(item => {
            return {
              ...item,
              values: []
            };
          });

          producteDetail.detail.options = arc;

          let option1 = [];
          let option2 = [];
          let option3 = [];

          producteDetail.detail.variants.map(item => {
            if (item?.option1) {
              option1.push(item.option1);
            }
            if (item?.option2) {
              option2.push(item.option2);
            }
            if (item?.option3) {
              option3.push(item.option3);
            }
          });

          let b = Array.from(new Set(option1));
          let c = Array.from(new Set(option2));
          let a = Array.from(new Set(option3));

          producteDetail.detail.options[0].values = b;
          if (producteDetail.detail.options[1]?.values) {
            producteDetail.detail.options[1].values = c;
          }
          if (producteDetail.detail.options[2]?.values) {
            producteDetail.detail.options[2].values = a;
          }
        }

        if (isPublish === false) {
          this.props.onSubmit(
            producteDetail.id,
            producteDetail,
            isPublish,
            this.moreOrganizationData
          );
        } else {
          this.props.onSubmit(producteDetail.id, producteDetail, isPublish);
        }
      }
    });
  };

  // 型号的值有没有重复的 例如2个 red / s
  hasRepeatOptionValue = () => {
    const {
      producteDetail: {
        detail: { variants = [], options = [] }
      }
    } = this.state;

    const arr = [];
    let repeat = false;

    variants.forEach(item => {
      let v = '';
      options.forEach((option, i) => {
        v += `${item[`option${i + 1}`]}--`;
      });

      if (arr.includes(v)) {
        repeat = true;
      } else {
        arr.push(v);
      }
    });

    return repeat;
  };

  arrt = [];

  selectChecked = id => {
    if (!this.arrt.includes(id)) {
      this.arrt.push(id);
      this.setState({
        selectedRows: [...this.arrt]
      });
    } else {
      this.arrt = this.arrt.filter(item => item != id);
      this.setState({
        selectedRows: [...this.arrt]
      });
    }

    if (!this.checkedArr.includes(id)) {
      this.checkedArr.push(id);
    } else {
      this.checkedArr = this.checkedArr.filter(item => item != id);
    }
  };

  getColumns = menu => {
    const skuRow = [
      {
        title: intl.get('product_edit.products'),
        dataIndex: 'img',
        width: 78,
        render: (v, record, i) => {
          return (
            <a>
              <img
                src={replaceUrlToHttps(v)}
                style={{ width: 64, height: 64 }}
                onClick={() => {
                  this.showChangeModal(record);
                }}
              />
              {/* <span className="bugw">
                <Checkbox onChange={(e)=>{
                  this.selectChecked(record.rowId)
                }} checked={ this.checkedArr.includes(record.rowId)} />
              </span> */}
            </a>
          );
        }
      },
      {
        title: intl.get('product_edit.sku'),
        dataIndex: 'sku',
        width: 90,
        render: (sku, record) => {
          return (
            <Tooltip title={sku}>
              <Input
                width={90}
                value={sku}
                name={record.id}
                onChange={e => {
                  window.dataLayer.push({
                    event: 'importListCustomEvent',
                    type: 'variants',
                    position: 'sku',
                    payload: e.target.value
                  });
                  this.setState({
                    isHidden: true
                  });
                  this.setVariantSKU(record.rowId, e.target.value);
                }}
              />
            </Tooltip>
          );
        }
      },
      {
        title: intl.get('product_edit.cost'),
        dataIndex: 'aeCost',
        width: 80,
        render: v => {
          return <span>{`$${numeral(v).format('0,0.00')}`}</span>;
        }
      },
      {
        title: intl.getHTML('product_edit.shipping_cost'),
        dataIndex: 'shippingCost',
        width: 80,
        render: v => {
          return (
            <span>
              {this.state.shippingList.length
                ? `$${numeral(v).format('0,0.00')}`
                : `N/A`}
            </span>
          );
        }
      },
      {
        title: intl.get('product_edit.price'),
        dataIndex: 'price',
        width: 80,
        render: (price, record) => {
          return (
            <div className={styles.priceWrap}>
              <span className={styles.currencyTip}>
                <Tooltip
                  title={intl.getHTML('product_edit.product_edit_price_tip')}
                >
                  <Icon type="question-circle" />
                </Tooltip>
              </span>
              <InputNumber
                width={80}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                min={0}
                precision={2}
                value={price}
                onChange={value => {
                  window.dataLayer.push({
                    event: 'importListCustomEvent',
                    type: 'variants',
                    position: 'price',
                    payload: value
                  });
                  this.setState({
                    isHidden: true
                  });
                  this.setVariantPirce(record.rowId, value);
                }}
              />
            </div>
          );
        }
      },
      {
        title: intl.getHTML('product_edit.compare_price'),
        dataIndex: 'compare_at_price',
        width: 80,
        render: (price, record) => {
          return (
            <div className={styles.priceWrap}>
              <span className={styles.currencyTip}>
                <Tooltip
                  title={intl.getHTML('product_edit.product_edit_price_tip')}
                >
                  <Icon type="question-circle" />
                </Tooltip>
              </span>
              <InputNumber
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: '100%' }}
                min={0}
                precision={2}
                value={price}
                onChange={value => {
                  window.dataLayer.push({
                    event: 'importListCustomEvent',
                    type: 'variants',
                    position: 'comparePrice',
                    payload: value
                  });
                  this.setState({
                    isHidden: true
                  });
                  this.setVariantComparePirce(record.rowId, value);
                }}
              />
            </div>
          );
        }
      },
      {
        title: (
          <div>
            {intl.getHTML('product_edit.shopify_stock')}
            <Tooltip
              title={intl.getHTML('product_edit.shopify_stock_tip', {
                platform:
                  this.state.producteDetail.supplier_type === 4
                    ? 'Tmall'
                    : 'AliExpress'
              })}
            >
              <Icon
                style={{ marginLeft: 5 }}
                type="question-circle"
                className={styles.stockTip}
              />
            </Tooltip>
          </div>
        ),
        dataIndex: 'inventory_quantity',
        width: 110,
        render: (v, record) => {
          return (
            <InputNumber
              value={v || 0}
              precision={0}
              min={0}
              max={100000000}
              className={className({
                [styles.redBorder]: Number.isNaN(Number(v))
              })}
              onChange={e => {
                window.dataLayer.push({
                  event: 'importListCustomEvent',
                  type: 'variants',
                  position: 'shopifyToStock',
                  payload: e
                });
                this.setState({
                  isHidden: true
                });
                this.changeStock(record.rowId, e);
              }}
            />
          );
        }
      },
      {
        title: (
          <div>
            {intl.getHTML('product_edit.replace_stock', {
              platform:
                this.state.producteDetail.supplier_type === 4
                  ? 'Tmall'
                  : 'AliExpress'
            })}
            <Tooltip
              placement="topRight"
              style={{ marginLeft: 5 }}
              title={
                this.state.producteDetail.supplier_type === 4
                  ? intl.getHTML('product_edit.tmall_stock_tip')
                  : intl.getHTML('product_edit.aliexpress_stock_tip')
              }
            >
              <Icon
                style={{ marginLeft: 5 }}
                type="question-circle"
                className={styles.stockTip}
              />
            </Tooltip>
          </div>
        ),
        dataIndex: 'aeStock',
        width: 110,
        render: v => {
          return <InputNumber disabled value={v || 0} />;
        }
      }
    ];

    skuRow.push({
      width: 10,
      render: (text, record) => {
        return (
          <i
            class="material-icons"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.removeVariants(record.rowId, record);
            }}
          >
            delete
          </i>
        );
      }
    });

    const {
      producteDetail: {
        detail: { options = [] }
      }
    } = this.state;

    const names = [];
    options.forEach((item, ind) => {
      const dataindex = `option${ind + 1}`;

      if (ind >= 2) {
        names.push(item.name);
      } else {
        skuRow.splice(2 + ind, 0, {
          title: (
            <Tooltip title={item.name.length > 5 ? item.name : null}>
              <Input
                width={65}
                value={item.name}
                name={item.id}
                onChange={e => {
                  window.dataLayer.push({
                    event: 'importListCustomEvent',
                    type: 'variants',
                    position: `title-${dataindex}`,
                    payload: e.target.value
                  });
                  this.setState({
                    isHidden: true
                  });
                  this.changeOptionTitle(e, ind);
                }}
              />
            </Tooltip>
          ),
          dataIndex: dataindex,
          width: 65,
          render: (v, record) => {
            return (
              <Tooltip title={v.length > 5 ? v : null}>
                <Input
                  width={65}
                  value={v}
                  name={record.id}
                  onChange={e => {
                    window.dataLayer.push({
                      event: 'importListCustomEvent',
                      type: 'variants',
                      position: `value-${dataindex}`,
                      payload: e.target.value
                    });
                    this.setState({
                      isHidden: true
                    });
                    this.setVariants(record.rowId, e.target.value, ind + 1);
                  }}
                />
              </Tooltip>
            );
          }
        });
      }
    });

    if (options.length > 2) {
      skuRow.splice(4, 0, {
        // title: names[0],
        title: (
          <Input
            value={names[0]}
            name={options[2].id}
            onChange={e => {
              window.dataLayer.push({
                event: 'importListCustomEvent',
                type: 'variants',
                position: 'title-option3',
                payload: e.target.value
              });
              this.setState({
                isHidden: true
              });
              this.changeOptionTitle(e, 2);
            }}
          />
        ),
        dataIndex: 'option3',
        width: 65,
        render: (option3, record) => {
          return (
            <Tooltip title={option3.length > 5 ? option3 : null}>
              <Input
                value={option3}
                name={record.id}
                onChange={e => {
                  window.dataLayer.push({
                    event: 'importListCustomEvent',
                    type: 'variants',
                    position: names[0],
                    payload: e.target.value
                  });
                  this.setState({
                    isHidden: true
                  });
                  this.setVariants(record.rowId, e.target.value, 2 + 1);
                }}
              />
            </Tooltip>
          );
        }
      });
    }

    // const len = skuRow.length;
    // for (let i = 0; i < len; i++) {
    //   if (skuRow[i]["title"] == "Purchsase cost") {
    //     if (this.state.producteDetail.platfrom_type == 1) {
    //       skuRow.splice(i + 1, 0, {
    //         title: "AliExpress Stock",
    //         dataIndex: "aeStock",
    //         width: 100
    //       });
    //       break;
    //     }
    //   }
    // }

    return skuRow;
  };

  setVariantId = data => {
    const { detail = {}, ext: { basic } = {} } = data;
    const { variants = [], images = [] } = detail;

    const {
      [this.aePid]: { supply = [] }
    } = basic || {};
    let id = 0;

    variants.forEach(variant => {
      const variantData = variant;
      id += 1;
      variantData.inventory_quantity = variantData.inventory_quantity || 0;
      variantData.rowId = id;
      variantData.price =
        Number(variantData.price) || Number(variantData.purchase_cost) || 0;
      variantData.sku = variantData.sku || 'Default';
      variantData.originSku = variantData.sku || 'Default';
      variantData.img = this.getVariantImage(variantData.sku, images);
      this.variantMap[id] = variant;

      supply.forEach(sp => {
        if (sp.sku_edit === variant.sku || sp.sku === variant.sku) {
          sp.rowId = sp.rowId || id;
        }
      });
    });
    this.rowId = id + 1;
  };

  getVariantImage = (sku, images) => {
    let src = defaultImg;
    images.some(item => {
      const { alt = '' } = item;
      if (alt.split('|').includes(sku)) {
        src = item.src;
        return true;
      } else {
        //找不到的情况
        alt.split('|')?.map(altItem => {
          if (altItem.includes(sku)) {
            src = item.src;
          }
        });
      }
      return false;
    });

    return src;
  };

  // 库存变化
  changeStock = (id, value) => {
    clearTimeout(this.inputErrorTimer);
    if (Number.isNaN(Number(value))) {
      this.inputErrorTimer = setTimeout(() => {
        message.error('Please fill in a number');
      }, 300);
    }
    let { producteDetail } = this.state;
    const data = this.variantMap[id];
    if (data) {
      data.inventory_quantity = value || 0;
    }
    producteDetail = { ...producteDetail };
    this.setState({ producteDetail });
  };

  // Compare price变化
  setVariantComparePirce = (id, value) => {
    let { producteDetail } = this.state;
    const data = this.variantMap[id];
    if (data) {
      data.compare_at_price = value || 0;
    }
    producteDetail = { ...producteDetail };
    this.setState({ producteDetail });
  };

  // 更改variant的price
  setVariantPirce = (id, value) => {
    let { producteDetail } = this.state;
    const data = this.variantMap[id];
    if (data) {
      data.price = value || 0;
    }
    producteDetail = { ...producteDetail };
    this.setState({ producteDetail });
  };

  // 批量更改variants的price
  setVariantPirceBatch = () => {
    let { producteDetail } = this.state;
    this.selectedRows.forEach(item => {
      item[this.actionType] = this.actionValue;
    });
    producteDetail = { ...producteDetail };
  };

  // 设置variants的options的值
  setVariants = (id, value, ind) => {
    let { producteDetail } = this.state;
    if (this.variantMap[id]) {
      this.variantMap[id][`option${ind}`] = value;
    }

    if (
      this.actionType != 'price' &&
      this.actionType != 'cost' &&
      this.actionType != 'image_src'
    ) {
      this.upDateSelectVariant(producteDetail);
    }

    producteDetail = { ...producteDetail };
    this.setState({ producteDetail });
  };

  // 设置variants的sku
  setVariantSKU = (id, value) => {
    let {
      producteDetail,
      producteDetail: {
        detail: { images = [] },
        ext: {
          basic: {
            [this.aePid]: { supply = [] }
          }
        } = {}
      }
    } = this.state;

    if (!this.variantMap[id]) {
      return;
    }

    const sku = this.variantMap[id].sku;

    images.some(item => {
      const { alt = '' } = item;
      let skuArr = alt.split('|');
      if (skuArr.includes(sku)) {
        skuArr = skuArr.filter(i => i !== sku);
        skuArr.push(value);
        item.alt = skuArr.join('|');
        return true;
      }
      return false;
    });

    supply.forEach(sp => {
      if (sp.rowId == id) {
        sp.sku_edit = value;
      }
    });
    this.variantMap[id].sku = value;
    producteDetail = { ...producteDetail };
    this.setState({ producteDetail });
  };

  // 设置商品详情
  changeDescription = html => {
    this.descriptionHtml = html;
  };

  changeSpecifications = html => {
    this.specifications = html;
  };

  // 确认删除variants的弹窗
  removeVariants = (rowId, recoed) => {
    const { producteDetail } = this.state;
    if (producteDetail?.detail?.variants?.length === 1) {
      message.error(intl.get('product_edit.sku_count_invalid'));
      return;
    }

    confirm({
      title: intl.get('product_edit.delete_variant_tip'),
      okText: intl.get('public.yes'),
      cancelText: intl.get('public.no'),
      onOk: () => {
        this.removeVariantsSub(rowId, recoed);
        this.setState({
          isHidden: true
        });
      }
    });
  };

  // 确认删除variants
  removeVariantsSub = (rowId, recoed) => {
    const {
      producteDetail: { ae_pid }
    } = this.props;
    let { producteDetail } = this.state;
    const { detail, ext } = producteDetail;
    const variants = [];
    detail.variants.forEach(item => {
      if (rowId !== item.rowId) {
        variants.push(item);
      }
    });

    //处理basic没有删除完全的bug
    let basicArr = ext.basic[ae_pid].supply;
    ext.basic[ae_pid].supply = basicArr.filter(item => rowId !== item.rowId);

    //强制删除一次supply
    ext.basic[ae_pid].supply = basicArr.filter(
      item => recoed.sku !== item.sku_edit
    );

    detail.variants = variants;

    let arr = [];
    detail.variants.forEach(ii => {
      arr.push(ii.img);
    });
    detail.images.map((el, index) => {
      if (!arr.includes(el.src)) {
        delete el.alt;
      }
    });
    producteDetail = { ...producteDetail };
    this.setState({ producteDetail });
  };

  // 弹出选择variant图片弹窗
  showChangeModal = variant => {
    if (this.state.producteDetail.is_pub) {
      return;
    }
    this.currentSku = variant;
    this.selectHead = false;
    this.setState({
      bShowModal: true
    });
  };

  // 隐藏选择图片弹窗
  hideChangeImage = () => {
    // const { producteDetail } = this.state;
    this.setState({
      bShowModal: false
    });
    this.forceUpdate();
  };

  // 把选择的图片给variants
  selectImageToSku = (image = {}) => {
    const { showImages } = this.state;
    showImages.forEach(item => {
      if (item.alt && item.alt.split('|').includes(this.currentSku.sku)) {
        const arr = item.alt
          .split('|')
          .filter(alt => alt != this.currentSku.sku);
        item.alt = arr.join('|');
      }

      if (item.src == image.src) {
        const variantSku = this.currentSku.sku;
        if (
          item.alt != undefined &&
          !item.alt.split('|').includes(variantSku)
        ) {
          let arr = item.alt.split('|');
          arr.push(variantSku);
          arr = arr.filter(sku => !!sku);
          item.alt = arr.join('|');
          return;
        }
        item.alt = variantSku;
      }
    });
    this.variantMap[this.currentSku.rowId].img = image.src;
    let arr = [];
    Object.values(this.variantMap)?.forEach(ii => {
      arr.push(ii.img);
    });
    showImages.map((el, index) => {
      if (!arr.includes(el.src)) {
        delete el.alt;
      }
    });
    this.setState({
      showImages: [...showImages],
      bShowModal: false
    });
  };

  // ae的库存和variants的map
  getAeSkuMap = sku => {
    const obj = {};
    sku = sku || [];
    sku.forEach(item => {
      obj[item.sku_id] = item;
    });
    return obj;
  };

  // 把ae的sku库存赋值给商品 把ae折后价赋值给shopify的成本
  setAEStockInVariants = product => {
    const skus = product.detail.variants;
    const supplier_default = product.supplier_default || [];
    const supplier_defaultOne = supplier_default[0] || '';
    const supplier_sku = product.supplier_sku || {};
    let sku_map = null;
    if (supplier_sku[supplier_defaultOne]) {
      sku_map = supplier_sku[supplier_defaultOne].sku_map;
    }

    skus.forEach(item => {
      item.aeStock = item.inventory_quantity;
    });

    if (product.platfrom_type == 1) {
      const aeSkus = this.getAeSkuMap(product.product_summary.sku_summary);
      let aeSkuFlag = null;
      skus.forEach(item => {
        aeSkuFlag = aeSkus[item.sku];
        if (sku_map) {
          const skuid = sku_map[item.id];
          aeSkuFlag = aeSkus[skuid];
        }
        if (aeSkuFlag) {
          item.purchase_cost = aeSkuFlag.purchase_cost;
        }
      });
    }
  };

  // 返回所有option  需求变更 ----------------- 暂时废弃
  getOptions2 = producteDetail => {
    const { detail = {} } = producteDetail;
    const { options = [] } = detail;
    const optionsArr = [];
    options.forEach((item, index) => {
      if (item.values && item.values.length) {
        item.values.forEach(option => {
          optionsArr.push({
            option: option,
            index: index
          });
        });
      }
    });

    //重转换数据结构   可能会影响到别的逻辑 -- 暂时未知
    let arr = options.map((item, index) => {
      return {
        option: item.name,
        children: item.values.map(child => {
          return {
            option: child,
            index: index
          };
        })
      };
    });

    return arr;
  };

  // 返回所有option
  getOptions = producteDetail => {
    const { detail = {} } = this.state.producteDetail;
    const { options = [] } = detail;
    const optionsArr = [];

    const arr = [];
    options?.forEach((item, index) => {
      const object = {};
      object.title = item.name;
      object.index = index;
      object.options = [];
      item.values?.forEach(op => {
        const obj = {};
        // obj.checked = false;
        obj.value = op;
        obj.label = op;
        // obj.disabled = false;
        object.options.push(obj);
      });
      arr.push(object);
    });

    this.setState({
      selectOptionsProps: arr
    });
  };

  getOptions3 = producteDetail => {
    const { detail = {} } = producteDetail;
    const { options = [] } = detail;
    const optionsArr = [];

    this.option = options;

    options.forEach((item, index) => {
      if (item.values && item.values.length) {
        item.values.forEach(option => {
          optionsArr.push(
            <Radio
              value={option}
              onChange={() => this.clickVariant(index, option, item.name)}
            >
              <a
                key={option}
                role="button"
                tab-index="0"
                value={this.state.optionVal}
                onClick={() => {
                  this.clickVariant(index, option, item.name);
                }}
              >
                {option}
              </a>
            </Radio>
          );
        });
      }
    });
    return optionsArr;
  };

  // 根据option筛选sku row  最新处理逻辑 可能有问题 暂时放在这 ----------  未使用
  clickVariant1 = (type, option) => {
    if (type == 101) {
      // 点击None
      this.disabledCopyOption();

      let arr3 = this.state.producteDetail.detail.variants.map(item => {
        return {
          ...item,
          checked: false
        };
      });

      this.setState({
        producteDetail: {
          ...this.state.producteDetail,
          detail: {
            ...this.state.producteDetail.detail,
            variants: arr3
          }
        }
      });

      this.setState({
        selectedRows: []
      });
      this.checkedArr = [];
      return;
    }

    const { detail = {} } = this.state.producteDetail;
    const { variants = [], options } = detail;
    const arr = [];

    if (type == 100) {
      // 点击All

      variants.forEach(item => {
        arr.push(item.rowId);
        this.selectedRows.push(item);
      });
      this.setState({
        selectedRows: [...arr]
      });
      this.checkedArr = [...arr];
      let arr2 = this.state.producteDetail.detail.variants.map(item => {
        return {
          ...item,
          checked: true
        };
      });

      this.setState({
        producteDetail: {
          ...this.state.producteDetail,
          detail: {
            ...this.state.producteDetail.detail,
            variants: arr2
          }
        }
      });

      this.selectedRows = variants;
      return;
    }

    this.selectedRows = [];
    variants.forEach(item => {
      if (item[`option${type + 1}`] == option) {
        arr.push(item.rowId);
        //本地缓存控制数据的改变
        this.selectedRows.push(item);
      }
    });

    this.copykey = options[type].name && options[type].name.toLowerCase();
    this.copykeyIndex = type + 1;
    this.actionOptions.forEach(item => {
      if (item.key == `d-${this.copykey}`) {
        item.disabled = false;
      } else if (item.key.includes('d-')) {
        item.disabled = true;
      }
    });

    // this.selectVariantRowIds = arr;
    // this.selectVariantValue = option;

    this.setState({
      selectedRows: [...arr]
    });
  };

  severalCheckBox = [];
  saveObj = {};
  getSelectedOptions = v => {

    this.setState({
      optionsSelectRadioValue: ''
    });
    this.saveObj[v.name] = v.options;
    let saveArr = [];

    Object.values(this.saveObj).forEach(i => {
      saveArr.push(...i);
    });
    this.severalCheckBox = saveArr;

    const { detail = {} } = this.state.producteDetail;
    const { variants = [], options } = detail;
    let arr = [];

    if (this.severalCheckBox.length > 1) {
      this.actionOptions = this.actionOptions.map(item => {
        if (item.name.includes('Duplicate in')) {
          return {
            ...item,
            disabled: true
          };
        } else {
          return item;
        }
      });
      this.actionOptions.forEach(item => {
        options.forEach(i => {
          if (item.name == `Change ${i.name.toLowerCase()}`) {
            item.disabled = true;
          }
        });
      });
    } else if (this.severalCheckBox.length == 1) {
      this.copykey = this.severalCheckBox[0].action.toLowerCase();
      this.copykeyIndex = v.type + 1;
      this.actionOptions.forEach(item => {
        if (item.key == `d-${this.copykey}`) {
          item.disabled = false;
        } else if (item.key.includes('d-')) {
          item.disabled = true;
        }
      });

      let keyRes = [];
      options.forEach(item => {
        keyRes.push(item.name.toLowerCase());
      });

      const disabledAction = keyRes.filter(
        item => item !== this.severalCheckBox[0].action.toLowerCase()
      );

      this.actionOptions.forEach(item => {
        if (
          !disabledAction.includes(item.key) &&
          item.name.includes('Change')
        ) {
          item.disabled = false;
        } else if (
          disabledAction.includes(item.key) &&
          item.name.includes('Change')
        ) {
          item.disabled = true;
        }
      });
    }

    this.selectedRows = [];
    this.severalCheckBox.forEach(it => {
      variants.forEach(item => {
        if (item[`option${it.type + 1}`] == it.option) {
          arr.push(item.rowId);
          this.selectedRows.push(item);
        }
      });
    });
    arr = new Set(arr);
    this.setState({
      selectedRows: [...arr]
    });
  };

  // 根据option筛选sku row
  clickVariant = (type, option, action) => {
    this.actionOptions.map(item => {
      item.disabled = false;
      return item;
    });

    const { detail = {} } = this.state.producteDetail;
    const { variants = [], options } = detail;
    const arr = [];

    if (type == 101) {
      // 点击None
      for (let index = 0; index < options?.length; index++) {
        this[`ChildComponent${index}`].clearSelect();
        this.saveObj = {}
      }
      this.actionOptions = this.actionOptions.map(item => {
        if (item.name.includes('Duplicate in')) {
          return {
            ...item,
            disabled: true
          };
        } else {
          return item;
        }
      });

      this.disabledCopyOption();
      this.setState({
        selectedRows: [],
        optionsSelectRadioValue: action === '101' ? '' : 'None'
      });
      return;
    }

    if (type == 100) {
      for (let index = 0; index < options?.length; index++) {
        this[`ChildComponent${index}`].clearSelect();
        this.saveObj = {}
      }

      if (this.option.length > 1) {
        let keyRes = [];
        this.option.forEach(item => {
          keyRes.push(item.name.toLowerCase());
        });
        this.actionOptions.forEach(item => {
          if (keyRes.includes(item.key)) {
            item.disabled = true;
          }
        });
      }

      this.actionOptions = this.actionOptions.map(item => {
        if (item.name.includes('Duplicate in')) {
          return {
            ...item,
            disabled: true
          };
        } else {
          return item;
        }
      });
      // 点击All
      variants.forEach(item => {
        arr.push(item.rowId);
      });
      this.setState({
        selectedRows: [...arr],
        optionsSelectRadioValue: 'All'
      });
      this.selectedRows = variants;
      return;
    }

    this.selectedRows = [];
    variants.forEach(item => {
      if (item[`option${type + 1}`] == option) {
        arr.push(item.rowId);
        this.selectedRows.push(item);
      }
    });

    this.copykey = options[type].name && options[type].name.toLowerCase();
    this.copykeyIndex = type + 1;
    this.actionOptions.forEach(item => {
      if (item.key == `d-${this.copykey}`) {
        item.disabled = false;
      } else if (item.key.includes('d-')) {
        item.disabled = true;
      }
    });

    this.selectVariantRowIds = arr;
    this.selectVariantValue = option;

    if (action) {
      let keyRes = [];
      this.option.forEach(item => {
        keyRes.push(item.name.toLowerCase());
      });

      const disabledAction = keyRes.filter(
        item => item !== action.toLowerCase()
      );

      this.actionOptions.forEach(item => {
        if (disabledAction.includes(item.key)) {
          item.disabled = true;
        }
      });
    }
    this.setState({
      selectedRows: [...arr]
    });
  };

  // 获取批量操作variants的按钮
  pushActionOptions = producteDetail => {
    const {
      detail: { options = [] }
    } = producteDetail;

    this.actionOptions.length = 4;
    options.forEach((item, index) => {
      const v = item.name ? item.name.toLowerCase() : `option${index + 1}`;
      this.actionOptions.push({
        key: v,
        name: `Change ${v}`,
        tip: `New ${v}`,
        disabled: false
      });
      this.keys[v] = `option${index + 1}`;
    });
    options.forEach((item, index) => {
      const v = item.name ? item.name.toLowerCase() : `option${index + 1}`;
      this.actionOptions.push({
        key: `d-${v}`,
        name: `Duplicate in another ${v}`,
        tip: `${v} for duplicated variants`,
        disabled: true
      });

      this.actionOptionsNews.push({
        key: `d-${v}`,
        newKey: item.name
      });
    });
  };

  hideEditSku = () => {
    this.actionType = '';
    this.setState({
      showEditSku: false
    });
  };

  deleteKd = () => {
    this.setState({
      selectedRows: []
    });
  };

  hideDeleteSku = () => {
    this.actionType = '';
    this.setState({
      showDeleteSku: false
    });
  };

  onbShowModalClose = () => {
    this.setState({
      bShowModal: false
    });
  };

  // 批量确认删除variants的弹窗
  removeVariantsAction = () => {
    const { producteDetail, selectedRows } = this.state;
    if (producteDetail?.detail?.variants?.length - selectedRows.length < 1) {
      message.error(intl.get('product_edit.sku_count_invalid'));
      return;
    }
    confirm({
      title: intl.get('product_edit.delete_selected_variant_tip'),
      okText: intl.get('public.yes'),
      cancelText: intl.get('public.cancel'),
      onOk: () => {
        this.deleteSku();
      },
      onCancel: () => {
        this.actionType = '';
      }
    });
  };

  // 删除选中sku
  deleteSku = () => {
    let { producteDetail, selectedRows } = this.state;
    const {
      producteDetail: { ae_pid }
    } = this.props;
    const { detail, ext } = producteDetail;
    let basicArr = ext.basic[ae_pid].supply;
    ext.basic[ae_pid].supply = basicArr.filter((item, index) => {
      const arr = detail.variants.filter(it => {
        if (!selectedRows.includes(it.rowId)) {
          return it;
        }
      });
      if (
        arr.findIndex(i => {
          return i?.sku === item?.sku || i?.sku === item?.sku_edit;
        }) > -1
      ) {
        return item;
      }
    });
    ext.basic[ae_pid].supply.forEach((item, index) => {
      item.rowId = index + 1;
    });
    const variants = [];
    detail.variants.forEach(item => {
      if (!selectedRows.includes(item.rowId)) {
        variants.push(item);
      }
    });
    detail.variants = variants;

    let arr = [];
    detail.variants.forEach(ii => {
      arr.push(ii.img);
    });
    detail.images.map((el, index) => {
      if (!arr.includes(el.src)) {
        delete el.alt;
      }
    });

    this.upDateSelectVariant(producteDetail);
    producteDetail = { ...producteDetail };
    this.setState({ producteDetail,isHidden:true, showDeleteSku: false, selectedRows: [] },()=>{
      this.getOptions();
    });

    this.actionType = '';
  };

  copySku = () => {
    const { producteDetail = {} } = this.state;
    const { detail = {} } = producteDetail;
    const { variants = [], options = [], ext: basic } = detail;

    // let sub = basic['4001230683089'].supply

    let obj2 = {};

    let variantsArr = [];
    this.selectedRows.forEach(item => {
      const obj = { ...item };
      obj[`option${this.copykeyIndex}`] = this.actionValue;
      obj.rowId = this.rowId;
      obj2[item.originSku] = {
        sku: item.sku,
        rowId: obj.rowId
      };
      variants.push(obj);

      variantsArr.push(obj);
      this.rowId += 1;
    });

    producteDetail.ext.basic[
      Object.keys(producteDetail.ext.basic)[0]
    ].supply.forEach(item => {
      const obj3 = { ...item };

      let ss = item.sku_edit;
      if (!ss) {
        ss = item.sku;
      }
      if (!obj2[ss]) {
        return;
      }

      obj3.sku_edit = obj2[ss].sku;
      obj3.rowId = obj2[ss].rowId;

      producteDetail.ext.basic[
        Object.keys(producteDetail.ext.basic)[0]
      ].supply.push(obj3);
    });

    options[this.copykeyIndex - 1].values.push(this.actionValue);
  };

  upDateSelectVariant = product => {
    const { detail = {} } = product;
    const { options = [], variants = [] } = detail;
    const optionValue = [{}, {}, {}];

    variants.forEach(item => {
      options.forEach((option, index) => {
        optionValue[index][item[`option${index + 1}`]] =
          item[`option${index + 1}`];
      });
    });

    options.forEach((item, i) => {
      item.values = Object.values(optionValue[i]);
    });

    this.setVariantId(product);
  };

  // 保存值
  saveEditSku = () => {
    if (this.actionValue || this.actionValue == 0) {
      // 如果有值或值是0
      let { producteDetail } = this.state;
      if (this.actionType == 'price' || this.actionType == 'compare_at_price') {
        this.setVariantPirceBatch();
      } else if (this.actionType.includes('d-')) {
        this.copySku();
      } else {
        this.selectedRows.forEach(item => {
          if (this.actionType === 'inventory_quantity') {
            item[this.keys[this.actionType]] = this.actionValue * 1 || 0;
          } else {
            item[this.keys[this.actionType]] = this.actionValue;
          }
        });
      }

      if (
        this.actionType != 'price' &&
        this.actionType != 'cost' &&
        this.actionType != 'image_src'
      ) {
        this.upDateSelectVariant(producteDetail);
      }

      producteDetail = { ...producteDetail };
      this.setState({
        producteDetail,
        showEditSku: false
      });
    } else {
      this.hideEditSku();
    }
    this.setState({
      isHidden:true
    })
    this.actionType = '';
  };

  upDateSku = async params => {
    const { producteDetail } = this.state;
    try {
      const response = await Post('/prod/select/copy_sku', { data: params });
      if (response.code === 2000) {
        //拼接到state里 找到对应的sku 然后push
        console.log(response.data);
      }
    } catch (error) {
      console.log('fetch error', error);
    }
  };

  selectAction = v => {
    this.setState({
      clickValKey: v.key
    });

    v = v.key;

    window.dataLayer.push({
      event: 'importListCustomEvent',
      type: 'variants',
      position: 'actionMenu',
      payload: v
    });

    this.actionType = v;
    if (v == 'delete') {
      this.removeVariantsAction();
      return;
    }
    if (v == 'image_src') {
      this.setState({
        bShowModal: true
      });
    } else {
      this.setState({
        showEditSku: true
      });
    }
  };

  // 改变option分类的name
  changeOptionTitle = (e, index) => {
    const v = e.target.value;
    Promise.resolve(null).then(() => {
      let { producteDetail } = this.state;
      const { detail = {} } = producteDetail;
      const { options = [] } = detail;
      options[index].name = v;
      producteDetail = { ...producteDetail };
      this.setState({
        producteDetail
      });
    });
  };

  // 批量操作sku用户输入的值
  editSkuVal = v => {
    this.actionValue = v;
  };

  getEditTitle = () => {
    const obj = {};
    this.actionOptions.forEach(item => {
      if (item.key == this.actionType) {
        obj.title = item.key.includes('d-') ? 'Duplicate variants' : item.name;
        obj.tip = item.tip;
      }
    });
    return obj;
  };

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys.length == 0) {
        this.disabledCopyOption();
      }
      this.setState({
        selectedRows: [...selectedRowKeys]
      });
      this.selectedRows = selectedRows;
    }
  };

  disabledCopyOption = () => {
    this.actionOptions.forEach(item => {
      if (item.key.includes('d-')) {
        item.disabled = true;
      }
    });
  };

  // tags变化
  changeTags = e => {
    this.tags = e.join(',');
  };

  // 更改 collection
  changeCollection = e => {
    this.collections = e;
  };

  currentSku = null; // 设置图片的variant
  selectHead = false; // 设置主图还是variant的图片
  selectVariantRowIds = [];
  selectVariantValue = '';
  disabledFlag = false; // 手否禁用表单
  collections = [];
  tags = '';
  imageList = [];
  variantMap = {};
  skuSame = true; // 是否和aliSku相同boolean
  exampleSku = null;
  selectedRows = [];
  actionType = '';
  actionValue = '';
  copykey = '';
  rowId = 0;
  copykeyIndex = null;
  keys = {
    price: 'price',
    compare_at_price: 'compare_at_price',
    inventory_quantity: 'inventory_quantity'
  };
  actionOptionsNews = [];
  actionOptions = [
    {
      key: 'price',
      name: intl.get('product_edit.change_price'),
      tip: intl.get('product_edit.new_price'),
      disabled: false
    },
    {
      key: 'compare_at_price',
      name: intl.get('product_edit.change_compare_at_price'),
      tip: intl.get('product_edit.new_compare_price'),
      disabled: false
    },
    // {
    //   key: 'delete',
    //   name: intl.get('product_edit.remove_sku'),
    //   tip: intl.get('product_edit.delete_selected_variant_tip'),
    //   disabled: false
    // },
    {
      key: 'inventory_quantity',
      name: intl.get('product_edit.change_stock'),
      tip: intl.get('product_edit.new_stock'),
      disabled: false
    }
  ];

  // 弹出选择主图
  showSelectHeadImg = () => {
    this.selectHead = true;
    this.setState({
      bShowModal: true
    });
  };

  // 把选择的图给主图
  selectImageToHead = (image = {}) => {
    const { producteDetail } = this.state;

    producteDetail.detail.image.src = image.src;

    const data = { ...producteDetail };
    this.setState({
      producteDetail: data,
      showImages: [...this.getImages(data)],
      bShowModal: false
    });
  };

  getCollectionsValues = data => {
    const { collections } = data;
    if (!collections || collections.length == 0) {
      return [];
    }
    const arr = [];

    collections.forEach(v => {
      this.collectionsData.forEach(item => {
        if (item.id == v.collection_id) {
          arr.push(`${item.id}:${item.title}`);
        }
      });
    });

    return arr;
  };

  handleTabChange = key => {
    this.setState({
      tagKey: key
    });

    if (key == 'images') {
      this.setState({
        isimage: true
      });
    } else {
      this.setState({
        isimage: false
      });
    }
    if (key == 'product') {
      this.setState({
        isProduct: false
      });
    } else {
      this.setState({
        isProduct: true
      });
    }

    if (key == 'shipping') {
      this.setState({
        isShopInfo: false
      });
    } else {
      this.setState({
        isShopInfo: true
      });
    }

    if (key === 'shipping') {
      window.dataLayer.push({
        event: 'importListCustomEvent',
        type: 'openShippingTab'
      });
    }
  };

  changeHiddenStatus = () => {
    this.setState({
      isHidden: true
    });
  };

  // 删除图片
  deleteImage = item => {
    const { showImages } = this.state;

    const arr = showImages.filter(image => item.src != image.src);

    this.setState({
      showImages: [...arr],
      isHidden: true
    });
  };
  // 批量删除图片
  deleteImages = (dataList, product_id) => {
    dataList.detail.variants = dataList.detail.variants.map(i => {
      i.price += '';
      return i;
    });
    this.setState({
      isHidden: false
    });

    this.props.dispatch({
      type: 'productmy/productEditImport',
      payload: {
        data: {
          unique_id: product_id,
          detail: dataList.detail,
          ext: dataList.ext,
          setting: dataList.setting
        },
        callback: () => {
          this.setState({
            showImages: dataList.detail.images
          });
        }
      }
    });
  };

  productTitleInputhandler = e => {
    this.setState({
      productTitleInput: e.target.value
    });
  };

  aeSku = '';
  sku_id = '';

  // 获取选定的物流方式列表
  getShippingCost = async () => {
    const {
      selectedShipToCity,
      producteDetail,
      defaultShipToCity,
      selectedShipToCountry,
      selectedShippingMethod,
      selectedShipsFrom
    } = this.state;
    const freightParams = {
      prod_id: this.props.currentProduct.ae_pid + '',
      sku: selectedShipsFrom || this.aeSku,
      country_code: selectedShipToCountry,
      province: selectedShipToCity?.length ? selectedShipToCity[0] : '',
      city: selectedShipToCity?.length ? selectedShipToCity[1] : '',
      quantity: 1,
      supplier_type: this.props.currentProduct.supplier_type,
      sku_id: this.sku_id
    };
    const freightRes = await getAeProductFreight({
      data: freightParams
    });
    if (freightRes) {
      this.setState({
        freightLoading: false
      });
    }
    let shippingCompanys = [];
    if (freightRes?.data?.freight?.length) {
      freightRes.data.freight.forEach(item => {
        shippingCompanys.push({
          code: item.company,
          company: item.company,
          price: item?.freightAmount?.value
        });
      });
    }
    this.setState(
      {
        shippingList: shippingCompanys
      },
      () => {
        if (selectedShippingMethod || shippingCompanys) {
          this.selectShippingMethodHandler(selectedShippingMethod);
        }

        // if(defaultShipToCity?.length){
        //   this.selectShippingfromCityHandler()
        // }
      }
    );
  };

  // 保存本次选择的物流方式
  saveShippingHandler = async () => {
    const {
      selectedShipToCountry,
      selectedShipsFrom,
      selectedShippingMethod,
      selectedShipToCity
    } = this.state;
    const res = await Put('/prod/select/shipping', {
      data: {
        unique_id: this.props.currentProduct.unique_id,
        ship_from: selectedShipsFrom,
        ship_to_country: selectedShipToCountry,
        ship_to_province: selectedShipToCity[0],
        ship_to_city: selectedShipToCity[1],
        shipping_method: selectedShippingMethod
      }
    });
  };

  // 获取shipsfrom
  setShipsFrom = (prod, defaultMethod) => {
    if (!prod) {
      return;
    }
    const {
      aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] }
    } = prod;

    const obj = {};
    this.sku_id = aeop_ae_product_sku[0]?.sku_id;
    this.aeSku = aeop_ae_product_sku[0]?.id;

    aeop_ae_product_sku.forEach(item => {
      const { aeop_s_k_u_propertys = {} } = item;

      if (!aeop_s_k_u_propertys.aeop_sku_property) {
        return;
      }

      aeop_s_k_u_propertys.aeop_sku_property.forEach(sku => {
        if (sku.sku_property_name == 'Ships From') {
          if (!obj[`${sku.sku_property_id}-${sku.property_value_id_long}`]) {
            obj[`${sku.sku_property_id}-${sku.property_value_id_long}`] = {
              sku_property_value: sku.sku_property_value,
              sku_property_id: sku.sku_property_id,
              property_value_id_long: sku.property_value_id_long,
              skuid: item.id
            };
          }
        }
      });
    });

    const shipsFrom = Object.values(obj);

    if (Object.values(obj).length) {
      this.aeSku = shipsFrom[0].skuid;
      this.shipsFrom = `${shipsFrom[0].sku_property_id}:${shipsFrom[0].property_value_id_long}`;
    }

    let defaultShipFrom = shipsFrom.filter(i => {
      return i?.sku_property_value == 'China';
    });

    this.setState({
      shipsFrom,
      selectedShipsFrom: defaultMethod
        ? defaultMethod
        : defaultShipFrom.length
        ? defaultShipFrom[0]?.skuid
        : shipsFrom[0]?.skuid
    });
  };

  // 获取默认的shipto
  getDefaultShipTo = data => {
    const { selectedShipsFrom, selectedShipToCity } = this.state;
    let arr = [];
    if (
      data.prod.shipping.ship_to_province ||
      data.prod.shipping.ship_to_city
    ) {
      arr = [
        data.prod.shipping.ship_to_province,
        data.prod.shipping.ship_to_city
      ];
    }
    this.aeSku = data.prod.shipping.ship_from;
    this.setState(
      {
        selectedShipToCountry: data.prod.shipping.ship_to_country,
        defaultShipToCity: arr,
        selectedShipsFrom: data.prod.shipping.ship_from,
        selectedShippingMethod: data.prod.shipping.shipping_method,
        freightLoading: true
      },
      () => {
        this.setShipsFrom(data.ae_prod, data.prod.shipping.ship_from);
        this.selectShippingfromCountryHandler(
          data.prod.shipping.ship_to_country,
          'default'
        );
        this.selectShippingfromCityHandler(arr, 'default');
        this.selectShippingMethodHandler(
          data.prod.shipping.shipping_method,
          'default'
        );
      }
    );
  };

  // 更改ships from
  changeShipsFrom = v => {
    const { shipsFrom } = this.state;

    shipsFrom.forEach(item => {
      if (`${item.sku_property_id}:${item.property_value_id_long}` == v) {
        this.aeSku = item.skuid;
      }
    });

    this.shipsFrom = v;
    this.setState(
      {
        selectedShipsFrom: v,
        shippingList: [],
        selectedShippingMethod: '',
        freightLoading: true
      },
      () => {
        this.selectShippingMethodHandler();
        this.getShippingCost();
        this.saveShippingHandler();
      }
    );
  };

  // 获取国家列表（用于shippingcost）
  getShippingToCountryList = () => {
    const countryList = JSON.parse(localStorage.getItem('countryList'));
    let options = [];
    countryList.forEach(i => {
      options.push({
        code: i.Code,
        name: i.Name
      });
    });
    this.setState({
      cascaderCountryOptions: options
    });
  };

  // 根据选择国家拼出省份城市
  selectShippingfromCountryHandler = (v, type) => {
    let cityList = [];
    if (v == 'ID' || v == 'RU' || v == 'ES' || v == 'BR' || v == 'FR') {
      getCity({
        data: {
          country_code: v
        }
      }).then(res => {
        if (res.data) {
          res.data.forEach((item, index) => {
            cityList.push({
              code: item.Name,
              name: item.Name,
              items: []
            });
            if (item.Citys?.length) {
              item.Citys?.forEach((ii, ind) => {
                if (item.Citys?.length) {
                  cityList[index].items[ind] = {
                    code: ii.Name,
                    name: ii.Name
                  };
                }
              });
            }
          });
        }
        if (type !== 'default') {
          this.setState({
            defaultShipToCity: [],
            selectedShipToCity: [],
            shippingList: [],
            selectedShippingMethod: ''
          });
        }
        this.setState(
          {
            shipToCity: cityList,
            selectedShipToCountry: v,
            freightLoading: true
          },
          () => {
            this.getShippingCost();
            this.saveShippingHandler();
            this.selectShippingMethodHandler();
          }
        );
      });
    } else {
      if (type !== 'default') {
        this.setState({
          defaultShipToCity: [],
          selectedShipToCity: [],
          shippingList: [],
          selectedShippingMethod: ''
        });
      }
      this.setState(
        {
          shipToCity: cityList,
          selectedShipToCountry: v,
          freightLoading: true
        },
        () => {
          this.getShippingCost();
          this.saveShippingHandler();
          this.selectShippingMethodHandler();
        }
      );
    }
  };

  selectShippingMethodHandler = (v, type) => {
    const { shippingList, selectedShippingMethod, producteDetail } = this.state;
    let defaultMethod = shippingList.filter(i => {
      return i.company == 'AliExpress Premium Shipping';
    });
    let shippingCost = shippingList.filter(i => {
      return (
        i.company ==
        (v ||
          (defaultMethod?.length
            ? defaultMethod[0]?.company
            : shippingList[0]?.company))
      );
    })[0]?.price;
    producteDetail.detail.variants.forEach(item => {
      item.shippingCost = shippingCost;
    });
    this.setState(
      {
        selectedShippingMethod:
          v ||
          (defaultMethod?.length
            ? defaultMethod[0]?.company
            : shippingList[0]?.company),
        producteDetail
      },
      () => {
        if (type !== 'default') {
          this.saveShippingHandler();
        }
      }
    );
  };

  selectShippingfromCityHandler = (v, type) => {
    this.setState(
      {
        selectedShipToCity: v,
        shippingList: [],
        selectedShippingMethod: '',
        filterCascaderVisible: false,
        freightLoading: true
      },
      () => {
        if (type !== 'default') {
          this.saveShippingHandler();
        }
        this.getShippingCost();
        this.selectShippingMethodHandler();
      }
    );
  };

  dotHandler(type) {
    if (type === 4) {
      CommonDataCollect({
        action: 'importlist_edit_click_for_details',
        event_type: 'import_list_tmall'
      });
    }
  }

  getBB = () => {
    const {
      selectedShipToCity,
      defaultShipToCity,
      shipToCity,
      filterCascaderVisible
    } = this.state;
    const filterCascader = (inputValue, path) => {
      return path.some(
        option =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    };
    return (
      <div
        className={styles.CascaderCon}
        title={
          selectedShipToCity.length
            ? `${selectedShipToCity[0]} / ${selectedShipToCity[1]}`
            : intl.getHTML('product_edit.select_province_pleaseholder')
        }
      >
        <Cascader
          showSearch={{ filterCascader }}
          onClick={() => {
            this.setState({
              filterCascaderVisible: true
            });
          }}
          // placeholder={intl.getHTML('product_edit.select_province_pleaseholder')}
          className={styles.fuLogisticsSelect}
          popupClassName={styles.popupClass}
          fieldNames={{ label: 'name', value: 'code', children: 'items' }}
          defaultValue={defaultShipToCity}
          style={{ width: 100 }}
          autoFocus={filterCascaderVisible}
          suffixIcon={
            <img
              width={'24px'}
              height={'24px'}
              className="clasSelImgCascader"
              src={require('../../assets/newImportList/icon_expand_more-24px.png')}
            ></img>
          }
          value={selectedShipToCity}
          options={shipToCity}
          expandTrigger="hover"
          onChange={this.selectShippingfromCityHandler}
          onPopupVisibleChange={v => {
            if (!v) {
              this.setState({
                filterCascaderVisible: false
              });
            }
          }}
        />
      </div>
    );
  };

  render() {
    const {
      form,
      updateProductLoading,
      onHide,
      removeProduct,
      copyLink,
      awaitPush,
      organizationLoading,
      saveEditLoading
    } = this.props;

    const { getFieldDecorator } = form;

    const {
      producteDetail,
      showImages,
      bShowModal,
      selectedRows,
      showEditSku,
      showDeleteSku,
      warningTips,
      clickValKey,
      tagKey,
      productTitleInput,
      cascaderCountryOptions,
      shipToCity,
      selectedShipsFrom,
      selectedShipToCountry,
      selectedShipToCity,
      defaultShipToCity,
      shipsFrom,
      shippingList,
      selectedShippingMethod,
      freightLoading
    } = this.state;
    const {
      detail: { image = {} }
    } = producteDetail;
    const headers = {
      ...getAuthorityHeader()
    };

    const menu = (
      <Menu onClick={this.selectAction}>
        {this.actionOptions.map(item => {
          return (
            <Menu.Item key={item.key} disabled={item.disabled}>
              {item.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    this.descriptionHtml = this.descriptionHtml
      ? this.descriptionHtml
      : producteDetail.detail?.body_html || '';

    this.specifications = this.specifications
      ? this.specifications
      : producteDetail.detail?.specifications || '';

    if (selectedRows.toString() != this.selectVariantRowIds.toString()) {
      this.selectVariantValue = '';
      this.selectVariantRowIds = [];
    }

    const operations = (
      <div className="openbtn">
        <a
          onClick={() => this.dotHandler(producteDetail.supplier_type)}
          href={getSupplierUrl(producteDetail.supplier_type, this.aePid)}
          target="_blank"
        >
          <Button data="bk">{intl.get('product_edit.open_on_supplier')}</Button>
        </a>

        <Button
          data="bk"
          onClick={() => {
            this.onSubmit(true);
            if (producteDetail.supplier_type === 4) {
              CommonDataCollect({
                action: 'importlist_edit_push_to_shopify',
                event_type: 'import_list_tmall'
              });
            }
          }}
          disabled={awaitPush}
        >
          {intl.get('product.product.push_to_shopify')}
        </Button>

        {/* <a
          href={`${window.DSERS_API_CONFIG.HOST
            }/api/v1/product/downloadimg?product_id=${producteDetail.id}&token=${headers.Authorization
            }&store_id=${localStorage.getItem('storeId')}`}
          target="_blank"
        >
          <Button
            className={this.state.isimage ? 'down_btn' : 'down_btn_dis'}
            data="bk"
          >
            {intl.get('product_edit.download_all_img')}
          </Button>
        </a> */}
      </div>
    );

    const { producteDetail: originProducteDetail } = this.props;

    // // 商品是否是商品池自营商品
    // const isTendays = originProducteDetail?.prod?.is_ten_days;
    const shippingData = {
      product_ship_to: originProducteDetail?.prod?.ship_to,
      aliexpress_product_id: originProducteDetail?.ae_pid,
      supplier_type: originProducteDetail?.supplier_type,
      image_src: producteDetail.detail && producteDetail.detail.image.src,
      title: producteDetail.detail && producteDetail.detail.title
    };

    return (
      <>
        <div className="btn_t">
          {/* <a onClick={onHide} role="button" tabIndex="-1">
            <Icon type="double-left" />
            {intl.get('product_edit.back')}
          </a> */}

          <div className="l">{operations}</div>
          <div className={this.state.isShopInfo ? 'r' : 'r'}>
            {/* <Button
              size="large"
              className="del"
              data="bk"
              onClick={() => {
                window.dataLayer.push({
                  event: 'importListCustomEvent',
                  type: 'deleteDescription'
                });
                removeProduct(producteDetail.product_unique_id, 1);
              }}
            >
              {intl.get('public.delete')}
            </Button> */}
            {producteDetail.status != 1 ? (
              <Button
                type="primary"
                size="large"
                data="dx"
                onClick={() => {
                  window.dataLayer.push({
                    event: 'importListCustomEvent',
                    type: 'saveDescription'
                  });
                  this.onSubmit(false);
                }}
                loading={updateProductLoading || saveEditLoading}
              >
                {intl.get('public.save')}
              </Button>
            ) : null}
          </div>
        </div>

        {this.getProdLoading ? (
          <div className={styles.loadingSpin}>
            <Spin />
          </div>
        ) : (
          <Form className={styles.productForm}>
            {warningTips ? (
              <Alert
                message={intl.get(
                  'order.order_product.shipping_dialog.overrun_warning'
                )}
                type="warning"
                showIcon
              />
            ) : null}
            <Tabs
              onChange={this.handleTabChange}
              tabPosition="top"
              activeKey={tagKey}
              animated={{ inkBar: false, tabPane: false }}
            >
              <TabPane tab={intl.get('product_edit.product')} key="product">
                <Row gutter={24}>
                  <Col span={6}>
                    <div className={styles.headImage}>
                      <div className="ant-upload-list ant-upload-list-picture-card">
                        <div className="ant-upload-list-item">
                          <div className="ant-upload-list-item-info">
                            <span>
                              <a
                                className="ant-upload-list-item-thumbnail"
                                href={
                                  replaceUrlToHttps(image.src) || defaultImg
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={
                                    replaceUrlToHttps(image.src) || defaultImg
                                  }
                                  alt=""
                                  onError={e => {
                                    e.target.onerror = null;
                                    e.target.src = defaultImg;
                                  }}
                                />
                              </a>
                              <a
                                className="ant-upload-list-item-name"
                                href={
                                  replaceUrlToHttps(image.src) || defaultImg
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              />
                            </span>
                          </div>
                          <span className="ant-upload-list-item-actions">
                            <Icon
                              type="edit"
                              onClick={this.showSelectHeadImg}
                            />
                          </span>
                        </div>
                      </div>
                      <div className={styles.changeImageLine}></div>
                    </div>
                    <Button
                      data="dx"
                      style={{ width: '100%', marginTop: 5 }}
                      className={styles.changeImgBtn}
                      onClick={this.showSelectHeadImg}
                    >
                      {intl.get('product_edit.change_image')}
                    </Button>
                  </Col>
                  <Col span={18}>
                    <Form.Item
                      label={intl.get('product_edit.title')}
                      extra={
                        <p className={styles.productFrom}>
                          {producteDetail.supplier_type === 4
                            ? intl.get('product_edit.from_tmall')
                            : intl.get('product_edit.from_aliexpress')}
                        </p>
                      }
                    >
                      {getFieldDecorator('title', {
                        initialValue: producteDetail.detail.title || '',
                        rules: [
                          {
                            required: true,
                            message: intl.get('product_edit.input_title')
                          }
                        ]
                      })(
                        <Input.TextArea
                          maxLength={225}
                          setFieldsValue={productTitleInput}
                          className={styles.importListNameTextArea}
                          onChange={this.productTitleInputhandler}
                          onBlur={e => {
                            if (form.isFieldTouched('title')) {
                              window.dataLayer.push({
                                event: 'importListCustomEvent',
                                type: 'changeTitle',
                                payload: e.target.value
                              });
                            }
                          }}
                        />
                      )}
                    </Form.Item>
                    <Form.Item label={intl.get('product_edit.organization')}>
                      {organizationLoading ? (
                        <div className={styles.loadingSpin}>
                          <Spin />
                        </div>
                      ) : (
                        <Organization
                          data={this.storesSettings}
                          changeCategory={this.changeOrganizationCategory}
                          deleteStoreData={this.deleteOrganizationStoreData}
                          changeHiddenStatus={this.changeHiddenStatus}
                        />
                      )}
                    </Form.Item>
                    {producteDetail.is_pub ? (
                      <Alert
                        message={intl.get('product_edit.no_edit')}
                        type="warning"
                      />
                    ) : null}
                  </Col>
                </Row>
              </TabPane>
              <TabPane
                tab={intl.getHTML('product_edit.variants', {
                  allvariants: producteDetail.detail.variants?.length || 0
                })}
                key="variants"
              >
                <>
                  <Form.Item>
                    {intl.get('product_edit.select_variant')}
                    <div className="variantBox" style={{ marginBottom: 20 }}>
                      {/* <Radio.Group > */}
                      <Radio
                        checked={this.state.optionsSelectRadioValue === 'All'
                      && this.state.selectedRows?.length ===
                      (this.state.producteDetail?.detail?.variants?.length || 0)}
                        onChange={() => {
                          this.clickVariant(100, 'All');
                        }}
                      >
                        <a
                          role="button"
                          tab-index="0"
                          onClick={() => {
                            this.clickVariant(100, 'All');
                          }}
                        >
                          All
                        </a>
                      </Radio>
                      <Radio
                        checked={this.state.optionsSelectRadioValue === 'None' && this.state.selectedRows?.length === 0}
                        onChange={() => {
                          this.clickVariant(101, 'None');
                        }}
                      >
                        <a
                          role="button"
                          tab-index="0"
                          onClick={() => {
                            this.clickVariant(101, 'None');
                          }}
                        >
                          None
                        </a>
                      </Radio>
                      {/* {this.getOptions3(producteDetail)} */}
                      {/* </Radio.Group> */}
                      <div className={styles.optionsContainer}>
                        {this.state.selectOptionsProps.map((item, index)=> {
                          return (
                            <>
                              <ProductOptionsSelect
                                ref={ref => {
                                  this[`ChildComponent${index}`] = ref;
                                }}
                                title={item.title}
                                index={item.index}
                                options={item.options}
                                getSelectedOptions={this.getSelectedOptions}
                              ></ProductOptionsSelect>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="dwq">
                      <Checkbox
                        checked={
                          this.state.selectedRows?.length ===
                          (this.state.producteDetail?.detail?.variants?.length || 0)
                        }
                        onClick={e => {
                          if (e.target.checked) {
                            this.clickVariant(100, 'All');
                          } else {
                            this.clickVariant(101, 'None','101');
                          }
                        }}
                        indeterminate={
                          selectedRows.length > 0 &&
                          selectedRows.length !==
                            producteDetail.detail.variants?.length
                        }
                      />

                      <span className="select_r">
                        <div className="drapop">
                          <Dropdown
                            overlay={menu}
                            disabled={this.state.selectedRows.length == 0}
                          >
                            <Button className="drapopbutton">
                              {intl.get('product_edit.action')}
                              <img
                                className="img_btn_y"
                                src={require('../../assets/newImportList/icon_expand_more-24px.png')}
                              ></img>
                            </Button>
                          </Dropdown>
                        </div>
                      </span>

                      <div className="shippingInfo">
                        {shipsFrom.length ? (
                          <div className={styles.shipFrom}>
                            <span className="title">
                              {intl.getHTML('product_edit.ship_from_text')}
                            </span>
                            <Select
                              showSearch
                              placeholder="Please select"
                              style={{ width: 100, marginRight: 16 }}
                              className={styles.fuLogisticsSelect}
                              dropdownMatchSelectWidth={false}
                              suffixIcon={
                                <img
                                  className="clasSelImgs"
                                  src={require('../../assets/newImportList/icon_expand_more-24px.png')}
                                ></img>
                              }
                              value={selectedShipsFrom}
                              defaultValue={selectedShipsFrom}
                              dropdownClassName={styles.dropdownClass}
                              onChange={this.changeShipsFrom}
                              getPopupContainer={triggerNode =>
                                triggerNode.parentNode
                              }
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {shipsFrom.map(item => {
                                return (
                                  <Option key={item.skuid}>
                                    {item.sku_property_value}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        ) : null}

                        <div className="shipto">
                          <span className="title">
                            {intl.getHTML('product_edit.ship_to_text')}
                          </span>
                          <div className={styles.shipTo}>
                            <Select
                              showSearch
                              className={styles.fuLogisticsSelect}
                              style={{ width: 100 }}
                              dropdownMatchSelectWidth={false}
                              dropdownStyle={{ width: 150 }}
                              options={cascaderCountryOptions}
                              value={selectedShipToCountry}
                              onChange={this.selectShippingfromCountryHandler}
                              suffixIcon={
                                <img
                                  className="clasSelImgs"
                                  src={require('../../assets/newImportList/icon_expand_more-24px.png')}
                                ></img>
                              }
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {cascaderCountryOptions.map(item => {
                                return (
                                  <Option key={item.code}>{item.name}</Option>
                                );
                              })}
                            </Select>
                            {defaultShipToCity?.length || shipToCity.length
                              ? this.getBB()
                              : null}
                            <Select
                              className={styles.fuLogisticsSelect}
                              showSearch
                              disabled={!shippingList?.length}
                              options={shippingList}
                              style={{ width: 250 }}
                              value={selectedShippingMethod}
                              placeholder={
                                !shippingList?.length
                                  ? 'N/A'
                                  : 'please select a shipping method'
                              }
                              onChange={this.selectShippingMethodHandler}
                              suffixIcon={
                                <img
                                  className="clasSelImgs"
                                  src={require('../../assets/newImportList/icon_expand_more-24px.png')}
                                ></img>
                              }
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {shippingList.map(item => {
                                return (
                                  <Option key={item.code}>
                                    {item.company}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className={styles.delete_btn}>
                          <Button
                            onClick={() => {
                              this.removeVariantsAction();
                            }}
                            disabled={!(selectedRows.length > 0)}
                            className={styles.btn}
                            style={{ marginRight: 20 }}
                          >
                            <i class="material-icons notranslate">delete</i>{' '}
                            {intl.get('product_edit.remove_sku')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                </>
                <Form.Item>
                  {/* Variants列表 */}
                  <div className="tableBox">
                    <Spin spinning={freightLoading}>
                      <Table
                        columns={this.getColumns(menu)}
                        rowKey="rowId"
                        pagination={false}
                        dataSource={producteDetail.detail.variants}
                        rowSelection={{
                          ...this.rowSelection,
                          selectedRowKeys: selectedRows
                        }}
                      />
                    </Spin>
                  </div>
                </Form.Item>
              </TabPane>
              <TabPane
                tab={intl.get('product_edit.description')}
                key="description"
              >
                <div className="tableBox">
                  <div
                    className={styles.descriptionTitle}
                    dangerouslySetInnerHTML={{
                      __html: intl.get('product_edit.push_description')
                    }}
                  />
                  <div
                    className={styles.descriptionTitle}
                    style={{ marginBottom: '8px' }}
                  >
                    {intl.get('product_edit.specifications')}
                  </div>
                  <Editor
                    onChange={this.changeSpecifications}
                    headers={headers}
                    initContent={this.specifications}
                    changeHiddenStatus={this.changeHiddenStatus}
                  />
                </div>
                <div className="tableBox">
                  <div
                    className={styles.descriptionTitle}
                    style={{ marginTop: '16px', marginBottom: '8px' }}
                  >
                    {intl.get('product_edit.overview')}
                  </div>
                  <Editor
                    onChange={this.changeDescription}
                    headers={headers}
                    initContent={this.descriptionHtml}
                    changeHiddenStatus={this.changeHiddenStatus}
                  />
                </div>
              </TabPane>
              <TabPane
                tab={intl.getHTML('product_edit.images', {
                  selectImages: this.state.showImages.filter(
                    item => item.choose === true
                  ).length,
                  allImages: this.state.showImages.length
                })}
                key="images"
              >
                <Form.Item>
                  <div>
                    <ImagesList
                      data={showImages}
                      product_id={producteDetail.id}
                      producte_detail={producteDetail}
                      deleteImage={this.deleteImage}
                      is_pub={producteDetail.is_pub}
                      dispatch={this.props.dispatch}
                      deleteImages={this.deleteImages}
                      changeHiddenStatus={this.changeHiddenStatus}
                    />
                  </div>
                </Form.Item>
              </TabPane>
              <TabPane tab={intl.get('product_edit.shipping')} key="shipping">
                <ShippingDetail data={shippingData} />
              </TabPane>
            </Tabs>
          </Form>
        )}

        {bShowModal ? (
          <Drawer
            title="Images"
            width={868}
            closable={false}
            onClose={this.onbShowModalClose}
            visible={this.state.bShowModal}
          >
            <SelectImageManagement
              params={{ product_id: producteDetail.id }}
              data={showImages}
              onOk={
                this.selectHead ? this.selectImageToHead : this.selectImageToSku
              }
              onCancel={this.hideChangeImage}
            />
          </Drawer>
        ) : null}

        {showEditSku ? (
          <Modal
            title={this.getEditTitle().title}
            width={560}
            visible={showEditSku}
            onCancel={this.hideEditSku}
            onOk={this.saveEditSku}
            maskClosable={false}
            okText={intl.get('public.save')}
            cancelText={intl.get('public.cancel')}
          >
            <p>{this.getEditTitle().tip}</p>
            {this.actionType == 'price' ||
            this.actionType == 'cost' ||
            this.actionType == 'discount' ? (
              <InputNumber
                precision={2}
                min={0}
                style={{ width: '100%' }}
                onChange={v => {
                  this.editSkuVal(v);
                }}
              />
            ) : clickValKeyArr.includes(clickValKey) ? (
              <InputNumber
                style={{ width: '100%' }}
                onChange={e => {
                  this.editSkuVal(e);
                }}
              />
            ) : (
              <Input
                onChange={e => {
                  this.editSkuVal(e.target.value);
                }}
              />
            )}
          </Modal>
        ) : null}

        {showDeleteSku ? (
          <Modal
            title={intl.get('product_edit.delete_variant_tip')}
            width={560}
            visible={showDeleteSku}
            onCancel={this.hideDeleteSku}
            onOk={this.deleteSku}
            okType="danger"
            maskClosable={false}
            okText={intl.get('public.delete')}
            cancelText={intl.get('public.cancel')}
          >
            <p>{intl.get('product_edit.cannot_recover')}</p>
          </Modal>
        ) : null}
      </>
    );
  }
}

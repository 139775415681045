/*
 * Created Date: Monday March 22nd 2021
 * Author: William (cweiliang.me@gmail.com)
 */

import React, { useEffect } from 'react';
import { Alert, Icon, Button } from 'antd';
import intl from 'react-intl-universal';

import usePushCheck from '../usePushCheck';
import styles from './PushNotice.less';

import appIcon from '../../../assets/app_icon.png';
import succ from 'assets/alert/pushsucc.png'
import error from 'assets/alert/pusherr.png'
import closesucc from 'assets/alert/closesucc.png'
import closeerr from 'assets/alert/closeerr.png'

function PushNotice({
  reqId,
  onCheckDetail,
  onStart,
  onFinish,
  onPending,
  onError,
  pushState,
  selectedPushProducts,
  hasTmallProduct
}) {
  const [pushProcessVisible, setPushProcessVisible] = React.useState(true);
  const { cachedData, result, awaited, run } = usePushCheck({
    onSuccess: () => {
      console.log('onSuccess');
      onFinish();
    },
    onError: error => {
      console.log('onError');
      onError();
    }
  });

  React.useEffect(() => {
    if (reqId) {
      setPushProcessVisible(true);
      run();
    }
  }, [reqId]);

  React.useEffect(() => {
    if (cachedData) {
      onStart(cachedData);
    }
  }, [cachedData, onStart]);

  React.useEffect(() => {
    if (result) {
      onPending(result);
    }

    
  }, [result, onPending]);

  function handleClose() {
    setPushProcessVisible(false);
    // localStorage.removeItem('push_task');
  }

  const closeAlertHandler = ()=>{
    setPushProcessVisible(false)
  }

  const counts = React.useMemo(() => {
    let ok = 0;
    let fail = 0;
    let awaitNumber = 0;
  //  const type = {
  //   4:'Tmall',
  //   2:'AliExpress'
  //  }
    if (result) {
      result.forEach(i => {
        if (i.status === 1) ok += 1;
        if (i.status === 2) fail += 1;
        if (i.status === 0) awaitNumber += 1;
        // if (i.status !==0 && i.status !== 2) {
        //   selectedPushProducts.map(item=>{
        //     if (item.id === i.ali_express_product_id) {
        //       window.dtag.set({
        //         event: 'click',
        //         module: 'import_list',
        //         action: 'import_list_push_success',
        //         custom_data:{
        //           id:i?.ali_express_product_id,
        //           type:type[item.supplier_type] ?? ''
        //         }
        //       }) 
        //     }
        //   })        
        // }
      });

      return {
        ok, 
        fail,
        awaitNumber
      };
    }

    return null;
  }, [result]);

  if (!result || pushState === 0) {
    return null;
  }


  return (
    <div style={{ marginBottom: 24 }}>
      {pushProcessVisible && (counts.ok || counts.fail || counts.awaitNumber) ? (
        <Alert
          className={awaited && counts.fail ? styles.awaitError : awaited ? styles.send : (counts?.fail ? styles.senderr : styles.alert )}
          type="warning"
          icon={
            awaited ? (
              <Icon type="loading" style={counts.fail ? {color:'#FF8F00'} : {color:'#2189E5'}}/>
            ) : (
              <span>
                <img src={counts?.fail ? error : succ}  />
              </span>
            )
          }
          showIcon
          afterClose={handleClose}
          message={
            awaited ? (
              <div className={counts.fail ? styles.awaitCountError : styles.awaitCount}>{counts.awaitNumber} &nbsp; {intl.get('import_list.push_desc')}                     <div className={styles.pushAlertTitle}>
                <span
                  className={styles.pushCheck}
                  onClick={() => onCheckDetail()}
                >
                  {intl.get('import_list.check_push')}
                </span>
                </div>
            </div>
            ) : (
              <div className={styles.count}>
                {counts ? (
                  <>
                    {
                      counts.fail && counts.ok
                      ? 
                      <div className={styles.leftText}>
                        <span className={styles.ok}>
                          {intl.get('import_list.push_desc_ok')}&nbsp;
                          <i>{counts.ok}</i>
                        </span>
                        <div></div>
                        <span className={styles?.fail}>
                          {intl.get('import_list.push_desc_fail')}&nbsp;
                          <i>{counts.fail}</i>
                        </span>
                    </div> : counts.fail
                    ? 
                    <div className={styles.leftText}>
                      <span className={styles?.fail}>
                        {intl.get('import_list.push_desc_fail')}&nbsp;
                        <i>{counts.fail}</i>
                      </span>
                    </div>
                    :
                    <div className={styles.leftText}>
                      <span className={styles.ok} style={{color:"#4CAF50"}}>
                        {intl.get('import_list.push_desc_ok')}&nbsp;
                        <i style={{color:"#4CAF50"}}>{counts.ok}</i>
                      </span>
                    </div>
                    }
                    <div className={styles.rightBox}>
                      {hasTmallProduct ? null : <Button
                        className={styles.banner}
                        onClick={() => onCheckDetail()}
                      >
                        <img className={styles.bannerImg} src={appIcon} />
                        <span>{intl.get('import_list.import_review')}</span>
                      </Button>}
                      <div className={styles.pushAlertTitle}>
                        <span
                          className={styles.pushCheck}
                          onClick={() => onCheckDetail()}
                        >
                          {intl.get('import_list.check_push')}
                        </span>
                      </div>
                      <span className={styles.closeimgcon} onClick={closeAlertHandler}>
                        {!awaited ? (!counts?.fail ? <img src={closesucc} alt="" /> : <img src={closeerr} alt="" />) : null }
                      </span>
                    </div>
                  </>
                ) : null}
              </div>
            )
          }
        />
      ) : null}
    </div>
  );
}

export default PushNotice;
